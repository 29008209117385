import { Skeleton } from "@mui/material";
import React from "react";

import { useListInstanceTypesQuery } from "../../hooks";

export interface DatabaseInstanceTypeProps {
  instanceType: string;
}

export const DatabaseInstanceType: React.FC<DatabaseInstanceTypeProps> = ({
  instanceType,
}) => {
  const { data: instanceTypes, isLoading } = useListInstanceTypesQuery();
  if (isLoading || !instanceTypes) {
    return <Skeleton />;
  }
  const instanceTypeData = instanceTypes.data.find(
    (it) => it.slug === instanceType
  );
  if (!instanceTypeData) {
    return <>{instanceType}</>;
  }
  return (
    <span>
      {instanceTypeData.cpu} CPU / {instanceTypeData.ram} GB RAM
    </span>
  );
};
