import { Box, Stack, Typography, styled, useTheme } from "@mui/material";
import { ValueFormatter } from "@nivo/axes/dist/types/types";
import { OrdinalColorScaleConfig } from "@nivo/colors";
import { PointTooltip, ResponsiveLine, Serie } from "@nivo/line";
import { useCreation } from "ahooks";
import React from "react";

import { Metrics } from "src/generated-sources/openapi";

export interface MetricsLineChartProps {
  yLabel: string;
  yFormat?: ValueFormatter<any>;
  data: Array<{ id: string; values: Metrics["values"] }>;
  min?: number;
  max?: number;
  colors?: OrdinalColorScaleConfig;
}

export const MetricsLineChart: React.FC<MetricsLineChartProps> = ({
  yLabel,
  yFormat,
  min,
  max,
  data,
  colors,
}) => {
  const theme = useTheme();
  const lineData: Serie[] = useCreation(() => {
    return data.map((entry) => ({
      id: entry.id,
      data: entry.values.map((point) => ({
        x: point.time,
        y: point.value,
      })),
    }));
  }, [data]);
  return (
    <Box height={300}>
      <ResponsiveLine
        data={lineData}
        colors={colors ?? theme.palette.indigo600}
        margin={{
          top: 10,
          bottom: 50,
          left: 100,
          right: 100,
        }}
        xScale={{
          type: "time",
          format: "native",
        }}
        xFormat="time:%H:%M:%S"
        yScale={{
          type: "linear",
          min: min ?? 0,
          max: max ?? "auto",
          nice: true,
          stacked: true,
          reverse: false,
        }}
        yFormat={yFormat}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: "%H:%M",
          tickValues: "every 5 minutes",
        }}
        axisLeft={{
          tickValues: 4,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yLabel,
          legendOffset: -70,
          legendPosition: "middle",
          format: yFormat,
        }}
        enablePoints={false}
        useMesh={true}
        curve="monotoneX"
        tooltip={CustomTooltip}
        animate={false}
      />
    </Box>
  );
};

const CustomTooltip: PointTooltip = (props) => {
  return (
    <Stack
      spacing={2}
      sx={(theme) => ({
        // @ts-ignore
        borderRadius: theme.shape.borderRadiusMd,
        backgroundColor: "background.paper",
        // @ts-ignore
        boxShadow: theme.ring({
          inset: true,
          width: 1,
          color: theme.palette.primary.main,
        }),
        padding: theme.spacing(2, 4),
      })}
    >
      <Box>
        <Typography variant="overline">{props.point.serieId}</Typography>
        <CodeBlock>{props.point.data.yFormatted}</CodeBlock>
      </Box>
      <Box>
        <Typography variant="overline">At</Typography>
        <CodeBlock>{props.point.data.xFormatted}</CodeBlock>
      </Box>
    </Stack>
  );
};

const CodeBlock = styled("code")({
  display: "block",
});
