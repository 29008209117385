import { UseQueryResult, useQuery } from "react-query";

import { DatabaseOptionEnvelope } from "src/generated-sources/openapi";

import { useDatabaseApi } from "./useDatabaseApi";

export function useListDatabaseOptionsQuery(): UseQueryResult<DatabaseOptionEnvelope> {
  const databaseApi = useDatabaseApi();

  return useQuery(["databaseOptions"], () => {
    return databaseApi.listDatabaseOptions();
  });
}
