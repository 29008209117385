import { PageHeaderContainer } from "@hooloovoodoo/mui-react";
import React from "react";

import { Service } from "src/generated-sources/openapi";

import { ServiceActions } from ".";
import { ServiceHeaderDetails } from "./ServiceHeaderDetails";
import { ServiceNav } from "./ServiceNav";

interface ServiceHeaderProps {
  service: Service;
}

export const ServiceHeader: React.FC<ServiceHeaderProps> = ({ service }) => {
  return (
    <>
      <PageHeaderContainer
        title={service.name}
        details={<ServiceHeaderDetails service={service} />}
        actions={<ServiceActions service={service} />}
      />
      <ServiceNav />
    </>
  );
};
