/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Action } from './Action';
import {
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';

/**
 * 
 * @export
 * @interface EntityMeta
 */
export interface EntityMeta {
    /**
     * 
     * @type {Array<Action>}
     * @memberof EntityMeta
     */
    actions?: Array<Action>;
}

/**
 * Check if a given object implements the EntityMeta interface.
 */
export function instanceOfEntityMeta(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EntityMetaFromJSON(json: any): EntityMeta {
    return EntityMetaFromJSONTyped(json, false);
}

export function EntityMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actions': !exists(json, 'actions') ? undefined : ((json['actions'] as Array<any>).map(ActionFromJSON)),
    };
}

export function EntityMetaToJSON(value?: EntityMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actions': value.actions === undefined ? undefined : ((value.actions as Array<any>).map(ActionToJSON)),
    };
}

