import CodeBracketIcon from "@heroicons/react/24/outline/CodeBracketIcon";
import GlobeAltIcon from "@heroicons/react/24/outline/GlobeAltIcon";
import { StackedList } from "@hooloovoodoo/mui-react";
import React from "react";

import { Repository } from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";

interface RepositoryListProps {
  repositories: Repository[];
}

export const RepositoryList: React.FC<RepositoryListProps> = ({
  repositories,
}) => {
  const { formatDistanceToNow } = useDateFns();

  return (
    <StackedList.List>
      {repositories.map((repository) => {
        return (
          <StackedList.ListItem key={repository.id}>
            <StackedList.ContentContainer>
              <StackedList.Title text={repository.name} />
              <StackedList.DetailContainer>
                <StackedList.Detail
                  icon={GlobeAltIcon}
                  text={repository.htmlUrl}
                  url={repository.htmlUrl}
                />
                {repository.language && (
                  <StackedList.Detail
                    icon={CodeBracketIcon}
                    text={repository.language}
                  />
                )}
              </StackedList.DetailContainer>
            </StackedList.ContentContainer>
            <StackedList.ContentContainer sx={{ alignItems: "flex-end" }}>
              <StackedList.Title
                text={"Connected at:"}
                sx={{ fontWeight: "normal" }}
              />
              <StackedList.Detail
                text={formatDistanceToNow(repository.connectedAt, {
                  addSuffix: true,
                })}
              />
            </StackedList.ContentContainer>
          </StackedList.ListItem>
        );
      })}
    </StackedList.List>
  );
};
