import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";

export const DeploymentInfoSkeleton: React.FC = () => {
  return (
    <Grid container spacing={4}>
      <Grid item md>
        <Typography variant="overline">Status</Typography>
        <Skeleton />
      </Grid>
      <Grid item md>
        <Box>
          <Typography variant="overline">Deployed by</Typography>
          <Skeleton />
        </Box>
      </Grid>
      <Grid item md>
        <Typography variant="overline">Deployed</Typography>
        <Skeleton />
      </Grid>
      <Grid item md>
        <Box>
          <Typography variant="overline">Version</Typography>
          <Skeleton />
        </Box>
      </Grid>
    </Grid>
  );
};
