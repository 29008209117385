import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import { EmptyStateSimple, UiHeroIcon } from "@hooloovoodoo/mui-react";
import React from "react";

import { useListServiceDeploymentsQuery, useRouteServiceId } from "../../hooks";
import { DeploymentsListTable } from "./DeploymentList";

export const ListDeployments: React.FC = () => {
  const serviceId = useRouteServiceId();
  const deployments = useListServiceDeploymentsQuery(serviceId);
  const envelope = deployments.data;
  return (
    <>
      {envelope && envelope.data.length === 0 && (
        <EmptyStateSimple
          icon={<UiHeroIcon icon={ClockIcon} />}
          title="No deployments yet"
          description="Your service should deploy automatically once the server is provisioned."
        />
      )}
      {envelope && envelope.data.length > 0 && (
        <DeploymentsListTable deployments={envelope.data} />
      )}
    </>
  );
};
