/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceSecret
 */
export interface ServiceSecret {
    /**
     * Secret name
     * @type {string}
     * @memberof ServiceSecret
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceSecret
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the ServiceSecret interface.
 */
export function instanceOfServiceSecret(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function ServiceSecretFromJSON(json: any): ServiceSecret {
    return ServiceSecretFromJSONTyped(json, false);
}

export function ServiceSecretFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceSecret {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function ServiceSecretToJSON(value?: ServiceSecret | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'updated_at': (value.updatedAt.toISOString()),
    };
}

