import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  PageHeaderContainer,
  UiButton,
  UiHeroIcon,
} from "@hooloovoodoo/mui-react";
import React from "react";

import { useCreateServerMutation } from "../hooks";

export const ServersHeader: React.FC = () => {
  const { mutate: createServer, isLoading: isCreatingServer } =
    useCreateServerMutation();
  return (
    <PageHeaderContainer
      title="Servers"
      actions={
        <UiButton
          onClick={() => createServer()}
          variant="contained"
          color="primary"
          disabled={isCreatingServer}
        >
          <UiHeroIcon icon={PlusIcon} />
          Create server
        </UiButton>
      }
    />
  );
};
