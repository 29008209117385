import { Box, CircularProgress } from "@mui/material";
import React from "react";

import {
  useGetDatabaseCredentialsQuery,
  useRouteDatabaseId,
} from "../../hooks";
import { DatabaseCredentialsContent } from "./DatabaseCredentialsContent";

export const DatabaseCredentials: React.FC = () => {
  const databaseId = useRouteDatabaseId();
  const { data: credentials, isLoading } =
    useGetDatabaseCredentialsQuery(databaseId);
  if (!credentials || isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ p: 4 }}>
      <DatabaseCredentialsContent credentials={credentials} />
    </Box>
  );
};
