import { UseQueryResult, useQuery } from "react-query";

import { Repository } from "src/generated-sources/openapi";
import { useCurrentOrganization } from "src/modules/organization/hooks";

import { useRepositoryApi } from "./useRepositoryApi";

export function useRepositoryQuery(
  repositoryId?: string
): UseQueryResult<Repository> {
  const organization = useCurrentOrganization();
  const repositoryApi = useRepositoryApi();
  return useQuery(
    ["repositories", organization.id, repositoryId],
    () => {
      if (!repositoryId) {
        throw new Error("Repository is not defined");
      }
      return repositoryApi.getRepository({
        repositoryId,
      });
    },
    {
      enabled: !!repositoryId,
    }
  );
}
