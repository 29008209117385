import { Sidebar } from "@hooloovoodoo/mui-react";
import { Avatar, Box, Stack } from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React from "react";

import { useCurrentUser } from "src/modules/authentication/hooks";
import { Logo } from "src/modules/common/components";
import { ConnectedOrganizationSelector } from "src/modules/organization/components";

import { ProfileMenu } from "./ProfileMenu";
import { SidebarMenu } from "./SidebarMenu";

export const AppSidebar: React.FC = () => {
  const profileMenuPopupState = usePopupState({
    variant: "popover",
    popupId: "sortMenuPopup",
  });
  const currentUser = useCurrentUser();

  return (
    <Sidebar.Container>
      <Logo />
      <Stack spacing={6} sx={{ width: "100%" }}>
        <ConnectedOrganizationSelector />
        <SidebarMenu />
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Sidebar.Nav>
        <Sidebar.MenuItem {...bindTrigger(profileMenuPopupState)}>
          <Sidebar.Icon>
            <Avatar
              sx={{ width: 24, height: 24 }}
              src={currentUser.avatarImageUrl}
            />
          </Sidebar.Icon>
          {currentUser.name}
        </Sidebar.MenuItem>
      </Sidebar.Nav>
      <ProfileMenu popupState={profileMenuPopupState} />
    </Sidebar.Container>
  );
};
