/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateServiceRequest
 */
export interface CreateServiceRequest {
    /**
     * Repository ID
     * @type {string}
     * @memberof CreateServiceRequest
     */
    repositoryId: string;
    /**
     * Server ID
     * @type {string}
     * @memberof CreateServiceRequest
     */
    serverId: string;
    /**
     * Service name
     * @type {string}
     * @memberof CreateServiceRequest
     */
    name: string;
    /**
     * Service ingress path
     * @type {string}
     * @memberof CreateServiceRequest
     */
    ingressPath?: string;
}

/**
 * Check if a given object implements the CreateServiceRequest interface.
 */
export function instanceOfCreateServiceRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "repositoryId" in value;
    isInstance = isInstance && "serverId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateServiceRequestFromJSON(json: any): CreateServiceRequest {
    return CreateServiceRequestFromJSONTyped(json, false);
}

export function CreateServiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateServiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repositoryId': json['repositoryId'],
        'serverId': json['serverId'],
        'name': json['name'],
        'ingressPath': !exists(json, 'ingressPath') ? undefined : json['ingressPath'],
    };
}

export function CreateServiceRequestToJSON(value?: CreateServiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repositoryId': value.repositoryId,
        'serverId': value.serverId,
        'name': value.name,
        'ingressPath': value.ingressPath,
    };
}

