/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Problem,
} from '../models';
import {
    ProblemFromJSON,
    ProblemToJSON,
} from '../models';

export interface PostGithubAppInstallationRequest {
    installationId: string;
    code: string;
    setupAction?: string;
}

/**
 * 
 */
export class WebhookGithubApi extends runtime.BaseAPI {

    /**
     * Github notifies us when an app installation is changed.
     * Repository installation webhook
     */
    async postGithubAppInstallationRaw(requestParameters: PostGithubAppInstallationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling postGithubAppInstallation.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling postGithubAppInstallation.');
        }

        const queryParameters: any = {};

        if (requestParameters.installationId !== undefined) {
            queryParameters['installation_id'] = requestParameters.installationId;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.setupAction !== undefined) {
            queryParameters['setup_action'] = requestParameters.setupAction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhook/github/app-installation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Github notifies us when an app installation is changed.
     * Repository installation webhook
     */
    async postGithubAppInstallation(requestParameters: PostGithubAppInstallationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postGithubAppInstallationRaw(requestParameters, initOverrides);
    }

}
