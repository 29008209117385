/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizationMember } from './OrganizationMember';
import {
    OrganizationMemberFromJSON,
    OrganizationMemberFromJSONTyped,
    OrganizationMemberToJSON,
} from './OrganizationMember';

/**
 * 
 * @export
 * @interface CurrentUser
 */
export interface CurrentUser {
    /**
     * The unique identifier for the user
     * @type {string}
     * @memberof CurrentUser
     */
    id: string;
    /**
     * The email address of the user
     * @type {string}
     * @memberof CurrentUser
     */
    email: string;
    /**
     * The name of the user
     * @type {string}
     * @memberof CurrentUser
     */
    name?: string;
    /**
     * The URL of the user's profile picture
     * @type {string}
     * @memberof CurrentUser
     */
    avatarImageUrl?: string;
    /**
     * The organizations the user belongs to
     * @type {Array<OrganizationMember>}
     * @memberof CurrentUser
     */
    organizations: Array<OrganizationMember>;
}

/**
 * Check if a given object implements the CurrentUser interface.
 */
export function instanceOfCurrentUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "organizations" in value;

    return isInstance;
}

export function CurrentUserFromJSON(json: any): CurrentUser {
    return CurrentUserFromJSONTyped(json, false);
}

export function CurrentUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'avatarImageUrl': !exists(json, 'avatarImageUrl') ? undefined : json['avatarImageUrl'],
        'organizations': ((json['organizations'] as Array<any>).map(OrganizationMemberFromJSON)),
    };
}

export function CurrentUserToJSON(value?: CurrentUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'name': value.name,
        'avatarImageUrl': value.avatarImageUrl,
        'organizations': ((value.organizations as Array<any>).map(OrganizationMemberToJSON)),
    };
}

