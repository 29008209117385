/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Deployment } from './Deployment';
import {
    DeploymentFromJSON,
    DeploymentFromJSONTyped,
    DeploymentToJSON,
} from './Deployment';
import type { PaginationModel } from './PaginationModel';
import {
    PaginationModelFromJSON,
    PaginationModelFromJSONTyped,
    PaginationModelToJSON,
} from './PaginationModel';

/**
 * 
 * @export
 * @interface DeploymentEnvelope
 */
export interface DeploymentEnvelope {
    /**
     * 
     * @type {Array<Deployment>}
     * @memberof DeploymentEnvelope
     */
    data: Array<Deployment>;
    /**
     * 
     * @type {PaginationModel}
     * @memberof DeploymentEnvelope
     */
    pagination: PaginationModel;
}

/**
 * Check if a given object implements the DeploymentEnvelope interface.
 */
export function instanceOfDeploymentEnvelope(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function DeploymentEnvelopeFromJSON(json: any): DeploymentEnvelope {
    return DeploymentEnvelopeFromJSONTyped(json, false);
}

export function DeploymentEnvelopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentEnvelope {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(DeploymentFromJSON)),
        'pagination': PaginationModelFromJSON(json['pagination']),
    };
}

export function DeploymentEnvelopeToJSON(value?: DeploymentEnvelope | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(DeploymentToJSON)),
        'pagination': PaginationModelToJSON(value.pagination),
    };
}

