import { UiDialogLoader } from "@hooloovoodoo/mui-react";
import { Dialog } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { bindDialog, usePopupState } from "material-ui-popup-state/hooks";
import React, { forwardRef, useImperativeHandle, useState } from "react";

import {
  RequestServiceDeploymentParams,
  useGetServiceQuery,
  useListServiceVersionsQuery,
  useServiceDeploymentMutation,
} from "../../hooks";
import { ServiceActionDialogContent } from "./ServiceActionDialogContent";

export interface ServiceActionDialogRef {
  open: (serviceId: string) => void;
}

export interface ServiceActionDialogProps {
  ref?: React.Ref<ServiceActionDialogRef>;
}

type DialogState = {
  serviceId: string;
  openTime: Date;
} | null;

export const ServiceActionDialog: React.FC<ServiceActionDialogProps> =
  forwardRef((props, ref) => {
    const popupState = usePopupState({ variant: "dialog" });
    const [dialogState, setDialogState] = useState<DialogState>(null);
    const deploymentMutation = useServiceDeploymentMutation({
      onSuccess: () => {
        popupState.close();
      },
    });

    const serviceQuery = useGetServiceQuery(dialogState?.serviceId ?? "", {
      queryKey: [
        "service-action-dialog",
        dialogState?.serviceId,
        dialogState?.openTime.toISOString(),
      ],
      staleTime: 0,
      enabled: !!dialogState?.serviceId && popupState.isOpen,
    });
    const versionsQuery = useListServiceVersionsQuery(
      serviceQuery.data?.id ?? "",
      {
        enabled: serviceQuery.isSuccess && popupState.isOpen,
        staleTime: 0,
      }
    );

    const open = useMemoizedFn((serviceId: string) => {
      setDialogState({
        serviceId,
        openTime: new Date(),
      });
      popupState.open();
    });

    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      [open]
    );

    const deploy = useMemoizedFn(
      async (params: RequestServiceDeploymentParams) => {
        await deploymentMutation.mutateAsync(params);
      }
    );

    return (
      <Dialog {...bindDialog(popupState)} maxWidth="xs" fullWidth>
        {(serviceQuery.isLoading || versionsQuery.isLoading) && (
          <UiDialogLoader />
        )}
        {serviceQuery.isSuccess && versionsQuery.isSuccess && (
          <ServiceActionDialogContent
            key={dialogState?.openTime.toISOString()}
            service={serviceQuery.data}
            versions={versionsQuery.data.data}
            closeDialog={popupState.close}
            confirm={deploy}
          />
        )}
      </Dialog>
    );
  });
