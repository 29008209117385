import LogoutIcon from "@heroicons/react/24/outline/ArrowLeftOnRectangleIcon";
import SettingsIcon from "@heroicons/react/24/outline/Cog6ToothIcon";
import { UiHeroIcon } from "@hooloovoodoo/mui-react";
import { useSession } from "@hooloovoodoo/mui-react-auth";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { bindMenu } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/hooks";
import React from "react";
import { NavLink } from "react-router-dom";

interface ProfileMenuProps {
  popupState: PopupState;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ popupState }) => {
  const session = useSession();
  return (
    <Menu
      {...bindMenu(popupState)}
      PaperProps={{ style: { minWidth: "150px" } }}
    >
      <MenuItem
        component={NavLink}
        to="/settings"
        onClick={() => popupState.close()}
      >
        <ListItemIcon>
          <UiHeroIcon icon={SettingsIcon} />
        </ListItemIcon>
        <ListItemText primary={"Settings"} />
      </MenuItem>
      <MenuItem
        onClick={() => {
          session.logOut().then(() => {
            console.log("Signed out");
          });
          popupState.close();
        }}
      >
        <ListItemIcon>
          <UiHeroIcon icon={LogoutIcon} />
        </ListItemIcon>
        <ListItemText primary={"Logout"} />
      </MenuItem>
    </Menu>
  );
};
