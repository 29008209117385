import { UiButton } from "@hooloovoodoo/mui-react";
import { Box, List, ListItem } from "@mui/material";
import React from "react";

const tabOptions = [
  "Overview",
  "Activity",
  "Settings",
  "Collaborators",
  "Notifications",
];

export const RepositoryNav: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "auto",
        borderBottom: "1px solid",
        borderColor: "gray200",
        py: 4,
      }}
    >
      <List
        sx={(theme) => ({
          display: "flex",
          flex: "none",
          columnGap: theme.spacing(6),
          px: theme.spacing(4),
          py: 0,
          fontSize: "0.875rem",
          [theme.breakpoints.up("sm")]: {
            px: 6,
          },
          [theme.breakpoints.up("lg")]: {
            px: 8,
          },
        })}
      >
        {tabOptions.map((tab) => {
          return (
            <ListItem
              key={tab}
              sx={{
                border: "none",
                padding: 0,
              }}
            >
              <UiButton style={{ padding: 0 }}>{tab}</UiButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
