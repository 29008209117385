/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationModel } from './PaginationModel';
import {
    PaginationModelFromJSON,
    PaginationModelFromJSONTyped,
    PaginationModelToJSON,
} from './PaginationModel';
import type { Server } from './Server';
import {
    ServerFromJSON,
    ServerFromJSONTyped,
    ServerToJSON,
} from './Server';

/**
 * 
 * @export
 * @interface ServerEnvelope
 */
export interface ServerEnvelope {
    /**
     * 
     * @type {Array<Server>}
     * @memberof ServerEnvelope
     */
    data: Array<Server>;
    /**
     * 
     * @type {PaginationModel}
     * @memberof ServerEnvelope
     */
    pagination: PaginationModel;
}

/**
 * Check if a given object implements the ServerEnvelope interface.
 */
export function instanceOfServerEnvelope(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function ServerEnvelopeFromJSON(json: any): ServerEnvelope {
    return ServerEnvelopeFromJSONTyped(json, false);
}

export function ServerEnvelopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServerEnvelope {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ServerFromJSON)),
        'pagination': PaginationModelFromJSON(json['pagination']),
    };
}

export function ServerEnvelopeToJSON(value?: ServerEnvelope | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(ServerToJSON)),
        'pagination': PaginationModelToJSON(value.pagination),
    };
}

