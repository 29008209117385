import React from "react";

import {
  useCurrentOrganization,
  useListOrganizations,
  useSetCurrentOrganizationId,
} from "../hooks";
import { OrganizationSelector } from "./OrganizationSelector";

export const ConnectedOrganizationSelector: React.FC = () => {
  const organizations = useListOrganizations();
  const currentOrganization = useCurrentOrganization();
  const setOrganizationId = useSetCurrentOrganizationId();

  if (organizations.length <= 1) {
    // Don't display the organization selector if there's a single organization.
    return null;
  }

  return (
    <OrganizationSelector
      organizations={organizations}
      value={currentOrganization.id}
      onChange={setOrganizationId}
    />
  );
};
