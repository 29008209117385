import { UiButton } from "@hooloovoodoo/mui-react";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";

import { ServiceSecret, ServiceVariable } from "src/generated-sources/openapi";

import {
  useDeleteSecretMutation,
  useDeleteVariableMutation,
  useListSecretsQuery,
  useListVariablesQuery,
  useRouteServiceId,
  useSetVariableMutation,
} from "../../hooks";
import { useSetSecretMutation } from "../../hooks/useSetSecretMutation";
import {
  DeleteVariableDialog,
  DeleteVariableDialogRef,
} from "./DeleteVariableDialog";
import { SetVariableDialog, SetVariableDialogRef } from "./SetVariableDialog";
import { GenericVariableList, VariableItem } from "./components";

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}));

type VariableType = "secret" | "variable";

export const VariableList: React.FC = () => {
  const serviceId = useRouteServiceId();
  const { isLoading: isLoadingVariables } = useListVariablesQuery(serviceId);
  const { isLoading: isLoadingSecrets } = useListSecretsQuery(serviceId);

  const setVariableDialogRef = React.useRef<SetVariableDialogRef>(null);
  const deleteVariableDialogRef = React.useRef<DeleteVariableDialogRef>(null);

  const showCreateVariableDialog = useMemoizedFn(() => {
    setVariableDialogRef.current?.open({
      type: "variable",
      name: "",
      value: "",
    });
  });
  const showCreateSecretDialog = useMemoizedFn(() => {
    setVariableDialogRef.current?.open({
      type: "secret",
      name: "",
      value: "",
    });
  });
  const showEditVariableDialog = useMemoizedFn((variable: ServiceVariable) => {
    setVariableDialogRef.current?.open({
      type: "variable",
      name: variable.name,
      value: variable.value,
    });
  });
  const showDeleteVariableDialog = useMemoizedFn(
    (variable: ServiceVariable) => {
      deleteVariableDialogRef.current?.open({
        type: "variable",
        name: variable.name,
      });
    }
  );
  const showEditSecretDialog = useMemoizedFn((secret: ServiceSecret) => {
    setVariableDialogRef.current?.open({
      type: "secret",
      name: secret.name,
    });
  });
  const showDeleteSecretDialog = useMemoizedFn((secret: ServiceSecret) => {
    deleteVariableDialogRef.current?.open({
      type: "secret",
      name: secret.name,
    });
  });

  const setVariable = useSetVariableMutation();
  const setSecret = useSetSecretMutation();
  const onCreate = useMemoizedFn(
    async (type: VariableType, name: string, value: string) => {
      const mutate =
        type === "variable" ? setVariable.mutateAsync : setSecret.mutateAsync;
      await mutate({
        serviceId,
        name,
        value,
      });
    }
  );

  const deleteVariable = useDeleteVariableMutation();
  const deleteSecret = useDeleteSecretMutation();
  const onDelete = useMemoizedFn(async (type: VariableType, name: string) => {
    const performDelete =
      type === "variable"
        ? deleteVariable.mutateAsync
        : deleteSecret.mutateAsync;
    await performDelete({ serviceId, name });
  });

  if (isLoadingVariables && isLoadingSecrets) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Stack spacing={4}>
        <SetVariableDialog ref={setVariableDialogRef} onCreate={onCreate} />
        <DeleteVariableDialog
          ref={deleteVariableDialogRef}
          onSubmit={onDelete}
        />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Variables</Typography>
          <UiButton
            variant="contained"
            color="primary"
            onClick={showCreateVariableDialog}
          >
            Add
          </UiButton>
        </Box>
        <GenericVariableList
          ItemComponent={VariableItem}
          useQuery={useListVariablesQuery}
          onEdit={showEditVariableDialog}
          onDelete={showDeleteVariableDialog}
          emptyStateTitle="No variables configured"
          emptyStateDescription="Add a variable will create a new environment variable when deploying your service"
        />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Secrets</Typography>
          <UiButton
            variant="contained"
            color="primary"
            onClick={showCreateSecretDialog}
          >
            Add
          </UiButton>
        </Box>
        <GenericVariableList
          ItemComponent={VariableItem}
          useQuery={useListSecretsQuery}
          onEdit={showEditSecretDialog}
          onDelete={showDeleteSecretDialog}
          emptyStateTitle="No secrets configured"
          emptyStateDescription="Adding a secret will create a new environment variable when deploying your service"
        />
      </Stack>
    </Container>
  );
};
