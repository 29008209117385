/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Pageable,
  Problem,
  Repository,
  RepositoryEnvelope,
} from '../models';
import {
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
    RepositoryFromJSON,
    RepositoryToJSON,
    RepositoryEnvelopeFromJSON,
    RepositoryEnvelopeToJSON,
} from '../models';

export interface GetRepositoryRequest {
    repositoryId: string;
}

export interface ListRepositoriesRequest {
    organizationId: string;
    pageable?: Pageable;
}

/**
 * 
 */
export class RepositoryApi extends runtime.BaseAPI {

    /**
     * Returns the repository with the given id.
     * Get repository by id
     */
    async getRepositoryRaw(requestParameters: GetRepositoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Repository>> {
        if (requestParameters.repositoryId === null || requestParameters.repositoryId === undefined) {
            throw new runtime.RequiredError('repositoryId','Required parameter requestParameters.repositoryId was null or undefined when calling getRepository.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/repository/{repositoryId}`.replace(`{${"repositoryId"}}`, encodeURIComponent(String(requestParameters.repositoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepositoryFromJSON(jsonValue));
    }

    /**
     * Returns the repository with the given id.
     * Get repository by id
     */
    async getRepository(requestParameters: GetRepositoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Repository> {
        const response = await this.getRepositoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of repositories belonging to organization.
     * List repositories
     */
    async listRepositoriesRaw(requestParameters: ListRepositoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepositoryEnvelope>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling listRepositories.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organization/{organizationId}/repository`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepositoryEnvelopeFromJSON(jsonValue));
    }

    /**
     * Returns a list of repositories belonging to organization.
     * List repositories
     */
    async listRepositories(requestParameters: ListRepositoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepositoryEnvelope> {
        const response = await this.listRepositoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
