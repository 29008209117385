import { FirebaseOptions } from "@firebase/app";
import { ApplicationTheme } from "@hooloovoodoo/mui-react";
import { FirebaseIdentityProvider } from "@hooloovoodoo/mui-react-firebase";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { LocalizedDateFnsProvider } from "./hooks";
import "./i18n";
import { LoginPage } from "./modules/authentication/pages";
import { CallbackGithubInstallationPage } from "./modules/callback/pages";
import { ErrorBoundary } from "./modules/common/components/ErrorBoundary";
import { AnonymousRoute, ProtectedRoute } from "./modules/common/routes";
import { DashboardPage } from "./modules/dashboard/pages";
import {
  CreateDatabasePage,
  DatabasePage,
  DatabasesPage,
} from "./modules/database/pages";
import { AppRoute } from "./modules/layout";
import {
  ConnectRepositoryPage,
  RepositoriesPage,
  RepositoryPage,
} from "./modules/repositories/pages";
import { ServersPage } from "./modules/server/pages";
import {
  CreateServicePage,
  ServiceDeletionSuccessPage,
  ServicePage,
  ServicesPage,
} from "./modules/services/pages";
import { SettingsRoutes } from "./modules/settings/pages";
import { MuiLocalizationProvider, NotificationProvider } from "./providers";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60_000,
      useErrorBoundary: true,
    },
    mutations: {
      retry: false,
    },
  },
});

const ApplicationRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<AnonymousRoute />}>
          <Route index element={<LoginPage />} />
        </Route>
        <Route path="/*" element={<ProtectedRoute />}>
          <Route path="/*" element={<AppRoute />}>
            <Route index element={<DashboardPage />} />
            <Route
              path="repositories/:repositoryId"
              element={<RepositoryPage />}
            />
            <Route path="repositories" element={<RepositoriesPage />} />
            <Route path="databases" element={<DatabasesPage />} />
            <Route path="databases/new" element={<CreateDatabasePage />} />
            <Route path="databases/:databaseId/*" element={<DatabasePage />} />
            <Route
              path="repositories/connect"
              element={<ConnectRepositoryPage />}
            />
            <Route path="services/new" element={<CreateServicePage />} />
            <Route
              path="services/deletion-success"
              element={<ServiceDeletionSuccessPage />}
            />
            <Route path="services/:serviceId/*" element={<ServicePage />} />
            <Route path="services" element={<ServicesPage />} />
            <Route path="servers" element={<ServersPage />} />
            <Route path="settings/*" element={<SettingsRoutes />} />
            <Route
              path="callback/github/installation"
              element={<CallbackGithubInstallationPage />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const firebase: FirebaseOptions = {
  apiKey: "AIzaSyDcf8GlOD3xGeQ7dKNw322_CWNJjyOsJjA",
  authDomain: "rad12-83383.firebaseapp.com",
};

const identityProviderConfig = {
  firebase,
};

const root = ReactDOM.createRoot(document.getElementById("root")!!);
root.render(
  <React.StrictMode>
    <MuiLocalizationProvider>
      <NotificationProvider>
        <LocalizedDateFnsProvider>
          <ApplicationTheme>
            <QueryClientProvider client={queryClient}>
              <ErrorBoundary>
                <FirebaseIdentityProvider config={identityProviderConfig}>
                  <ApplicationRoutes />
                </FirebaseIdentityProvider>
              </ErrorBoundary>
            </QueryClientProvider>
          </ApplicationTheme>
        </LocalizedDateFnsProvider>
      </NotificationProvider>
    </MuiLocalizationProvider>
  </React.StrictMode>
);
