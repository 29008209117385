import { SimpleLoader } from "@hooloovoodoo/mui-react";
import { Container } from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";

import { ConnectRepositoriesList } from "src/modules/repositories/components";
import { useListRepositoriesQuery } from "src/modules/repositories/hooks";
import { useListServicesQuery } from "src/modules/services/hooks";

export const DashboardPage: React.FC = () => {
  const { data: services, isLoading: isLoadingServices } =
    useListServicesQuery();
  const { data: repositories, isLoading: isLoadingRepositories } =
    useListRepositoriesQuery();

  if (isLoadingServices || isLoadingRepositories) {
    return <SimpleLoader text="Loading services and repositories..." />;
  }

  if (repositories && repositories.data.length === 0) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <ConnectRepositoriesList />
      </Container>
    );
  }

  if (services && services.data.length === 0) {
    return <Navigate to="/services/new" />;
  }

  if (
    services &&
    services.data.length > 0 &&
    repositories &&
    repositories.data.length > 0
  ) {
    return <Navigate to={`/services/${services.data[0].id}`} />;
  }

  throw new Error("Unexpected error");
};
