export function validateIngressPath(value: string): string | true {
  const startsWithSlash = /^\//g;
  const noConsecutiveSlash = /\/\/+/g;
  if (!startsWithSlash.test(value)) {
    return "Must start with a forward slash (/)";
  }
  if (noConsecutiveSlash.test(value)) {
    return "Consecutive slashes are not allowed";
  }
  return true;
}
