import {
  ListItemText,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React, { forwardRef } from "react";

import { ServiceVersion } from "src/generated-sources/openapi";

export type RollbackSelectFieldProps = {
  versions: ServiceVersion[];
  defaultValue?: string;
} & TextFieldProps;

export const RollbackSelectField: React.FC<RollbackSelectFieldProps> =
  forwardRef(({ defaultValue, versions, ...props }, ref) => (
    <TextField
      select
      label="Rollback version"
      fullWidth
      defaultValue={defaultValue}
      placeholder="Select a version to rollback to"
      ref={ref}
      {...props}
    >
      {versions.map((item) => (
        <MenuItem key={item.version} value={item.version}>
          <ListItemText primary={<code>{item.version}</code>} />
        </MenuItem>
      ))}
    </TextField>
  ));
