import { UiButton, UiDialogCloseButton } from "@hooloovoodoo/mui-react";
import {
  Alert,
  AlertTitle,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import { useMemoizedFn } from "ahooks";
import capitalize from "lodash.capitalize";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { Service, ServiceVersion } from "src/generated-sources/openapi";

import { RequestServiceDeploymentParams } from "../../hooks";
import { canDeployService } from "../../permissions";
import { ActionSelectField, RollbackSelectField } from "./fields";

export interface ServiceActionDialogContentProps {
  service: Service;
  versions: ServiceVersion[];

  closeDialog: () => void;
  confirm: (values: RequestServiceDeploymentParams) => Promise<void>;
}

enum ServiceAction {
  DEPLOY = "deploy",
  ROLLBACK = "rollback",
}

export interface FormValues {
  action: string;
  tag?: string;
}

export const ServiceActionDialogContent: React.FC<
  ServiceActionDialogContentProps
> = ({ service, versions, closeDialog, confirm }) => {
  const form = useForm<FormValues>({
    defaultValues: {
      action: ServiceAction.DEPLOY,
      tag: versions[0]?.version ?? "",
    },
  });
  const values = form.watch();
  const canDeploy = canDeployService(service);

  const submit = useMemoizedFn(async (values: FormValues) => {
    try {
      await confirm({
        service,
        action: values.action,
        tag: values.tag ?? "",
      });
    } catch (e) {
      // Ignore error, handled above
    }
  });

  const canRollback = versions.length > 1;

  return (
    <form onSubmit={form.handleSubmit(submit)} style={{ margin: 0 }}>
      <UiDialogCloseButton
        onClick={closeDialog}
        disabled={form.formState.isSubmitting}
      />
      <DialogTitle>Deploy</DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <Controller
            name="action"
            control={form.control}
            render={({ field }) => (
              <ActionSelectField canRollback={canRollback} {...field} />
            )}
          />
          {canRollback && values.action === ServiceAction.ROLLBACK && (
            <Controller
              name="tag"
              rules={{ required: true }}
              control={form.control}
              render={({ field }) => (
                <RollbackSelectField versions={versions} {...field} />
              )}
            />
          )}
          {!canDeploy && (
            <Alert severity="warning">
              <AlertTitle>This service is currently being deployed.</AlertTitle>
              Please wait for the deployment to finish before deploying again.
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={4}>
          <Grid item xs={12} sm>
            <UiButton
              variant="outlined"
              color="gray"
              fullWidth
              onClick={closeDialog}
              disabled={form.formState.isSubmitting}
            >
              Cancel
            </UiButton>
          </Grid>
          <Grid item xs={12} sm>
            <UiButton
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={
                form.formState.isSubmitting ||
                !form.formState.isValid ||
                !canDeploy
              }
            >
              {capitalize(values.action)}
            </UiButton>
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
};
