import ArrowPathIcon from "@heroicons/react/24/outline/ArrowPathIcon";
import { StackedList } from "@hooloovoodoo/mui-react";
import { Link } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Database } from "src/generated-sources/openapi";

import { useDateFns } from "../../../hooks";

interface DatabaseListProps {
  databases: Database[];
}

export const DatabaseList: React.FC<DatabaseListProps> = ({ databases }) => {
  const { formatDistanceToNow } = useDateFns();
  return (
    <StackedList.List>
      {databases.map((database) => {
        return (
          <StackedList.ListItem key={database.id}>
            <StackedList.ContentContainer>
              <Link component={RouterLink} to={`/databases/${database.id}`}>
                <StackedList.Title text={database.name} />
              </Link>
              <StackedList.DetailContainer>
                <StackedList.Detail
                  icon={ArrowPathIcon}
                  text={database.status}
                />
              </StackedList.DetailContainer>
            </StackedList.ContentContainer>
            <StackedList.ContentContainer sx={{ alignItems: "flex-end" }}>
              <StackedList.Title text="Connected at:" fontWeight="normal" />
              <StackedList.Detail
                text={formatDistanceToNow(database.createdAt, {
                  addSuffix: true,
                })}
              />
            </StackedList.ContentContainer>
          </StackedList.ListItem>
        );
      })}
    </StackedList.List>
  );
};
