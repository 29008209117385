import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enUSLocale from "date-fns/locale/en-US";
import srLocale from "date-fns/locale/sr-Latn";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { StyledSnackbarProvider } from "./StyledSnackbarProvider";

export const MuiLocalizationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { i18n } = useTranslation();

  const adapterLocale = useMemo(() => {
    switch (i18n.resolvedLanguage) {
      case "rs":
        return srLocale;
      default:
        return enUSLocale;
    }
  }, [i18n.resolvedLanguage]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={adapterLocale}
    >
      <StyledSnackbarProvider>{children}</StyledSnackbarProvider>
    </LocalizationProvider>
  );
};
