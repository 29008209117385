import RocketLaunchIcon from "@heroicons/react/24/solid/RocketLaunchIcon";
import { UiButton, UiCardRadio, UiHeroIcon } from "@hooloovoodoo/mui-react";
import {
  Alert,
  Box,
  Container,
  FormHelperText,
  FormLabel,
  Link,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { Repository, Server } from "src/generated-sources/openapi";
import {
  ConnectRepositoriesList,
  RepositorySelector,
} from "src/modules/repositories/components";
import { useListRepositoriesQuery } from "src/modules/repositories/hooks";
import { ServerSelector } from "src/modules/server/components";
import {
  useCreateServerMutation,
  useListServersQuery,
} from "src/modules/server/hooks";

import { useCreateServiceMutation } from "../../hooks";

export interface CreateServiceFormProps {
  repositories: Repository[];
  servers: Server[];
}

interface FormValues {
  repository: Repository;
  server: Server | null;
  createServerInstanceType: string;
  name: string;
  ingressPath: string;
}

const instanceTypes = [
  {
    label: "2 CPU, 2 GB RAM, 60 GB SSD",
    value: "s-2vcpu-2gb",
    disabled: false,
  },
  {
    label: "2 CPU, 4 GB RAM, 80 GB SSD",
    value: "s-2vcpu-4gb",
    disabled: false,
  },
  {
    label: "4 CPU, 8 GB RAM, 160 GB SSD",
    value: "s-4vcpu-8gb",
    disabled: false,
  },
  {
    label: "8 CPU, 16 GB RAM, 320 GB SSD",
    value: "s-8vcpu-16gb",
    disabled: true,
  },
];

export const CreateServiceFormInner: React.FC<CreateServiceFormProps> = ({
  repositories,
  servers,
}) => {
  const navigate = useNavigate();
  const createServer = useCreateServerMutation();
  const createService = useCreateServiceMutation();
  const form = useForm<FormValues>({
    defaultValues: {
      repository: repositories[0],
      server: servers[0] ?? null,
      name: repositories[0].name,
      ingressPath: "/",
    },
    mode: "onChange",
  });
  const values = form.watch();

  const createOrGetServer = useMemoizedFn(async () => {
    const server = values.server;
    if (server === null) {
      return await createServer.mutateAsync();
    }
    return server;
  });

  const doCreateService = useMemoizedFn(async (values: FormValues) => {
    try {
      const server = await createOrGetServer();
      const service = await createService.mutateAsync({
        serverId: server.id,
        repositoryId: values.repository.id,
        name: values.name,
        ingressPath: values.ingressPath ?? null,
      });
      navigate(`/services/${service.id}`);
    } catch (err) {
      // Mutations will have already displayed an error
    }
  });

  const validateIngressPath = (value: string) => {
    const regex = new RegExp("^/(?:.*[^/])?$");
    return (
      regex.test(value) ||
      "Ingress path must start with a slash and not end with a slash."
    );
  };

  // show server selector if there are servers
  const showServerSelector = servers.length > 0;

  // show instance type field if no selected server
  const showInstanceType = values.server === null;

  return (
    <form onSubmit={form.handleSubmit(doCreateService)}>
      <Container maxWidth="xs" sx={{ marginY: 5 }}>
        <Stack spacing={4}>
          <Typography variant="h3" component="h1" sx={{ mb: 3 }}>
            Deploy a new service
          </Typography>
          <Box>
            <Typography>
              Select a repository from which to create a service.
            </Typography>
            <Typography>
              If you don't see the repository you want, you can{" "}
              <Link component={RouterLink} to="/repositories/connect">
                connect it
              </Link>
              .
            </Typography>
          </Box>
          <Controller
            name="repository"
            control={form.control}
            render={({ field }) => (
              <RepositorySelector
                {...field}
                onChange={(repo: Repository) => {
                  field.onChange(repo);
                  if (!form.formState.dirtyFields.name) {
                    form.setValue("name", repo.name);
                  }
                }}
                value={field.value}
                label="Repository"
              />
            )}
          />
          <Alert severity="info">
            Please ensure that your repository has a valid{" "}
            <code>Dockerfile</code>.
          </Alert>
          <TextField
            label="Name"
            helperText="The name of the service on the rad12 dashboard."
            {...form.register("name", {
              required: true,
            })}
          />
          <TextField
            label="Ingress path"
            {...form.register("ingressPath", {
              validate: { validateIngressPath },
            })}
            error={!!form.formState.errors.ingressPath}
            helperText={
              form.formState.errors.ingressPath
                ? form.formState.errors.ingressPath.message
                : "The URL path used to access the created service."
            }
          />

          {showServerSelector && (
            <Controller
              name="server"
              control={form.control}
              render={({ field }) => (
                <ServerSelector
                  {...field}
                  onChange={(server) => field.onChange(server)}
                  label="Server"
                  allowCreate
                />
              )}
            />
          )}
          {showInstanceType && (
            <Box>
              <FormLabel>Instance type</FormLabel>
              <RadioGroup
                {...form.register("createServerInstanceType")}
                defaultValue={instanceTypes[0].value}
                sx={{ gap: 2 }}
              >
                {instanceTypes.map((type) => (
                  <UiCardRadio
                    key={type.value}
                    variant="outlined"
                    color="gray"
                    value={type.value}
                    disabled={type.disabled}
                  >
                    {type.label}
                  </UiCardRadio>
                ))}
              </RadioGroup>
              <FormHelperText>
                Need a different instance?{" "}
                <Link component={RouterLink} to="mailto:support@rad12.io">
                  Contact support.
                </Link>
              </FormHelperText>
            </Box>
          )}
          <UiButton
            type="submit"
            fullWidth
            disabled={!form.formState.isValid || form.formState.isSubmitting}
            variant="contained"
            color="primary"
          >
            <UiHeroIcon icon={RocketLaunchIcon} />
            Deploy
          </UiButton>
        </Stack>
      </Container>
    </form>
  );
};

export const CreateServiceForm: React.FC = () => {
  const { data: repositories } = useListRepositoriesQuery();
  const { data: servers } = useListServersQuery();

  if (!repositories || !servers) {
    return null;
  }

  if (!repositories.data.length) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <ConnectRepositoriesList />
      </Container>
    );
  }

  return (
    <CreateServiceFormInner
      repositories={repositories.data}
      servers={servers.data}
    />
  );
};
