/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceLogs
 */
export interface ServiceLogs {
    /**
     * Logs in text format
     * @type {string}
     * @memberof ServiceLogs
     */
    logs: string;
    /**
     * The cursor of the next page of logs
     * @type {string}
     * @memberof ServiceLogs
     */
    cursor?: string;
    /**
     * Current page cursor identifier
     * @type {string}
     * @memberof ServiceLogs
     */
    current: string;
}

/**
 * Check if a given object implements the ServiceLogs interface.
 */
export function instanceOfServiceLogs(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "logs" in value;
    isInstance = isInstance && "current" in value;

    return isInstance;
}

export function ServiceLogsFromJSON(json: any): ServiceLogs {
    return ServiceLogsFromJSONTyped(json, false);
}

export function ServiceLogsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLogs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logs': json['logs'],
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        'current': json['current'],
    };
}

export function ServiceLogsToJSON(value?: ServiceLogs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logs': value.logs,
        'cursor': value.cursor,
        'current': value.current,
    };
}

