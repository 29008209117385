import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import { EmptyStateSimple, UiHeroIcon } from "@hooloovoodoo/mui-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { UseQueryResult } from "react-query";

import { useRouteServiceId } from "../../../hooks";

export interface Envelope<Data> {
  data: Data[];
}

export interface ItemComponentProps<Data> {
  item: Data;
  onEdit?: (item: Data) => void;
  onDelete?: (item: Data) => void;
}

export interface GenericVariableListProps<Data> {
  useQuery: (serviceId: string) => UseQueryResult<Envelope<Data>>;
  ItemComponent: React.FC<ItemComponentProps<Data>>;
  onEdit?: (item: Data) => void;
  onDelete?: (item: Data) => void;
  emptyStateTitle: string;
  emptyStateDescription: string;
}

export function GenericVariableList<Data>({
  ItemComponent,
  emptyStateTitle,
  emptyStateDescription,
  useQuery,
  onEdit,
  onDelete,
}: GenericVariableListProps<Data>) {
  const serviceId = useRouteServiceId();
  const { data } = useQuery(serviceId);
  const hasData = (data?.data?.length ?? 0) !== 0;
  return (
    <>
      {!hasData && (
        <EmptyStateSimple
          icon={<UiHeroIcon icon={MagnifyingGlassIcon} />}
          title={emptyStateTitle}
          description={emptyStateDescription}
        />
      )}
      {hasData && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.map((item, index) => (
              <ItemComponent
                key={index}
                item={item}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}
