/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';

/**
 * 
 * @export
 * @interface Repository
 */
export interface Repository {
    /**
     * The unique identifier for the repository
     * @type {string}
     * @memberof Repository
     */
    id: string;
    /**
     * The name of the repository
     * @type {string}
     * @memberof Repository
     */
    name: string;
    /**
     * The URL of the repository
     * @type {string}
     * @memberof Repository
     */
    htmlUrl: string;
    /**
     * The primary programming language
     * @type {string}
     * @memberof Repository
     */
    language?: string;
    /**
     * 
     * @type {Organization}
     * @memberof Repository
     */
    organization: Organization;
    /**
     * The date and time the repository was connected
     * @type {Date}
     * @memberof Repository
     */
    connectedAt: Date;
}

/**
 * Check if a given object implements the Repository interface.
 */
export function instanceOfRepository(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "htmlUrl" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "connectedAt" in value;

    return isInstance;
}

export function RepositoryFromJSON(json: any): Repository {
    return RepositoryFromJSONTyped(json, false);
}

export function RepositoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Repository {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'htmlUrl': json['html_url'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'organization': OrganizationFromJSON(json['organization']),
        'connectedAt': (new Date(json['connected_at'])),
    };
}

export function RepositoryToJSON(value?: Repository | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'html_url': value.htmlUrl,
        'language': value.language,
        'organization': OrganizationToJSON(value.organization),
        'connected_at': (value.connectedAt.toISOString()),
    };
}

