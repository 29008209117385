import {
  ListItemText,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React from "react";

import {
  InstanceType,
  InstanceTypeNodeTypeEnum,
} from "src/generated-sources/openapi";

export type DbInstanceSizeSelectorProps = TextFieldProps & {
  options: string[];
  instanceTypes: InstanceType[];
};

function resolveInstanceType(
  option: string,
  instanceTypes: InstanceType[]
): InstanceType | null {
  return instanceTypes.find((it) => it.slug === option) ?? null;
}

function resolveNodeType(nodeType: InstanceTypeNodeTypeEnum): string {
  switch (nodeType) {
    case InstanceTypeNodeTypeEnum.Basic:
      return "Basic";
    case InstanceTypeNodeTypeEnum.GeneralPurpose:
      return "General purpose";
    case InstanceTypeNodeTypeEnum.MemoryOptimized:
      return "Memory optimized";
    case InstanceTypeNodeTypeEnum.StorageOptimized:
      return "Storage optimized";
  }
}

export const DbInstanceSizeSelector: React.FC<DbInstanceSizeSelectorProps> =
  React.forwardRef(({ options, instanceTypes, ...props }, ref) => {
    return (
      <TextField select label="Instance size" ref={ref} {...props}>
        {options.map((option) => {
          const instanceType = resolveInstanceType(option, instanceTypes);
          return (
            <MenuItem key={option} value={option}>
              <ListItemText
                primary={
                  <>
                    {instanceType?.cpu} vCPU / {instanceType?.ram} GB RAM
                  </>
                }
                secondary={
                  <>
                    {resolveNodeType(
                      instanceType?.nodeType ?? InstanceTypeNodeTypeEnum.Basic
                    )}
                  </>
                }
              />
            </MenuItem>
          );
        })}
      </TextField>
    );
  });
