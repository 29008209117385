/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Pageable,
  Problem,
  ServiceSecret,
  ServiceSecretEnvelope,
  SetServiceSecretRequest,
} from '../models';
import {
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
    ServiceSecretFromJSON,
    ServiceSecretToJSON,
    ServiceSecretEnvelopeFromJSON,
    ServiceSecretEnvelopeToJSON,
    SetServiceSecretRequestFromJSON,
    SetServiceSecretRequestToJSON,
} from '../models';

export interface DeleteSecretRequest {
    serviceId: string;
    serviceSecretName: string;
}

export interface GetSecretRequest {
    serviceId: string;
    serviceSecretName: string;
}

export interface ListSecretsRequest {
    serviceId: string;
    pageable?: Pageable;
}

export interface SetSecretRequest {
    serviceId: string;
    serviceSecretName: string;
    setServiceSecretRequest: SetServiceSecretRequest;
}

/**
 * 
 */
export class ServiceSecretApi extends runtime.BaseAPI {

    /**
     * Deletes the Secret with the given name associated with the given service.
     * Delete Secret
     */
    async deleteSecretRaw(requestParameters: DeleteSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling deleteSecret.');
        }

        if (requestParameters.serviceSecretName === null || requestParameters.serviceSecretName === undefined) {
            throw new runtime.RequiredError('serviceSecretName','Required parameter requestParameters.serviceSecretName was null or undefined when calling deleteSecret.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/secret/{serviceSecretName}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))).replace(`{${"serviceSecretName"}}`, encodeURIComponent(String(requestParameters.serviceSecretName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the Secret with the given name associated with the given service.
     * Delete Secret
     */
    async deleteSecret(requestParameters: DeleteSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSecretRaw(requestParameters, initOverrides);
    }

    /**
     * Returns the Secret with the given name associated with the given service.
     * Get Secret
     */
    async getSecretRaw(requestParameters: GetSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceSecret>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling getSecret.');
        }

        if (requestParameters.serviceSecretName === null || requestParameters.serviceSecretName === undefined) {
            throw new runtime.RequiredError('serviceSecretName','Required parameter requestParameters.serviceSecretName was null or undefined when calling getSecret.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/secret/{serviceSecretName}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))).replace(`{${"serviceSecretName"}}`, encodeURIComponent(String(requestParameters.serviceSecretName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceSecretFromJSON(jsonValue));
    }

    /**
     * Returns the Secret with the given name associated with the given service.
     * Get Secret
     */
    async getSecret(requestParameters: GetSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceSecret> {
        const response = await this.getSecretRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of secret names associated with the given service.
     * List Secrets
     */
    async listSecretsRaw(requestParameters: ListSecretsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceSecretEnvelope>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling listSecrets.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/secret`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceSecretEnvelopeFromJSON(jsonValue));
    }

    /**
     * Returns a list of secret names associated with the given service.
     * List Secrets
     */
    async listSecrets(requestParameters: ListSecretsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceSecretEnvelope> {
        const response = await this.listSecretsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates or updates a secret for the given service.
     * Set Secret value
     */
    async setSecretRaw(requestParameters: SetSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceSecret>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling setSecret.');
        }

        if (requestParameters.serviceSecretName === null || requestParameters.serviceSecretName === undefined) {
            throw new runtime.RequiredError('serviceSecretName','Required parameter requestParameters.serviceSecretName was null or undefined when calling setSecret.');
        }

        if (requestParameters.setServiceSecretRequest === null || requestParameters.setServiceSecretRequest === undefined) {
            throw new runtime.RequiredError('setServiceSecretRequest','Required parameter requestParameters.setServiceSecretRequest was null or undefined when calling setSecret.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/secret/{serviceSecretName}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))).replace(`{${"serviceSecretName"}}`, encodeURIComponent(String(requestParameters.serviceSecretName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetServiceSecretRequestToJSON(requestParameters.setServiceSecretRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceSecretFromJSON(jsonValue));
    }

    /**
     * Creates or updates a secret for the given service.
     * Set Secret value
     */
    async setSecret(requestParameters: SetSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceSecret> {
        const response = await this.setSecretRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
