import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  PageHeaderContainer,
  UiButton,
  UiHeroIcon,
} from "@hooloovoodoo/mui-react";
import { Box } from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { bindDialog, usePopupState } from "material-ui-popup-state/hooks";
import React from "react";

import { ConnectRepositoryDialog } from ".";

export const RepositoriesHeader: React.FC = () => {
  const addRepositoryPopupState = usePopupState({ variant: "dialog" });

  return (
    <PageHeaderContainer
      title="Repositories"
      actions={
        <Box>
          <UiButton
            variant="contained"
            color="primary"
            {...bindTrigger(addRepositoryPopupState)}
          >
            <UiHeroIcon icon={PlusIcon} />
            Add repository
          </UiButton>
          <ConnectRepositoryDialog {...bindDialog(addRepositoryPopupState)} />
        </Box>
      }
    />
  );
};
