"use client";

import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {
  NotFoundSimple,
  ServerError,
  UiButton,
  UiHeroIcon,
} from "@hooloovoodoo/mui-react";
import { Box, styled } from "@mui/material";
import React from "react";
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
} from "react-error-boundary";

import { ResponseError } from "src/generated-sources/openapi";

import ServerErrorSrc from "../../assets/server-error.png";

const ContainerWrapper = styled(Box)({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const NotFoundWrapper = () => (
  <ContainerWrapper>
    <NotFoundSimple
      actions={
        <>
          <UiButton href="/" color="primary" variant="contained">
            Go back home
          </UiButton>
          <UiButton href="mailto:support@rad12.io" color="gray">
            Contact support <UiHeroIcon icon={ChevronRightIcon} size="sm" />
          </UiButton>
        </>
      }
    />
  </ContainerWrapper>
);

export const FallbackComponent: React.FC<FallbackProps> = ({ error }) => {
  if (error instanceof ResponseError) {
    if (error.response.status === 404) {
      return <NotFoundWrapper />;
    }
  }
  return (
    <ContainerWrapper>
      <ServerError image={ServerErrorSrc} />
    </ContainerWrapper>
  );
};

export const ErrorBoundary: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </ReactErrorBoundary>
  );
};
