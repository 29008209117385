import {
  UiButton,
  UiDialogActions,
  UiDialogIconTitle,
  UiWarningCircle,
} from "@hooloovoodoo/mui-react";
import {
  Alert,
  AlertTitle,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { Service } from "src/generated-sources/openapi";

import { canDeleteService } from "../../permissions";

export interface DeleteServiceDialogContentProps {
  service: Service;
  confirmDeletion: (service: Service) => Promise<void>;
  closeDialog: () => void;
}

export const DeleteServiceDialogContent: React.FC<
  DeleteServiceDialogContentProps
> = ({ service, closeDialog, confirmDeletion }) => {
  const form = useForm({
    defaultValues: {
      agree1: false,
      agree2: false,
    },
  });
  const submit = useMemoizedFn(async () => {
    try {
      await confirmDeletion(service);
    } catch (e) {
      // Ignore error, handled above
    }
  });
  const canDelete = canDeleteService(service);
  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <UiDialogIconTitle icon={<UiWarningCircle />} title="Delete service?" />
      <DialogContent>
        <Stack spacing={4}>
          <Typography variant="body2" color="text.secondary" align="center">
            Are you sure you want to delete the service? This action is
            irreversible.
          </Typography>
          <Controller
            name="agree1"
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox />}
                label="I understand that this action is irreversible."
                disabled={form.formState.isSubmitting}
                {...field}
                onChange={(event, checked) => field.onChange(checked)}
              />
            )}
          />
          <Controller
            name="agree2"
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <>
                    I want to delete service <strong>{service.name}</strong>
                  </>
                }
                disabled={form.formState.isSubmitting}
                {...field}
                onChange={(event, checked) => field.onChange(checked)}
              />
            )}
          />
          {!canDelete && (
            <Alert severity="warning">
              <AlertTitle>Deploy in progress</AlertTitle>
              You can't delete service while deploy is in progress. Please wait
              until the current action is finished.
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <UiDialogActions>
        <Grid container spacing={4}>
          <Grid item xs={12} sm>
            <UiButton
              color="gray"
              variant="outlined"
              fullWidth
              onClick={closeDialog}
              disabled={form.formState.isSubmitting}
            >
              Cancel
            </UiButton>
          </Grid>
          <Grid item xs={12} sm>
            <UiButton
              color="red"
              variant="contained"
              fullWidth
              type="submit"
              disabled={
                form.formState.isSubmitting ||
                !form.formState.isValid ||
                !canDelete
              }
            >
              Delete
            </UiButton>
          </Grid>
        </Grid>
      </UiDialogActions>
    </form>
  );
};
