import {
  UiButton,
  UiDialogActions,
  UiDialogIconTitle,
  UiWarningCircle,
} from "@hooloovoodoo/mui-react";
import { Dialog, DialogContent, DialogContentText, Grid } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { bindDialog, usePopupState } from "material-ui-popup-state/hooks";
import React, { Ref, forwardRef } from "react";
import { useForm } from "react-hook-form";

export interface OpenParams {
  type: "secret" | "variable";
  name: string;
}

export interface DeleteVariableDialogRef {
  open: (params: OpenParams) => void;
}

export interface DeleteVariableDialogProps {
  onSubmit?: (type: "secret" | "variable", name: string) => Promise<void>;
  ref?: Ref<DeleteVariableDialogRef>;
}

export const DeleteVariableDialog: React.FC<DeleteVariableDialogProps> =
  forwardRef((props, ref) => {
    const popupState = usePopupState({
      variant: "dialog",
      popupId: "delete-variable-dialog",
    });
    const [state, setState] = React.useState<OpenParams | null>(null);

    React.useImperativeHandle(
      ref,
      () => ({
        open: (params) => {
          popupState.open();
          setState(params);
        },
      }),
      [popupState]
    );

    return (
      <Dialog {...bindDialog(popupState)}>
        {state && (
          <DeleteVariableDialogContent
            params={state}
            onClose={popupState.close}
            onSubmit={props.onSubmit}
          />
        )}
      </Dialog>
    );
  });

const DeleteVariableDialogContent: React.FC<{
  params: OpenParams;
  onSubmit?: DeleteVariableDialogProps["onSubmit"];
  onClose?: () => void;
}> = ({ params, onSubmit, onClose }) => {
  const { handleSubmit, formState } = useForm();
  const doSubmit = useMemoizedFn(async () => {
    try {
      await onSubmit?.(params.type, params.name);
      onClose?.();
    } catch (e) {
      // TODO: handle error
    }
  });

  return (
    <form onSubmit={handleSubmit(doSubmit)}>
      <UiDialogIconTitle
        icon={<UiWarningCircle />}
        title={
          params.type === "variable" ? "Delete Variable?" : "Delete Secret?"
        }
      />
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete <code>{params.name}</code>?
        </DialogContentText>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <UiDialogActions>
        <Grid container spacing={4}>
          <Grid item xs={12} sm>
            <UiButton
              variant="outlined"
              color="gray"
              fullWidth
              disabled={formState.isSubmitting}
              onClick={onClose}
            >
              Cancel
            </UiButton>
          </Grid>
          <Grid item xs={12} sm>
            <UiButton
              variant="contained"
              color="red"
              fullWidth
              type="submit"
              disabled={formState.isSubmitting}
            >
              Delete
            </UiButton>
          </Grid>
        </Grid>
      </UiDialogActions>
    </form>
  );
};
