import { SimpleStats } from "@hooloovoodoo/mui-react";
import React from "react";

import { ServiceDeploymentMetric } from "src/generated-sources/openapi";

export interface ServiceStatsProps {
  metrics: ServiceDeploymentMetric;
}

const numberFormat = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
});

export const ServiceStats: React.FC<ServiceStatsProps> = ({ metrics }) => {
  const avgDeployTime =
    metrics.avgDeployTimeInMin < 1
      ? "< 1"
      : numberFormat.format(metrics.avgDeployTimeInMin);

  return (
    <SimpleStats.Root>
      <SimpleStats.Stat
        title="Deployments"
        value={metrics.numberOfDeployments}
      />
      <SimpleStats.Stat
        title="Average Deploy Time"
        value={avgDeployTime}
        unit="mins"
      />
      <SimpleStats.Stat
        title="Success Rate"
        value={metrics.successRate}
        unit="%"
      />
    </SimpleStats.Root>
  );
};
