import { UseQueryResult, useQuery } from "react-query";

import { DeploymentStatusEnvelope } from "src/generated-sources/openapi";

import { useDeploymentApi } from "./useApi";

export interface UseListServiceDeploymentLogsQueryOptions {
  poll?: boolean;
}

export function useListServiceDeploymentStatusesQuery(
  deploymentId: string,
  options?: UseListServiceDeploymentLogsQueryOptions
): UseQueryResult<DeploymentStatusEnvelope> {
  const deploymentApi = useDeploymentApi();
  return useQuery(
    ["deployments", deploymentId, "logs"],
    () => {
      return deploymentApi.listDeploymentStatuses({
        deploymentId,
      });
    },
    {
      refetchInterval: options?.poll ? 3000 : false,
    }
  );
}
