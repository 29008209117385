import { UseQueryResult, useQuery } from "react-query";

import { ServiceMetrics } from "src/generated-sources/openapi";

import { useServiceApi } from "./useApi";

const refetchIntervalMillis = 15_000;

export function useGetServiceApplicationMetricsQuery(
  serviceId: string
): UseQueryResult<ServiceMetrics> {
  const serviceApi = useServiceApi();
  return useQuery(
    ["service", serviceId, "metrics"],
    () =>
      serviceApi.getServiceMetrics({
        serviceId,
        period: "1h",
      }),
    {
      refetchInterval: refetchIntervalMillis,
      useErrorBoundary: false,
    }
  );
}
