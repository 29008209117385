/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntityMeta } from './EntityMeta';
import {
    EntityMetaFromJSON,
    EntityMetaFromJSONTyped,
    EntityMetaToJSON,
} from './EntityMeta';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';
import type { Repository } from './Repository';
import {
    RepositoryFromJSON,
    RepositoryFromJSONTyped,
    RepositoryToJSON,
} from './Repository';
import type { Server } from './Server';
import {
    ServerFromJSON,
    ServerFromJSONTyped,
    ServerToJSON,
} from './Server';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * The unique identifier for the service
     * @type {string}
     * @memberof Service
     */
    id: string;
    /**
     * 
     * @type {Repository}
     * @memberof Service
     */
    repository: Repository;
    /**
     * 
     * @type {Server}
     * @memberof Service
     */
    server: Server;
    /**
     * 
     * @type {Organization}
     * @memberof Service
     */
    organization: Organization;
    /**
     * The name of the service
     * @type {string}
     * @memberof Service
     */
    name: string;
    /**
     * The ingress path of the service
     * @type {string}
     * @memberof Service
     */
    ingressPath: string;
    /**
     * The description of the service
     * @type {string}
     * @memberof Service
     */
    description?: string;
    /**
     * The date and time the service was created
     * @type {Date}
     * @memberof Service
     */
    createdAt: Date;
    /**
     * 
     * @type {User}
     * @memberof Service
     */
    createdBy: User;
    /**
     * The status of the service
     * @type {string}
     * @memberof Service
     */
    status: ServiceStatusEnum;
    /**
     * 
     * @type {EntityMeta}
     * @memberof Service
     */
    meta: EntityMeta;
}


/**
 * @export
 */
export const ServiceStatusEnum = {
    ConfiguringRepositoryScheduled: 'CONFIGURING_REPOSITORY_SCHEDULED',
    ConfiguringRepository: 'CONFIGURING_REPOSITORY',
    ConfiguringRepositoryError: 'CONFIGURING_REPOSITORY_ERROR',
    RepositoryConfigured: 'REPOSITORY_CONFIGURED',
    Deploying: 'DEPLOYING',
    Deployed: 'DEPLOYED',
    DeployingError: 'DEPLOYING_ERROR',
    DeleteScheduled: 'DELETE_SCHEDULED',
    Deleting: 'DELETING',
    Deleted: 'DELETED'
} as const;
export type ServiceStatusEnum = typeof ServiceStatusEnum[keyof typeof ServiceStatusEnum];


/**
 * Check if a given object implements the Service interface.
 */
export function instanceOfService(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "repository" in value;
    isInstance = isInstance && "server" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "ingressPath" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function ServiceFromJSON(json: any): Service {
    return ServiceFromJSONTyped(json, false);
}

export function ServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Service {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'repository': RepositoryFromJSON(json['repository']),
        'server': ServerFromJSON(json['server']),
        'organization': OrganizationFromJSON(json['organization']),
        'name': json['name'],
        'ingressPath': json['ingress_path'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdAt': (new Date(json['created_at'])),
        'createdBy': UserFromJSON(json['created_by']),
        'status': json['status'],
        'meta': EntityMetaFromJSON(json['_meta']),
    };
}

export function ServiceToJSON(value?: Service | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'repository': RepositoryToJSON(value.repository),
        'server': ServerToJSON(value.server),
        'organization': OrganizationToJSON(value.organization),
        'name': value.name,
        'ingress_path': value.ingressPath,
        'description': value.description,
        'created_at': (value.createdAt.toISOString()),
        'created_by': UserToJSON(value.createdBy),
        'status': value.status,
        '_meta': EntityMetaToJSON(value.meta),
    };
}

