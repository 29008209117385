import { UiButton, UiDialogActions } from "@hooloovoodoo/mui-react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { bindDialog, usePopupState } from "material-ui-popup-state/hooks";
import React, { Ref, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";

interface OpenParams {
  type: "variable" | "secret";
  name?: string;
  value?: string;
}

export interface SetVariableDialogRef {
  open: (params: OpenParams) => void;
}

export interface SetVariableDialogProps {
  ref?: Ref<SetVariableDialogRef>;
  onCreate?: (
    type: "variable" | "secret",
    name: string,
    value: string
  ) => Promise<void>;
}

export const SetVariableDialog: React.FC<SetVariableDialogProps> =
  React.forwardRef((props, ref) => {
    const popupState = usePopupState({ variant: "dialog" });
    const [state, setState] = useState<OpenParams | null>(null);

    const open = useMemoizedFn((params: OpenParams) => {
      popupState.open();
      setState(params);
    });

    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      [open]
    );

    return (
      <Dialog {...bindDialog(popupState)} maxWidth="xs" fullWidth>
        {state && (
          <SetVariableDialogContent
            {...state}
            onClose={popupState.close}
            onSubmit={props.onCreate}
          />
        )}
      </Dialog>
    );
  });

interface FormValues {
  name: string;
  value: string;
}

interface SetVariableDialogContentProps extends OpenParams {
  onClose?: () => void;
  onSubmit?: (
    type: "secret" | "variable",
    name: string,
    value: string
  ) => Promise<void>;
}

const SetVariableDialogContent: React.FC<SetVariableDialogContentProps> = ({
  type,
  name,
  value,
  onSubmit,
  onClose,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      name: name,
      value: value,
    },
    mode: "onChange",
  });

  const createVariable = useMemoizedFn(async (data: FormValues) => {
    try {
      await onSubmit?.(type, data.name.toUpperCase(), data.value);
      onClose?.();
    } catch (e) {
      // TODO: handle error
    }
  });

  return (
    <form onSubmit={handleSubmit(createVariable)}>
      <DialogTitle>
        {type === "variable" && (name ? "Edit Variable" : "Create Variable")}
        {type === "secret" && (name ? "Edit Secret" : "Create Secret")}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText>
            A variable is a key-value pair that can be used in the service
            configuration. It will be set as an environment variable in the
            container.
          </DialogContentText>
          <TextField
            label="Name"
            fullWidth
            InputProps={{ autoFocus: true }}
            disabled={(name?.length ?? 0) > 0 || isSubmitting}
            placeholder="SPRING_DATASOURCE_URL"
            error={!!errors.name}
            helperText={errors.name?.message}
            {...register("name", {
              required: "Name is required",
              validate: validateVariableName,
            })}
          />
          <TextField
            label="Value"
            fullWidth
            disabled={isSubmitting}
            placeholder="jdbc://"
            error={!!errors.value}
            helperText={errors.value?.message}
            {...register("value", {
              required: "Value is required",
            })}
          />
        </Stack>
      </DialogContent>
      <UiDialogActions>
        <Grid container spacing={4}>
          <Grid item xs={12} sm>
            <UiButton
              color="gray"
              variant="outlined"
              fullWidth
              onClick={onClose}
            >
              Cancel
            </UiButton>
          </Grid>
          <Grid item xs={12} sm>
            <UiButton
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              disabled={!isValid || isSubmitting}
            >
              Save
            </UiButton>
          </Grid>
        </Grid>
      </UiDialogActions>
    </form>
  );
};

const VALID_VARIABLE_NAME = /^[a-zA-Z][a-zA-Z0-9_]*$/gi;
const DISALLOWED_VARIABLE_NAMES = /^(GITHUB_|RAD12_).*$/gi;

function validateVariableName(name: string): string | true {
  if (!name.match(VALID_VARIABLE_NAME)) {
    return "Variable names must start with a letter and can only contain letters, numbers and underscores.";
  }
  if (name.match(DISALLOWED_VARIABLE_NAMES)) {
    return "Variable names starting with GITHUB_ or RAD12_ are reserved.";
  }
  return true;
}
