import { ResponseError } from "src/generated-sources/openapi";

export class SpringBootError extends Error {
  constructor(public code: string, public message: string) {
    super(message);
  }
}

export async function parseError<T>(error: ResponseError): Promise<T> {
  try {
    const json = await error.response.json();
    if (typeof json.code === "string" && typeof json.message === "string") {
      return Promise.reject(new SpringBootError(json.code, json.message));
    }
    return Promise.reject(error);
  } catch (e) {
    // Failed to parse
    return Promise.reject(error);
  }
}
