/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatabaseConnection
 */
export interface DatabaseConnection {
    /**
     * A connection string in the format accepted by the database engine's command
     * @type {string}
     * @memberof DatabaseConnection
     */
    uri?: string;
    /**
     * The name of the default database
     * @type {string}
     * @memberof DatabaseConnection
     */
    database: string;
    /**
     * The FQDN pointing to the database cluster's current primary node
     * @type {string}
     * @memberof DatabaseConnection
     */
    host: string;
    /**
     * The port on which the database cluster is listening
     * @type {number}
     * @memberof DatabaseConnection
     */
    port: number;
    /**
     * The default user for the database
     * @type {string}
     * @memberof DatabaseConnection
     */
    user: string;
    /**
     * The randomly generated password for the default user
     * @type {string}
     * @memberof DatabaseConnection
     */
    password: string;
    /**
     * A boolean value indicating if the connection should be made over SSL
     * @type {boolean}
     * @memberof DatabaseConnection
     */
    ssl: boolean;
}

/**
 * Check if a given object implements the DatabaseConnection interface.
 */
export function instanceOfDatabaseConnection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "database" in value;
    isInstance = isInstance && "host" in value;
    isInstance = isInstance && "port" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "ssl" in value;

    return isInstance;
}

export function DatabaseConnectionFromJSON(json: any): DatabaseConnection {
    return DatabaseConnectionFromJSONTyped(json, false);
}

export function DatabaseConnectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseConnection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uri': !exists(json, 'uri') ? undefined : json['uri'],
        'database': json['database'],
        'host': json['host'],
        'port': json['port'],
        'user': json['user'],
        'password': json['password'],
        'ssl': json['ssl'],
    };
}

export function DatabaseConnectionToJSON(value?: DatabaseConnection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uri': value.uri,
        'database': value.database,
        'host': value.host,
        'port': value.port,
        'user': value.user,
        'password': value.password,
        'ssl': value.ssl,
    };
}

