import {
  ListItemText,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React, { ChangeEvent } from "react";

import { Repository } from "src/generated-sources/openapi";

import { useListRepositoriesQuery } from "../hooks";

export interface RepositorySelectorProps
  extends Omit<TextFieldProps, "onChange" | "value" | "defaultValue"> {
  defaultValue?: Repository;
  value?: Repository;
  onChange: (repository: Repository) => void;
}

export const RepositorySelector: React.FC<RepositorySelectorProps> =
  React.forwardRef(({ onChange, defaultValue, value, ...props }, ref) => {
    const { data: repositories } = useListRepositoriesQuery();
    const onSelectRepository = useMemoizedFn(
      (event: ChangeEvent<HTMLInputElement>) => {
        const id = event.target.value;
        const selectedRepository = repositories?.data.find(
          (repository) => repository.id === id
        );
        if (selectedRepository) {
          onChange?.(selectedRepository);
        }
      }
    );

    return (
      <TextField
        select
        onChange={onSelectRepository}
        defaultValue={defaultValue?.id}
        value={value?.id}
        {...props}
        ref={ref}
      >
        {repositories?.data.map((repository) => (
          <MenuItem key={repository.id} value={repository.id}>
            <ListItemText
              primary={repository.name}
              secondary={<>{repository.language} · GitHub</>}
            />
          </MenuItem>
        ))}
      </TextField>
    );
  });
