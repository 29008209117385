/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceDeploymentMetric
 */
export interface ServiceDeploymentMetric {
    /**
     * 
     * @type {number}
     * @memberof ServiceDeploymentMetric
     */
    numberOfDeployments: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceDeploymentMetric
     */
    avgDeployTimeInMin: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceDeploymentMetric
     */
    successRate: number;
}

/**
 * Check if a given object implements the ServiceDeploymentMetric interface.
 */
export function instanceOfServiceDeploymentMetric(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "numberOfDeployments" in value;
    isInstance = isInstance && "avgDeployTimeInMin" in value;
    isInstance = isInstance && "successRate" in value;

    return isInstance;
}

export function ServiceDeploymentMetricFromJSON(json: any): ServiceDeploymentMetric {
    return ServiceDeploymentMetricFromJSONTyped(json, false);
}

export function ServiceDeploymentMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceDeploymentMetric {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfDeployments': json['number_of_deployments'],
        'avgDeployTimeInMin': json['avg_deploy_time_in_min'],
        'successRate': json['success_rate'],
    };
}

export function ServiceDeploymentMetricToJSON(value?: ServiceDeploymentMetric | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number_of_deployments': value.numberOfDeployments,
        'avg_deploy_time_in_min': value.avgDeployTimeInMin,
        'success_rate': value.successRate,
    };
}

