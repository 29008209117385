import {
  PageContainer,
  PageHeaderContainer,
  SingleColumnDescriptionList,
} from "@hooloovoodoo/mui-react";
import { Box, Skeleton } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { Route, Routes } from "react-router-dom";

import { DatabaseCredentials } from "../components/DatabaseCredentials";
import { DatabaseHeaderActions } from "../components/DatabaseHeaderActions";
import { DatabaseHeaderDetails } from "../components/DatabaseHeaderDetails";
import { DatabaseNav } from "../components/DatabaseNav";
import { DatabaseOverview } from "../components/DatabaseOverview";
import { useGetDatabaseQuery } from "../hooks";

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <PageContainer
    sx={{
      overflow: "auto",
    }}
  >
    {children}
  </PageContainer>
);

export interface DatabasePageProps {}

export type DatabaseRouteParams = "databaseId";

export const DatabasePage: React.FC<DatabasePageProps> = () => {
  const { databaseId } = useParams<DatabaseRouteParams>();
  const { data: database, isLoading } = useGetDatabaseQuery(databaseId!!);

  if (isLoading || !database) {
    return (
      <Wrapper>
        <DatabaseSkeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PageHeaderContainer
        title={database.name}
        details={<DatabaseHeaderDetails database={database} />}
        actions={<DatabaseHeaderActions database={database} />}
      />
      <DatabaseNav />
      <Routes>
        <Route path="/" element={<DatabaseOverview />} />
        <Route path="/connection" element={<DatabaseCredentials />} />
      </Routes>
    </Wrapper>
  );
};

const DatabaseSkeleton: React.FC = () => {
  return (
    <Box sx={{ p: 4 }}>
      <SingleColumnDescriptionList.Root>
        <SingleColumnDescriptionList.Header title="Database" />
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <SingleColumnDescriptionList.Item
              key={index}
              label="..."
              value={<Skeleton />}
            />
          ))}
      </SingleColumnDescriptionList.Root>
    </Box>
  );
};
