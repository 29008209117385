import { useMemo } from "react";

import {
  DeploymentApi,
  ServiceApi,
  ServiceSecretApi,
  ServiceVariableApi,
} from "src/generated-sources/openapi";
import { useApiConfiguration } from "src/modules/common/hooks";

export function useServiceApi(): ServiceApi {
  const configuration = useApiConfiguration();
  return useMemo(() => new ServiceApi(configuration), [configuration]);
}

export function useDeploymentApi(): DeploymentApi {
  const configuration = useApiConfiguration();
  return useMemo(() => new DeploymentApi(configuration), [configuration]);
}

export function useServiceVariableApi(): ServiceVariableApi {
  const configuration = useApiConfiguration();
  return useMemo(() => new ServiceVariableApi(configuration), [configuration]);
}

export function useServiceSecretApi(): ServiceSecretApi {
  const configuration = useApiConfiguration();
  return useMemo(() => new ServiceSecretApi(configuration), [configuration]);
}
