import { useSnackbar } from "notistack";
import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "react-query";
import { useNavigate } from "react-router";

import {
  Deployment,
  RequestServiceDeployment,
  Service,
} from "src/generated-sources/openapi";

import { useServiceApi } from "./useApi";

export interface RequestServiceDeploymentParams
  extends RequestServiceDeployment {
  service: Service;
  action: string;
  tag: string;
}

export function useServiceDeploymentMutation(
  options: UseMutationOptions<
    Deployment,
    Error,
    RequestServiceDeploymentParams
  > = {}
): UseMutationResult<Deployment, Error, RequestServiceDeploymentParams> {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const serviceApi = useServiceApi();
  const { onSuccess, onError, onSettled, ...other } = options;
  return useMutation({
    mutationKey: ["service-deployment"],
    mutationFn: async (request: RequestServiceDeploymentParams) => {
      return serviceApi.requestServiceDeployment({
        serviceId: request.service.id,
        requestServiceDeployment: {
          tag: request.tag,
          action: request.action,
        },
      });
    },
    onSuccess: (deployment, request, context) => {
      snackbar.enqueueSnackbar(
        `Service ${request.service.name} will start deploying shortly...`,
        {
          variant: "success",
        }
      );

      navigate(
        `/services/${deployment.service.id}/deployments/${deployment.id}`
      );

      Promise.all([
        queryClient.invalidateQueries([
          "service",
          request.service.id,
          "details",
        ]),
        queryClient.invalidateQueries([
          "service",
          request.service.id,
          "deployments",
        ]),
      ]).then(() => {
        onSuccess?.(deployment, request, context);
      });
    },
    onError: (err: Error, request, context) => {
      snackbar.enqueueSnackbar(
        "An error occurred during the service deployment request.",
        {
          variant: "error",
        }
      );

      onError?.(err, request, context);
    },
    ...other,
  });
}
