import EllipsisVerticalIcon from "@heroicons/react/24/outline/EllipsisVerticalIcon";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import { UiButton, UiHeroIcon, UiIconButton } from "@hooloovoodoo/mui-react";
import {
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { bindMenu } from "material-ui-popup-state";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import React from "react";

import { Service } from "src/generated-sources/openapi";

import {
  DeleteServiceDialog,
  DeleteServiceDialogRef,
} from "../../DeleteServiceDialog";
import {
  ServiceActionDialog,
  ServiceActionDialogRef,
} from "../../DeployDialog";

export interface ServiceActionsProps {
  service: Service;
}

export const ServiceActions: React.FC<ServiceActionsProps> = ({ service }) => {
  const menuPopupState = usePopupState({ variant: "popper" });

  const deleteServiceDialogRef = React.useRef<DeleteServiceDialogRef>(null);
  const onOpenDeleteDialog = useMemoizedFn(() => {
    deleteServiceDialogRef.current?.open(service.id);
    menuPopupState.close();
  });

  const serviceActionDialogRef = React.useRef<ServiceActionDialogRef>(null);
  const onOpenDeployForm = useMemoizedFn(() => {
    serviceActionDialogRef.current?.open(service.id);
    menuPopupState.close();
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <UiButton
          variant="contained"
          color="primary"
          onClick={onOpenDeployForm}
        >
          Deploy
        </UiButton>
      </Grid>
      <Grid item xs>
        <UiIconButton
          variant="ghost"
          color="gray"
          {...bindTrigger(menuPopupState)}
        >
          <UiHeroIcon icon={EllipsisVerticalIcon} />
        </UiIconButton>
        <Menu {...bindMenu(menuPopupState)}>
          <MenuItem onClick={onOpenDeleteDialog}>
            <ListItemIcon>
              <UiHeroIcon icon={TrashIcon} />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        </Menu>
        <DeleteServiceDialog ref={deleteServiceDialogRef} />
        <ServiceActionDialog ref={serviceActionDialogRef} />
      </Grid>
    </Grid>
  );
};
