import CircleStackIcon from "@heroicons/react/24/outline/CircleStackIcon";
import { EmptyStateSimple, UiHeroIcon } from "@hooloovoodoo/mui-react";
import { Box } from "@mui/material";
import React from "react";

export const DatabaseEmptyState: React.FC = () => {
  return (
    <Box sx={{ marginY: 10 }}>
      <EmptyStateSimple
        icon={<UiHeroIcon icon={CircleStackIcon} size="lg" />}
        title="No databases found"
        description="Choose from a range of database types and sizes."
      />
    </Box>
  );
};
