import { PageContainer } from "@hooloovoodoo/mui-react";
import { Container } from "@mui/material";
import React from "react";

import { ConnectRepositoriesList } from "../components";

export const ConnectRepositoryPage: React.FC = () => {
  return (
    <PageContainer>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <ConnectRepositoriesList />
      </Container>
    </PageContainer>
  );
};
