import { Box, LinearProgress } from "@mui/material";
import { useEventListener } from "ahooks";
import useMutationObserver from "ahooks/lib/useMutationObserver";
import React, { useRef } from "react";

import { useRouteServiceId } from "../../hooks";
import { useStreamingLogs } from "./useStreamingLogs";

function useScrollToBottom(
  ref: React.RefObject<HTMLElement>,
  bottomRef: React.RefObject<HTMLElement>
) {
  const isAtBottomRef = useRef(true);

  useEventListener(
    "scroll",
    () => {
      const current = ref.current;
      if (current) {
        isAtBottomRef.current =
          current.scrollHeight - current.scrollTop === current.clientHeight;
      }
    },
    {
      target: ref.current,
    }
  );

  useMutationObserver(
    () => {
      const containerCurrent = ref?.current;
      if (containerCurrent && isAtBottomRef.current) {
        bottomRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    ref,
    { childList: true }
  );
}

export const ListLogs: React.FC = () => {
  const serviceId = useRouteServiceId();
  const { logs, isLoading } = useStreamingLogs(serviceId);
  const logsContainerRef = useRef<HTMLPreElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  useScrollToBottom(logsContainerRef, bottomRef);

  return (
    <Box
      sx={{
        px: 4,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Box
        component="pre"
        sx={{
          border: "2px solid",
          borderColor: "indigo600",
          padding: 4,
          backgroundColor: "indigo100",
          color: "indigo900",
          overflow: "auto",
          flexGrow: 1,
          whiteSpace: "pre-wrap",
          position: "relative",
        }}
        ref={logsContainerRef}
      >
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
        {logs.map((log) => (
          <code key={log.id}>{log.message}</code>
        ))}
        <div ref={bottomRef} />
      </Box>
    </Box>
  );
};
