import React from "react";

import { useGetDatabaseQuery, useRouteDatabaseId } from "../../hooks";
import { DatabaseOverviewContent } from "./DatabaseOverviewContent";

export const DatabaseOverview: React.FC = () => {
  const databaseId = useRouteDatabaseId();
  const { data: database, isLoading } = useGetDatabaseQuery(databaseId);

  if (!database || isLoading) {
    return null;
  }

  return <DatabaseOverviewContent database={database} />;
};
