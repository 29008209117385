import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {
  UiButton,
  UiHeroIcon,
  UiStatusDot,
  UiStatusDotProps,
} from "@hooloovoodoo/mui-react";
import { Avatar, Box, Typography, styled } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Deployment,
  DeploymentOutcomeEnum,
} from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";

export interface DeploymentsListTableProps {
  deployments: Deployment[];
}

const Table = styled("table")({
  width: "100%",
  borderCollapse: "collapse",
});
const TableHead = styled("thead")(({ theme }) => ({
  "& > :not(:hidden)": {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.gray300,
  },
}));
const TableBody = styled("tbody")({});
const TableRow = styled("tr")(({ theme }) => ({
  display: "table-row",
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.gray200,
}));
const TableHeader = styled("th")(({ theme }) => ({
  textAlign: "left",
  padding: theme.spacing(4, 3),
  "&:first-of-type": {
    paddingLeft: theme.spacing(4),
  },
  "&:last-of-type": {
    paddingRight: theme.spacing(4),
  },
}));
const TableData = styled("td")(({ theme }) => ({
  padding: theme.spacing(4, 3),
  "&:first-of-type": {
    paddingLeft: theme.spacing(4),
  },
  "&:last-of-type": {
    paddingRight: theme.spacing(4),
  },
}));

export const DeploymentsListTable: React.FC<DeploymentsListTableProps> = (
  props
) => {
  const { formatDistanceToNow } = useDateFns();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>User</TableHeader>
          <TableHeader>Version</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader colSpan={2}>Started at</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.deployments.map((deployment) => {
          const serviceId = deployment.service.id;
          const deploymentId = deployment.id;
          return (
            <TableRow key={deployment.id}>
              <TableData>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Avatar
                    src={deployment.executedBy?.avatarImageUrl}
                    alt={deployment.executedBy?.name}
                  />
                  {deployment.executedBy?.name}
                </Box>
              </TableData>
              <TableData>
                <code>{deployment.tag}</code>
              </TableData>
              <TableData>
                <DeploymentStatus outcome={deployment.outcome} />
              </TableData>
              <TableData>
                {formatDistanceToNow(deployment.executedAt)}
              </TableData>
              <TableData sx={{ textAlign: "right" }}>
                <UiButton
                  variant="ghost"
                  color="primary"
                  component={RouterLink}
                  to={`/services/${serviceId}/deployments/${deploymentId}`}
                >
                  <span>View</span>
                  <UiHeroIcon pullRight size="sm" icon={ChevronRightIcon} />
                </UiButton>
              </TableData>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const DeploymentStatus: React.FC<{ outcome: DeploymentOutcomeEnum }> = ({
  outcome,
}) => (
  <Typography sx={{ display: "flex", gap: 1, alignItems: "center" }}>
    <UiStatusDot
      variant={
        {
          [DeploymentOutcomeEnum.Successful]: "success",
          [DeploymentOutcomeEnum.Failed]: "error",
          [DeploymentOutcomeEnum.Pending]: "pending",
        }[outcome] as UiStatusDotProps["variant"]
      }
    />
    {
      {
        [DeploymentOutcomeEnum.Successful]: "Successful",
        [DeploymentOutcomeEnum.Failed]: "Failed",
        [DeploymentOutcomeEnum.Pending]: "Pending",
      }[outcome]
    }
  </Typography>
);
