import {
  PageContainer,
  UiButton,
  UiSuccessCircle,
} from "@hooloovoodoo/mui-react";
import { Container, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const ServiceDeletionSuccessPage: React.FC = () => {
  return (
    <PageContainer sx={{ my: 10 }}>
      <Container maxWidth="sm">
        <Stack alignItems={"center"}>
          <UiSuccessCircle size="xl" />
          <Typography
            my={4}
            variant="h4"
            component={"h1"}
            sx={{ fontWeight: "fontWeightSemiBold" }}
          >
            Deletion successful!
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
            mb={4}
          >
            Service successfully deleted. If you want, you can always choose to{" "}
            <Link component={RouterLink} to="/services/new">
              deploy another one
            </Link>
            . If you need further assistance or wish to use other services,{" "}
            <Link component={RouterLink} to="mailto:support@rad12.io">
              we're here to help.
            </Link>
          </Typography>
          <UiButton
            color="primary"
            variant="contained"
            fullWidth
            component={RouterLink}
            to="/"
          >
            Go back to dashboard
          </UiButton>
        </Stack>
      </Container>
    </PageContainer>
  );
};
