import { UseQueryResult, useQuery } from "react-query";

import {
  Deployment,
  DeploymentOutcomeEnum,
} from "src/generated-sources/openapi";

import { useDeploymentApi } from "./useApi";

export function useGetDeploymentQuery(
  deploymentId: string
): UseQueryResult<Deployment> {
  const deploymentApi = useDeploymentApi();
  return useQuery(
    ["deployments", deploymentId],
    () => {
      return deploymentApi.getDeployment({
        deploymentId,
      });
    },
    {
      // Keep polling until the deployment is no longer pending
      refetchInterval: (data) => {
        return data?.outcome === DeploymentOutcomeEnum.Pending ? 3000 : false;
      },
    }
  );
}
