import { CircularProgress, Typography } from "@mui/material";
import React from "react";

export const ServiceDeletionInProgress: React.FC = () => {
  return (
    <Typography
      variant="h4"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 4,
        ml: 4,
      }}
    >
      <CircularProgress />
      <span>Please wait while your service is being deleted</span>
    </Typography>
  );
};
