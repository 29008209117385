import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";

import { User } from "src/generated-sources/openapi";

export interface ServiceDetailsProps {
  publicUrl: string;
  vCPU: number;
  memory: number;
  disk: number;
  createdBy: User;
}

export const ServerDetails: React.FC<ServiceDetailsProps> = ({
  publicUrl,
  vCPU,
  memory,
  disk,
  createdBy,
}) => (
  <Grid container spacing={4}>
    <Grid item md>
      <Typography variant="overline">Public URL</Typography>
      <Typography>
        <Link href={publicUrl}>{publicUrl}</Link>
      </Typography>
    </Grid>
    <Grid item md>
      <Typography variant="overline">CPU</Typography>
      <Typography>{vCPU}</Typography>
    </Grid>
    <Grid item md>
      <Box>
        <Typography variant="overline">Memory</Typography>
        <Typography>{memory} GB</Typography>
      </Box>
    </Grid>
    <Grid item md>
      <Typography variant="overline">Disk</Typography>
      <Typography>{disk} GB</Typography>
    </Grid>
    <Grid item md>
      <Box>
        {/*<Typography variant="overline">Version</Typography>*/}
        {/*<Typography>*/}
        {/*  <code>{lastDeployment?.tag ?? "N/A"}</code>*/}
        {/*</Typography>*/}
      </Box>
    </Grid>
  </Grid>
);
