import { useSnackbar } from "notistack";
import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "react-query";

import {
  Service,
  UpdateServiceIngressPathOperationRequest,
} from "src/generated-sources/openapi";
import { SpringBootError, parseError } from "src/modules/common/errors";

import { useServiceApi } from "./useApi";

export function useUpdateServiceIngressPathMutation(
  options?: UseMutationOptions<
    Service,
    Error,
    UpdateServiceIngressPathOperationRequest
  >
): UseMutationResult<Service, Error, UpdateServiceIngressPathOperationRequest> {
  const api = useServiceApi();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(
    ["update-ingress-path"],
    (params) =>
      api.updateServiceIngressPath(params).catch((err) => parseError(err)),
    {
      onSuccess: (data, variables, context) => {
        snackbar.enqueueSnackbar("Deployment started", {
          variant: "success",
        });
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        const message =
          error instanceof SpringBootError
            ? error.message
            : "Failed to update ingress path";
        snackbar.enqueueSnackbar(message, {
          variant: "error",
        });
        options?.onError?.(error, variables, context);
      },
      onSettled(data, error, variables, context) {
        queryClient.invalidateQueries(["service", variables.serviceId]);
        options?.onSettled?.(data, error, variables, context);
      },
    }
  );
}
