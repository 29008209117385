import { UiBadge, UiButton } from "@hooloovoodoo/mui-react";
import { Box, Typography, styled } from "@mui/material";
import React from "react";

import { Server } from "src/generated-sources/openapi";

import {
  useCreateServerMutation,
  useDestroyServerMutation,
  useListServersQuery,
} from "../hooks";

export interface ServerListWidgetProps {}

const ServerListItem = styled(
  Box,
  {}
)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  padding: theme.spacing(2, 1),
}));

export const ServerListWidget: React.FC<ServerListWidgetProps> = (props) => {
  const { data: servers } = useListServersQuery();
  const { mutate: createServer, isLoading: isCreatingServer } =
    useCreateServerMutation();
  const { mutate: destroyServer, isLoading: isDestroyingServer } =
    useDestroyServerMutation();
  return (
    <Box>
      <Typography variant="h1">Servers</Typography>
      <UiButton
        onClick={() => createServer()}
        color="primary"
        variant="contained"
        disabled={isCreatingServer}
      >
        Create server
      </UiButton>
      {servers?.data.map((server: Server) => (
        <ServerListItem key={server.id}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography sx={{ fontWeight: "fontWeightSemiBold" }}>
              {server.hostname}

              <UiBadge component={"span"}>online</UiBadge>
            </Typography>
            <Box>
              <Typography color="gray300">
                Created by: {server.createdBy?.name}
              </Typography>
              <UiButton
                color="red"
                variant="contained"
                onClick={() => destroyServer({ serverId: server.id })}
                disabled={isDestroyingServer}
              >
                Delete server
              </UiButton>
            </Box>
          </Box>
        </ServerListItem>
      ))}
    </Box>
  );
};
