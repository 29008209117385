/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetricValue } from './MetricValue';
import {
    MetricValueFromJSON,
    MetricValueFromJSONTyped,
    MetricValueToJSON,
} from './MetricValue';

/**
 * 
 * @export
 * @interface Metrics
 */
export interface Metrics {
    /**
     * 
     * @type {string}
     * @memberof Metrics
     */
    type: MetricsTypeEnum;
    /**
     * 
     * @type {Array<MetricValue>}
     * @memberof Metrics
     */
    values: Array<MetricValue>;
}


/**
 * @export
 */
export const MetricsTypeEnum = {
    Counter: 'counter',
    Gauge: 'gauge'
} as const;
export type MetricsTypeEnum = typeof MetricsTypeEnum[keyof typeof MetricsTypeEnum];


/**
 * Check if a given object implements the Metrics interface.
 */
export function instanceOfMetrics(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function MetricsFromJSON(json: any): Metrics {
    return MetricsFromJSONTyped(json, false);
}

export function MetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Metrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'values': ((json['values'] as Array<any>).map(MetricValueFromJSON)),
    };
}

export function MetricsToJSON(value?: Metrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'values': ((value.values as Array<any>).map(MetricValueToJSON)),
    };
}

