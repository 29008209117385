/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateServiceRequest,
  Deployment,
  DeploymentEnvelope,
  Pageable,
  Problem,
  RequestServiceDeployment,
  Service,
  ServiceDeploymentMetric,
  ServiceEnvelope,
  ServiceLogs,
  ServiceMetrics,
  ServiceStatusEnvelope,
  ServiceVersionEnvelope,
  UpdateServiceIngressPathRequest,
  UpdateServiceRequest,
} from '../models';
import {
    CreateServiceRequestFromJSON,
    CreateServiceRequestToJSON,
    DeploymentFromJSON,
    DeploymentToJSON,
    DeploymentEnvelopeFromJSON,
    DeploymentEnvelopeToJSON,
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
    RequestServiceDeploymentFromJSON,
    RequestServiceDeploymentToJSON,
    ServiceFromJSON,
    ServiceToJSON,
    ServiceDeploymentMetricFromJSON,
    ServiceDeploymentMetricToJSON,
    ServiceEnvelopeFromJSON,
    ServiceEnvelopeToJSON,
    ServiceLogsFromJSON,
    ServiceLogsToJSON,
    ServiceMetricsFromJSON,
    ServiceMetricsToJSON,
    ServiceStatusEnvelopeFromJSON,
    ServiceStatusEnvelopeToJSON,
    ServiceVersionEnvelopeFromJSON,
    ServiceVersionEnvelopeToJSON,
    UpdateServiceIngressPathRequestFromJSON,
    UpdateServiceIngressPathRequestToJSON,
    UpdateServiceRequestFromJSON,
    UpdateServiceRequestToJSON,
} from '../models';

export interface CreateServiceOperationRequest {
    organizationId: string;
    createServiceRequest: CreateServiceRequest;
}

export interface DeleteServiceRequest {
    serviceId: string;
}

export interface GetServiceRequest {
    serviceId: string;
}

export interface GetServiceDeploymentsMetricRequest {
    serviceId: string;
}

export interface GetServiceLogsRequest {
    serviceId: string;
    date: Date;
    cursor?: string;
}

export interface GetServiceMetricsRequest {
    serviceId: string;
    period: GetServiceMetricsPeriodEnum;
}

export interface GetServiceStatusesRequest {
    serviceId: string;
    pageable?: Pageable;
}

export interface ListServiceDeploymentsRequest {
    serviceId: string;
    pageable?: Pageable;
}

export interface ListServiceVersionsRequest {
    serviceId: string;
    pageable?: Pageable;
}

export interface ListServicesRequest {
    organizationId: string;
    pageable?: Pageable;
}

export interface RequestServiceDeploymentRequest {
    serviceId: string;
    requestServiceDeployment: RequestServiceDeployment;
}

export interface UpdateServiceOperationRequest {
    serviceId: string;
    updateServiceRequest: UpdateServiceRequest;
}

export interface UpdateServiceIngressPathOperationRequest {
    serviceId: string;
    updateServiceIngressPathRequest: UpdateServiceIngressPathRequest;
}

/**
 * 
 */
export class ServiceApi extends runtime.BaseAPI {

    /**
     * Creates a new service.
     * Create service
     */
    async createServiceRaw(requestParameters: CreateServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Service>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createService.');
        }

        if (requestParameters.createServiceRequest === null || requestParameters.createServiceRequest === undefined) {
            throw new runtime.RequiredError('createServiceRequest','Required parameter requestParameters.createServiceRequest was null or undefined when calling createService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organization/{organizationId}/service`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateServiceRequestToJSON(requestParameters.createServiceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceFromJSON(jsonValue));
    }

    /**
     * Creates a new service.
     * Create service
     */
    async createService(requestParameters: CreateServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Service> {
        const response = await this.createServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a service of the organization. This operation removes the workflow and all secrets of the belonging repository.
     * Delete service
     */
    async deleteServiceRaw(requestParameters: DeleteServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling deleteService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a service of the organization. This operation removes the workflow and all secrets of the belonging repository.
     * Delete service
     */
    async deleteService(requestParameters: DeleteServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteServiceRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a service of the organization.
     * Get service
     */
    async getServiceRaw(requestParameters: GetServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Service>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling getService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceFromJSON(jsonValue));
    }

    /**
     * Returns a service of the organization.
     * Get service
     */
    async getService(requestParameters: GetServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Service> {
        const response = await this.getServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a metric of service deployments.
     * Get service deployments metric
     */
    async getServiceDeploymentsMetricRaw(requestParameters: GetServiceDeploymentsMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceDeploymentMetric>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling getServiceDeploymentsMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/deployment-metric`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceDeploymentMetricFromJSON(jsonValue));
    }

    /**
     * Returns a metric of service deployments.
     * Get service deployments metric
     */
    async getServiceDeploymentsMetric(requestParameters: GetServiceDeploymentsMetricRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceDeploymentMetric> {
        const response = await this.getServiceDeploymentsMetricRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the logs of a deployed service
     * Get service logs
     */
    async getServiceLogsRaw(requestParameters: GetServiceLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceLogs>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling getServiceLogs.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getServiceLogs.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString().substr(0,10);
        }

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/logs`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceLogsFromJSON(jsonValue));
    }

    /**
     * Returns the logs of a deployed service
     * Get service logs
     */
    async getServiceLogs(requestParameters: GetServiceLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceLogs> {
        const response = await this.getServiceLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the metrics of a deployed service
     * Get service metrics
     */
    async getServiceMetricsRaw(requestParameters: GetServiceMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceMetrics>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling getServiceMetrics.');
        }

        if (requestParameters.period === null || requestParameters.period === undefined) {
            throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling getServiceMetrics.');
        }

        const queryParameters: any = {};

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/metrics`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceMetricsFromJSON(jsonValue));
    }

    /**
     * Returns the metrics of a deployed service
     * Get service metrics
     */
    async getServiceMetrics(requestParameters: GetServiceMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceMetrics> {
        const response = await this.getServiceMetricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of latest service statuses.
     * Get service statuses
     */
    async getServiceStatusesRaw(requestParameters: GetServiceStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceStatusEnvelope>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling getServiceStatuses.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/status`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceStatusEnvelopeFromJSON(jsonValue));
    }

    /**
     * Returns a list of latest service statuses.
     * Get service statuses
     */
    async getServiceStatuses(requestParameters: GetServiceStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceStatusEnvelope> {
        const response = await this.getServiceStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of service deployments.
     * List service deployments
     */
    async listServiceDeploymentsRaw(requestParameters: ListServiceDeploymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeploymentEnvelope>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling listServiceDeployments.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/deployment`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeploymentEnvelopeFromJSON(jsonValue));
    }

    /**
     * Returns a list of service deployments.
     * List service deployments
     */
    async listServiceDeployments(requestParameters: ListServiceDeploymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeploymentEnvelope> {
        const response = await this.listServiceDeploymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of deployable service versions.
     * List service versions
     */
    async listServiceVersionsRaw(requestParameters: ListServiceVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceVersionEnvelope>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling listServiceVersions.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/versions`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceVersionEnvelopeFromJSON(jsonValue));
    }

    /**
     * Returns a list of deployable service versions.
     * List service versions
     */
    async listServiceVersions(requestParameters: ListServiceVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceVersionEnvelope> {
        const response = await this.listServiceVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of services belonging to organization.
     * List services
     */
    async listServicesRaw(requestParameters: ListServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceEnvelope>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling listServices.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organization/{organizationId}/service`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceEnvelopeFromJSON(jsonValue));
    }

    /**
     * Returns a list of services belonging to organization.
     * List services
     */
    async listServices(requestParameters: ListServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceEnvelope> {
        const response = await this.listServicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Triggers a service deployment.
     * Request service deployment
     */
    async requestServiceDeploymentRaw(requestParameters: RequestServiceDeploymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Deployment>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling requestServiceDeployment.');
        }

        if (requestParameters.requestServiceDeployment === null || requestParameters.requestServiceDeployment === undefined) {
            throw new runtime.RequiredError('requestServiceDeployment','Required parameter requestParameters.requestServiceDeployment was null or undefined when calling requestServiceDeployment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/deployment`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestServiceDeploymentToJSON(requestParameters.requestServiceDeployment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeploymentFromJSON(jsonValue));
    }

    /**
     * Triggers a service deployment.
     * Request service deployment
     */
    async requestServiceDeployment(requestParameters: RequestServiceDeploymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Deployment> {
        const response = await this.requestServiceDeploymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a service of the organization
     * Update service
     */
    async updateServiceRaw(requestParameters: UpdateServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Service>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling updateService.');
        }

        if (requestParameters.updateServiceRequest === null || requestParameters.updateServiceRequest === undefined) {
            throw new runtime.RequiredError('updateServiceRequest','Required parameter requestParameters.updateServiceRequest was null or undefined when calling updateService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateServiceRequestToJSON(requestParameters.updateServiceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceFromJSON(jsonValue));
    }

    /**
     * Updates a service of the organization
     * Update service
     */
    async updateService(requestParameters: UpdateServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Service> {
        const response = await this.updateServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a ingress path of the service
     * Update service ingress path
     */
    async updateServiceIngressPathRaw(requestParameters: UpdateServiceIngressPathOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Service>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling updateServiceIngressPath.');
        }

        if (requestParameters.updateServiceIngressPathRequest === null || requestParameters.updateServiceIngressPathRequest === undefined) {
            throw new runtime.RequiredError('updateServiceIngressPathRequest','Required parameter requestParameters.updateServiceIngressPathRequest was null or undefined when calling updateServiceIngressPath.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/ingress-path`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateServiceIngressPathRequestToJSON(requestParameters.updateServiceIngressPathRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceFromJSON(jsonValue));
    }

    /**
     * Updates a ingress path of the service
     * Update service ingress path
     */
    async updateServiceIngressPath(requestParameters: UpdateServiceIngressPathOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Service> {
        const response = await this.updateServiceIngressPathRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetServiceMetricsPeriodEnum = {
    _1h: '1h',
    _6h: '6h',
    _24h: '24h',
    _7d: '7d',
    _14d: '14d'
} as const;
export type GetServiceMetricsPeriodEnum = typeof GetServiceMetricsPeriodEnum[keyof typeof GetServiceMetricsPeriodEnum];
