import { SimpleLoader } from "@hooloovoodoo/mui-react";
import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router";

import { ServiceRoutes } from "../components/ServiceComponent";
import { ServiceHeader } from "../components/ServiceComponent/ServiceHeader";
import { useGetServiceQuery } from "../hooks";

export interface ServicePageProps {}

export type ServiceRouteParams = "serviceId";

export const ServicePage: React.FC<ServicePageProps> = () => {
  const { serviceId } = useParams<ServiceRouteParams>();
  const { data: service, isLoading } = useGetServiceQuery(serviceId!!);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflow: "hidden",
        pb: 4,
      }}
    >
      {service && (
        <>
          <ServiceHeader service={service} />
          <ServiceRoutes />
        </>
      )}
      {isLoading && <SimpleLoader text="Loading service info..." />}
    </Box>
  );
};
