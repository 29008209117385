/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * The name of the action
     * @type {string}
     * @memberof Action
     */
    name: string;
    /**
     * Whether the action is denied
     * @type {boolean}
     * @memberof Action
     */
    allow: boolean;
    /**
     * The reason why the action is not allowed
     * @type {string}
     * @memberof Action
     */
    denyReason?: string;
}

/**
 * Check if a given object implements the Action interface.
 */
export function instanceOfAction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "allow" in value;

    return isInstance;
}

export function ActionFromJSON(json: any): Action {
    return ActionFromJSONTyped(json, false);
}

export function ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Action {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'allow': json['allow'],
        'denyReason': !exists(json, 'deny_reason') ? undefined : json['deny_reason'],
    };
}

export function ActionToJSON(value?: Action | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'allow': value.allow,
        'deny_reason': value.denyReason,
    };
}

