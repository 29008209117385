import { UseQueryResult, useQuery } from "react-query";

export interface RegionDetails {
  slug: string;
  city: string;
  country: string;
}

export const RegionMap: Record<string, RegionDetails> = {
  nyc1: {
    slug: "nyc1",
    city: "New York City 1",
    country: "United States",
  },
  nyc3: {
    slug: "nyc3",
    city: "New York City 3",
    country: "United States",
  },
  ams3: {
    slug: "ams3",
    city: "Amsterdam 3",
    country: "Netherlands",
  },
  sfo2: {
    slug: "sfo2",
    city: "San Francisco 2",
    country: "United States",
  },
  sfo3: {
    slug: "sfo3",
    city: "San Francisco 3",
    country: "United States",
  },
  sgp1: {
    slug: "sgp1",
    city: "Singapore 1",
    country: "Singapore",
  },
  lon1: {
    slug: "lon1",
    city: "London 1",
    country: "United Kingdom",
  },
  fra1: {
    slug: "fra1",
    city: "Frankfurt 1",
    country: "Germany",
  },
  tor1: {
    slug: "tor1",
    city: "Toronto 1",
    country: "Canada",
  },
  blr1: {
    slug: "blr1",
    city: "Bangalore 1",
    country: "India",
  },
  syd1: {
    slug: "syd1",
    city: "Sydney 1",
    country: "Australia",
  },
};

export function useListRegionsQuery(): UseQueryResult<RegionDetails[]> {
  return useQuery<RegionDetails[]>("regions", async () => {
    return Object.values(RegionMap);
  });
}
