import { Section } from "@hooloovoodoo/mui-react";
import { CircularProgress, Divider, Stack } from "@mui/material";
import React from "react";

import { Service } from "src/generated-sources/openapi";

import { useGetServiceQuery, useRouteServiceId } from "../../hooks";
import { DetailsForm, IngressForm } from "./components";

export interface SettingsProps {}

export const Settings: React.FC<SettingsProps> = () => {
  const serviceId = useRouteServiceId();
  const serviceQuery = useGetServiceQuery(serviceId);
  return (
    <>
      {serviceQuery.isLoading && <CircularProgress />}
      {serviceQuery.data && <SettingsForm service={serviceQuery.data} />}
    </>
  );
};

export interface SettingsFormProps {
  service: Service;
}

export const SettingsForm: React.FC<SettingsFormProps> = ({ service }) => (
  <Stack divider={<Divider />}>
    <Section.Container>
      <Section.Description
        title="Service"
        description="Change your service name."
      />
      <Section.Content>
        <DetailsForm service={service} />
      </Section.Content>
    </Section.Container>
    <Section.Container>
      <Section.Description
        title="Ingress"
        description="Configure how your service is being accessed publicly."
      />
      <Section.Content>
        <IngressForm service={service} />
      </Section.Content>
    </Section.Container>
  </Stack>
);
