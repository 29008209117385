/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationModel } from './PaginationModel';
import {
    PaginationModelFromJSON,
    PaginationModelFromJSONTyped,
    PaginationModelToJSON,
} from './PaginationModel';
import type { ServiceStatus } from './ServiceStatus';
import {
    ServiceStatusFromJSON,
    ServiceStatusFromJSONTyped,
    ServiceStatusToJSON,
} from './ServiceStatus';

/**
 * 
 * @export
 * @interface ServiceStatusEnvelope
 */
export interface ServiceStatusEnvelope {
    /**
     * 
     * @type {Array<ServiceStatus>}
     * @memberof ServiceStatusEnvelope
     */
    data: Array<ServiceStatus>;
    /**
     * 
     * @type {PaginationModel}
     * @memberof ServiceStatusEnvelope
     */
    pagination: PaginationModel;
}

/**
 * Check if a given object implements the ServiceStatusEnvelope interface.
 */
export function instanceOfServiceStatusEnvelope(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function ServiceStatusEnvelopeFromJSON(json: any): ServiceStatusEnvelope {
    return ServiceStatusEnvelopeFromJSONTyped(json, false);
}

export function ServiceStatusEnvelopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceStatusEnvelope {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ServiceStatusFromJSON)),
        'pagination': PaginationModelFromJSON(json['pagination']),
    };
}

export function ServiceStatusEnvelopeToJSON(value?: ServiceStatusEnvelope | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(ServiceStatusToJSON)),
        'pagination': PaginationModelToJSON(value.pagination),
    };
}

