import { styled } from "@mui/material";
import React from "react";

import { DatabaseEngineEnum } from "src/generated-sources/openapi";
import { PostgresIconSrc, RedisIconSrc } from "src/modules/common/assets/icons";

export interface EngineNameProps {
  engine: DatabaseEngineEnum;
  icon?: boolean;
}

const Name = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

const Img = styled("img")({
  width: "1.25em",
  height: "1.25em",
});

export const DatabaseEngineName: React.FC<EngineNameProps> = ({
  engine,
  icon,
}) => {
  switch (engine) {
    case DatabaseEngineEnum.Postgresql:
      return (
        <Name>
          {icon && <Img src={PostgresIconSrc} alt="" role="presentation" />}{" "}
          PostgreSQL
        </Name>
      );
    case DatabaseEngineEnum.Mysql:
      return <Name>MySQL</Name>;
    case DatabaseEngineEnum.Mongodb:
      return <Name>MongoDB</Name>;
    case DatabaseEngineEnum.Redis:
      return (
        <Name>
          {icon && <Img src={RedisIconSrc} alt="" role="presentation" />} Redis
        </Name>
      );
  }
};
