import { SingleColumnDescriptionList } from "@hooloovoodoo/mui-react";
import { Grid } from "@mui/material";
import React from "react";

import { Database } from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";

import { DatabaseEngineName } from "../DatabaseEngineName";
import { DatabaseInstanceType } from "./DatabaseInstanceType";
import { DatabaseRegion } from "./DatabaseRegion";
import { DatabaseStatus } from "./DatabaseStatus";

export interface DatabaseOverviewProps {
  database: Database;
}

export const DatabaseOverviewContent: React.FC<DatabaseOverviewProps> = ({
  database,
}) => {
  const { formatDistanceToNow } = useDateFns();
  return (
    <>
      <Grid container sx={{ p: 4 }}>
        <Grid item xs={12} md={6}>
          <SingleColumnDescriptionList.Root>
            <SingleColumnDescriptionList.Header title="Database" />
            <SingleColumnDescriptionList.Item
              label="Name"
              value={database.name}
            />
            <SingleColumnDescriptionList.Item
              label="Engine"
              value={<DatabaseEngineName engine={database.engine} icon />}
            />
            <SingleColumnDescriptionList.Item
              label="Status"
              value={<DatabaseStatus status={database.status} />}
            />
            <SingleColumnDescriptionList.Item
              label="Region"
              value={<DatabaseRegion region={database.datacenterRegion} />}
            />
          </SingleColumnDescriptionList.Root>
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleColumnDescriptionList.Root>
            <SingleColumnDescriptionList.Header title={<>&nbsp;</>} />
            <SingleColumnDescriptionList.Item
              label="Instance Type"
              value={
                <DatabaseInstanceType instanceType={database.instanceType} />
              }
            />
            <SingleColumnDescriptionList.Item
              label="Nodes"
              value={database.numNodes}
            />
            <SingleColumnDescriptionList.Item
              label="Created by"
              value={database.createdBy.name}
            />
            <SingleColumnDescriptionList.Item
              label="Created At"
              value={formatDistanceToNow(database.createdAt, {
                addSuffix: true,
              })}
            />
          </SingleColumnDescriptionList.Root>
        </Grid>
      </Grid>
    </>
  );
};
