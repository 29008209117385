import { SimpleNav } from "@hooloovoodoo/mui-react";
import { ProfilePage } from "@hooloovoodoo/mui-react-auth";
import { Box } from "@mui/material";
import React from "react";
import { Routes } from "react-router";
import { NavLink, Navigate, Route } from "react-router-dom";

export const SettingsRoutes: React.FC = () => {
  return (
    <Box flexGrow={1} overflow="auto">
      <SimpleNav.Root sx={{ px: 4, mt: 4 }}>
        <SimpleNav.TabButton component={NavLink} to="/settings/profile">
          Profile
        </SimpleNav.TabButton>
      </SimpleNav.Root>
      <Routes>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/" element={<Navigate to="/settings/profile" replace />} />
      </Routes>
    </Box>
  );
};
