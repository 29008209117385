import { UiCardRadio } from "@hooloovoodoo/mui-react";
import { ListItemText, RadioGroup, RadioGroupProps } from "@mui/material";
import React, { forwardRef } from "react";

export interface ActionSelectFieldProps extends RadioGroupProps {
  canRollback?: boolean;
}

export const ActionSelectField: React.FC<ActionSelectFieldProps> = forwardRef(
  ({ canRollback, ...props }, ref) => (
    <RadioGroup sx={{ gap: 4 }} {...props} ref={ref}>
      <UiCardRadio value="deploy" color="gray" variant="outlined">
        <ListItemText
          primary="Deploy"
          secondary="Build & Deploy the latest commit"
        />
      </UiCardRadio>
      {canRollback && (
        <UiCardRadio value="rollback" color="gray" variant="outlined">
          <ListItemText
            primary="Rollback"
            secondary="Deploy a previously version"
          />
        </UiCardRadio>
      )}
    </RadioGroup>
  )
);
