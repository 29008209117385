import ChevronUpDownIcon from "@heroicons/react/24/solid/ChevronUpDownIcon";
import { UiButton, UiHeroIcon } from "@hooloovoodoo/mui-react";
import { Box, Menu, MenuItem, Typography, styled } from "@mui/material";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React from "react";

import { Organization } from "src/generated-sources/openapi";

export interface OrganizationSelectorProps {
  organizations: Organization[];
  onChange?: (organizationId: string) => void;
  value: string;
}

const CustomizedButton = styled(UiButton)(({ theme }) => ({
  justifyContent: "space-between",
}));

export const OrganizationSelector: React.FC<OrganizationSelectorProps> = ({
  organizations,
  value,
  onChange,
}) => {
  const popupState = usePopupState({ variant: "popover" });

  if (organizations.length === 0) {
    throw new Error("No organizations found");
  }

  const selectedOrganization =
    organizations.find((organization) => organization.id === value) ??
    organizations[0];
  return (
    <>
      <CustomizedButton
        variant="outlined"
        color="gray"
        {...bindTrigger(popupState)}
        fullWidth
      >
        <Box textAlign="left">
          <Typography variant="overline" color="text.secondary">
            Organization
          </Typography>
          <Typography>{selectedOrganization.name}</Typography>
        </Box>
        <UiHeroIcon icon={ChevronUpDownIcon} pullRight />
      </CustomizedButton>
      <Menu {...bindMenu(popupState)}>
        {organizations.map((organization) => (
          <MenuItem
            key={organization.id}
            onClick={() => {
              onChange?.(organization.id);
              popupState.close();
            }}
          >
            {organization.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
