import { Box, Skeleton } from "@mui/material";
import React from "react";

export const DeploymentLogsSkeleton: React.FC = () => (
  <Box>
    <Skeleton width="30%" />
    <Skeleton width="30%" />
    <Skeleton width="30%" />
    <Skeleton width="30%" />
  </Box>
);
