/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Pageable,
  Problem,
  Server,
  ServerEnvelope,
  ServerStatusEnvelope,
} from '../models';
import {
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
    ServerFromJSON,
    ServerToJSON,
    ServerEnvelopeFromJSON,
    ServerEnvelopeToJSON,
    ServerStatusEnvelopeFromJSON,
    ServerStatusEnvelopeToJSON,
} from '../models';

export interface CreateServerRequest {
    organizationId: string;
}

export interface DestroyServerRequest {
    serverId: string;
}

export interface GetServerRequest {
    serverId: string;
}

export interface ListServerStatusesRequest {
    serverId: string;
    pageable?: Pageable;
}

export interface ListServersRequest {
    organizationId: string;
    pageable?: Pageable;
}

/**
 * 
 */
export class ServerApi extends runtime.BaseAPI {

    /**
     * Create and bootstrap a new server.
     * Create new server
     */
    async createServerRaw(requestParameters: CreateServerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Server>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createServer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organization/{organizationId}/server`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServerFromJSON(jsonValue));
    }

    /**
     * Create and bootstrap a new server.
     * Create new server
     */
    async createServer(requestParameters: CreateServerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Server> {
        const response = await this.createServerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Destroy the created server.
     * Destroy server
     */
    async destroyServerRaw(requestParameters: DestroyServerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.serverId === null || requestParameters.serverId === undefined) {
            throw new runtime.RequiredError('serverId','Required parameter requestParameters.serverId was null or undefined when calling destroyServer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/server/{serverId}`.replace(`{${"serverId"}}`, encodeURIComponent(String(requestParameters.serverId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Destroy the created server.
     * Destroy server
     */
    async destroyServer(requestParameters: DestroyServerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.destroyServerRaw(requestParameters, initOverrides);
    }

    /**
     * Get the server.
     * Get server
     */
    async getServerRaw(requestParameters: GetServerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Server>> {
        if (requestParameters.serverId === null || requestParameters.serverId === undefined) {
            throw new runtime.RequiredError('serverId','Required parameter requestParameters.serverId was null or undefined when calling getServer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/server/{serverId}`.replace(`{${"serverId"}}`, encodeURIComponent(String(requestParameters.serverId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServerFromJSON(jsonValue));
    }

    /**
     * Get the server.
     * Get server
     */
    async getServer(requestParameters: GetServerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Server> {
        const response = await this.getServerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the server status updates. This will show updates related to provisioning the machine and installing all dependencies. When the server is destroyed, it will also show status updates related to destroying the machine. 
     * List server status updates
     */
    async listServerStatusesRaw(requestParameters: ListServerStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServerStatusEnvelope>> {
        if (requestParameters.serverId === null || requestParameters.serverId === undefined) {
            throw new runtime.RequiredError('serverId','Required parameter requestParameters.serverId was null or undefined when calling listServerStatuses.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/server/{serverId}/status`.replace(`{${"serverId"}}`, encodeURIComponent(String(requestParameters.serverId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServerStatusEnvelopeFromJSON(jsonValue));
    }

    /**
     * List the server status updates. This will show updates related to provisioning the machine and installing all dependencies. When the server is destroyed, it will also show status updates related to destroying the machine. 
     * List server status updates
     */
    async listServerStatuses(requestParameters: ListServerStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServerStatusEnvelope> {
        const response = await this.listServerStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return a list of servers of the organization.
     * List organization servers
     */
    async listServersRaw(requestParameters: ListServersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServerEnvelope>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling listServers.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organization/{organizationId}/server`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServerEnvelopeFromJSON(jsonValue));
    }

    /**
     * Return a list of servers of the organization.
     * List organization servers
     */
    async listServers(requestParameters: ListServersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServerEnvelope> {
        const response = await this.listServersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
