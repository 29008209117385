import { useSnackbar } from "notistack";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useServiceVariableApi } from "./useApi";

export interface DeleteVariableMutationRequest {
  serviceId: string;
  name: string;
}

export function useDeleteVariableMutation(): UseMutationResult<
  void,
  Error,
  DeleteVariableMutationRequest
> {
  const api = useServiceVariableApi();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  return useMutation({
    mutationFn: async (request: DeleteVariableMutationRequest) => {
      await api.deleteVariable({
        serviceId: request.serviceId,
        serviceVariableName: request.name,
      });
    },
    onSuccess: (_, variables) => {
      snackbar.enqueueSnackbar(`Variable "${variables.name}" deleted.`, {
        variant: "success",
      });
      queryClient.invalidateQueries([
        "service",
        variables.serviceId,
        "variables",
      ]);
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });
}
