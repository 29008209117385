import { UseQueryResult, useQuery } from "react-query";

import { InstanceTypeEnvelope } from "src/generated-sources/openapi";

import { useInstanceTypeApi } from "./useInstanceTypeApi";

export function useListInstanceTypesQuery(): UseQueryResult<InstanceTypeEnvelope> {
  const instanceTypeApi = useInstanceTypeApi();

  return useQuery(["instance-types"], () => {
    return instanceTypeApi.listInstanceTypes();
  });
}
