import { Action, Service } from "src/generated-sources/openapi";

const DELETE_ACTION = "delete";
const INSUFFICIENT_PERMISSIONS = "INSUFFICIENT_PERMISSIONS";
const INVALID_STATE = "INVALID_STATE";

export function canDeleteService(service: Service): boolean {
  return (
    filterDeleteServiceActions(service).filter((it) => it.allow).length > 0
  );
}

export function isDeleteServiceHidden(service: Service): boolean {
  return filterDeleteServiceActions(service).every(
    (it) => it.denyReason === INSUFFICIENT_PERMISSIONS
  );
}

export function isDeleteServiceDisabled(service: Service): boolean {
  return filterDeleteServiceActions(service).every(
    (it) => it.denyReason === INVALID_STATE
  );
}

export function filterDeleteServiceActions(service: Service): Action[] {
  return service.meta.actions?.filter((it) => it.name === DELETE_ACTION) ?? [];
}
