import { UiButton } from "@hooloovoodoo/mui-react";
import { List, Typography } from "@mui/material";
import React from "react";

import { Server } from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";
import { EntityListItem } from "src/modules/common/components";

import { useDestroyServerMutation } from "../hooks";

interface ServerListProps {
  servers: Server[];
}

export const ServerList: React.FC<ServerListProps> = ({ servers }) => {
  const { formatDistanceToNow } = useDateFns();
  const { mutate: destroyServer, isLoading: isDestroyingServer } =
    useDestroyServerMutation();

  return (
    <List>
      {servers.map((server) => {
        return (
          <EntityListItem
            key={server.id}
            name={server.hostname}
            additionalInfo={`Created by: ${server.createdBy.name}`}
          >
            <Typography variant="body2" color="gray400">
              Created {formatDistanceToNow(server.createdAt)} ago
            </Typography>
            <UiButton variant="contained" color="gray">
              Bootstrap
            </UiButton>
            <UiButton
              variant="contained"
              color="red"
              onClick={() => destroyServer({ serverId: server.id })}
              disabled={isDestroyingServer}
            >
              Destroy
            </UiButton>
          </EntityListItem>
        );
      })}
    </List>
  );
};
