/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DatabaseConnection } from './DatabaseConnection';
import {
    DatabaseConnectionFromJSON,
    DatabaseConnectionFromJSONTyped,
    DatabaseConnectionToJSON,
} from './DatabaseConnection';

/**
 * 
 * @export
 * @interface DatabaseCredentials
 */
export interface DatabaseCredentials {
    /**
     * The unique identifier for the database
     * @type {string}
     * @memberof DatabaseCredentials
     */
    databaseId: string;
    /**
     * 
     * @type {DatabaseConnection}
     * @memberof DatabaseCredentials
     */
    connection: DatabaseConnection;
    /**
     * 
     * @type {DatabaseConnection}
     * @memberof DatabaseCredentials
     */
    privateConnection: DatabaseConnection;
}

/**
 * Check if a given object implements the DatabaseCredentials interface.
 */
export function instanceOfDatabaseCredentials(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "databaseId" in value;
    isInstance = isInstance && "connection" in value;
    isInstance = isInstance && "privateConnection" in value;

    return isInstance;
}

export function DatabaseCredentialsFromJSON(json: any): DatabaseCredentials {
    return DatabaseCredentialsFromJSONTyped(json, false);
}

export function DatabaseCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'databaseId': json['database_id'],
        'connection': DatabaseConnectionFromJSON(json['connection']),
        'privateConnection': DatabaseConnectionFromJSON(json['private_connection']),
    };
}

export function DatabaseCredentialsToJSON(value?: DatabaseCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'database_id': value.databaseId,
        'connection': DatabaseConnectionToJSON(value.connection),
        'private_connection': DatabaseConnectionToJSON(value.privateConnection),
    };
}

