/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * 
 * @export
 * @interface ServiceMetrics
 */
export interface ServiceMetrics {
    /**
     * 
     * @type {Metrics}
     * @memberof ServiceMetrics
     */
    cpu?: Metrics;
    /**
     * 
     * @type {Metrics}
     * @memberof ServiceMetrics
     */
    memoryTotal?: Metrics;
    /**
     * 
     * @type {Metrics}
     * @memberof ServiceMetrics
     */
    memoryUsed?: Metrics;
    /**
     * 
     * @type {Metrics}
     * @memberof ServiceMetrics
     */
    diskReadBytes?: Metrics;
    /**
     * 
     * @type {Metrics}
     * @memberof ServiceMetrics
     */
    diskWrittenBytes?: Metrics;
}

/**
 * Check if a given object implements the ServiceMetrics interface.
 */
export function instanceOfServiceMetrics(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceMetricsFromJSON(json: any): ServiceMetrics {
    return ServiceMetricsFromJSONTyped(json, false);
}

export function ServiceMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cpu': !exists(json, 'cpu') ? undefined : MetricsFromJSON(json['cpu']),
        'memoryTotal': !exists(json, 'memory_total') ? undefined : MetricsFromJSON(json['memory_total']),
        'memoryUsed': !exists(json, 'memory_used') ? undefined : MetricsFromJSON(json['memory_used']),
        'diskReadBytes': !exists(json, 'disk_read_bytes') ? undefined : MetricsFromJSON(json['disk_read_bytes']),
        'diskWrittenBytes': !exists(json, 'disk_written_bytes') ? undefined : MetricsFromJSON(json['disk_written_bytes']),
    };
}

export function ServiceMetricsToJSON(value?: ServiceMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cpu': MetricsToJSON(value.cpu),
        'memory_total': MetricsToJSON(value.memoryTotal),
        'memory_used': MetricsToJSON(value.memoryUsed),
        'disk_read_bytes': MetricsToJSON(value.diskReadBytes),
        'disk_written_bytes': MetricsToJSON(value.diskWrittenBytes),
    };
}

