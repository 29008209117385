import {
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  TextFieldProps,
  styled,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React, { ChangeEvent } from "react";

import { DatabaseOption } from "src/generated-sources/openapi";
import { PostgresIconSrc, RedisIconSrc } from "src/modules/common/assets/icons";

export interface EngineSelectorProps
  extends Omit<TextFieldProps, "onChange" | "value" | "defaultValue"> {
  defaultValue?: DatabaseOption;
  value?: DatabaseOption;
  onChange: (option: DatabaseOption) => void;
  options: DatabaseOption[];
}

const Icons: Record<string, string> = {
  PostgreSQL: PostgresIconSrc,
  Redis: RedisIconSrc,
};

const StyledListItem = styled(ListItem)({
  padding: 0,
  ".MuiListItemIcon-root": {
    minWidth: 36,
  },
});

export const EngineSelector: React.FC<EngineSelectorProps> = React.forwardRef(
  ({ onChange, defaultValue, value, options, ...props }, ref) => {
    const onSelectRepository = useMemoizedFn(
      (event: ChangeEvent<HTMLInputElement>) => {
        const id = event.target.value;
        const selectedRepository = options.find((option) => option.name === id);
        if (selectedRepository) {
          onChange?.(selectedRepository);
        }
      }
    );

    return (
      <TextField
        select
        onChange={onSelectRepository}
        defaultValue={defaultValue?.name}
        value={value?.name}
        {...props}
        ref={ref}
      >
        {options.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            <StyledListItem>
              <ListItemIcon>
                <img
                  src={Icons[option.name!!]}
                  alt={option.name}
                  style={{ width: 24, height: 24 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={option.name}
                secondary={
                  <>
                    {option.versions.length} Versions · {option.regions.length}{" "}
                    Regions
                  </>
                }
              />
            </StyledListItem>
          </MenuItem>
        ))}
      </TextField>
    );
  }
);
