/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDatabaseRequest
 */
export interface CreateDatabaseRequest {
    /**
     * The slug identifier for the region where the database cluster is located
     * @type {string}
     * @memberof CreateDatabaseRequest
     */
    datacenterRegion: string;
    /**
     * The slug identifier for the database engine
     * @type {string}
     * @memberof CreateDatabaseRequest
     */
    engine: string;
    /**
     * The slug identifier representing the size of the nodes in the database cluster
     * @type {string}
     * @memberof CreateDatabaseRequest
     */
    instanceType: string;
    /**
     * The number of nodes in the database cluster
     * @type {number}
     * @memberof CreateDatabaseRequest
     */
    numNodes: number;
    /**
     * The name of the database cluster
     * @type {string}
     * @memberof CreateDatabaseRequest
     */
    name: string;
}

/**
 * Check if a given object implements the CreateDatabaseRequest interface.
 */
export function instanceOfCreateDatabaseRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datacenterRegion" in value;
    isInstance = isInstance && "engine" in value;
    isInstance = isInstance && "instanceType" in value;
    isInstance = isInstance && "numNodes" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateDatabaseRequestFromJSON(json: any): CreateDatabaseRequest {
    return CreateDatabaseRequestFromJSONTyped(json, false);
}

export function CreateDatabaseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDatabaseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datacenterRegion': json['datacenterRegion'],
        'engine': json['engine'],
        'instanceType': json['instanceType'],
        'numNodes': json['numNodes'],
        'name': json['name'],
    };
}

export function CreateDatabaseRequestToJSON(value?: CreateDatabaseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datacenterRegion': value.datacenterRegion,
        'engine': value.engine,
        'instanceType': value.instanceType,
        'numNodes': value.numNodes,
        'name': value.name,
    };
}

