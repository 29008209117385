import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";

import { ServiceSecretEnvelope } from "src/generated-sources/openapi";

import { useServiceSecretApi } from "./useApi";

export function useListSecretsQuery(
  serviceId: string,
  options?: Partial<UseQueryOptions<ServiceSecretEnvelope, Error>>
): UseQueryResult<ServiceSecretEnvelope> {
  const api = useServiceSecretApi();
  return useQuery({
    queryKey: ["service", serviceId, "secrets"],
    queryFn: () =>
      api.listSecrets({
        serviceId,
        pageable: {
          size: 200,
        },
      }),
    ...options,
  });
}
