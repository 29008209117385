import { UiStatusDot, UiStatusDotProps } from "@hooloovoodoo/mui-react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";

import {
  Deployment,
  DeploymentOutcomeEnum,
} from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";

export interface DeploymentInfoProps {
  deployment: Deployment;
}

export const DeploymentInfo: React.FC<DeploymentInfoProps> = ({
  deployment,
}) => {
  const { formatDistanceToNow, format } = useDateFns();

  return (
    <Grid container spacing={4}>
      <Grid item md>
        <Typography variant="overline">Status</Typography>
        <Typography sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <UiStatusDot variant={resolveStatusDotVariant(deployment.outcome)} />
          {resolveOutcome(deployment.outcome)}
        </Typography>
      </Grid>
      <Grid item md>
        <Box>
          <Typography variant="overline">Deployed by</Typography>
          <Typography>{deployment.executedBy.name ?? "N/A"}</Typography>
        </Box>
      </Grid>
      <Grid item md>
        <Typography variant="overline">Deployed</Typography>
        <Typography>
          <Tooltip
            title={format(deployment.executedAt, " hh:mm:ss dd.MM.yyyy.")}
          >
            <span>
              {formatDistanceToNow(deployment.executedAt, {
                addSuffix: true,
              })}
            </span>
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item md>
        <Box>
          <Typography variant="overline">Version</Typography>
          <Typography>
            <code>{deployment.tag}</code>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

function resolveStatusDotVariant(
  outcome: DeploymentOutcomeEnum
): UiStatusDotProps["variant"] {
  switch (outcome) {
    case DeploymentOutcomeEnum.Pending:
      return "pending";
    case DeploymentOutcomeEnum.Failed:
      return "error";
    case DeploymentOutcomeEnum.Successful:
      return "success";
  }
}

function resolveOutcome(outcome: DeploymentOutcomeEnum): string {
  switch (outcome) {
    case DeploymentOutcomeEnum.Pending:
      return "Deploying";
    case DeploymentOutcomeEnum.Failed:
      return "Failed";
    case DeploymentOutcomeEnum.Successful:
      return "Successful";
  }
}
