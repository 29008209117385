import { UiButton } from "@hooloovoodoo/mui-react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
} from "@mui/material";
import React from "react";

import { ConnectRepositoriesList } from "./ConnectRepositoriesList";

export const ConnectRepositoryDialog: React.FC<DialogProps> = (props) => {
  return (
    <Dialog {...props}>
      <DialogContent>
        <ConnectRepositoriesList />
      </DialogContent>
      <DialogActions>
        <UiButton
          variant="outlined"
          onClick={() => props.onClose?.({}, "escapeKeyDown")}
        >
          Close
        </UiButton>
      </DialogActions>
    </Dialog>
  );
};
