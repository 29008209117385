import { UseQueryResult, useQuery } from "react-query";

import { ServiceEnvelope } from "src/generated-sources/openapi";
import { useCurrentOrganization } from "src/modules/organization/hooks";

import { useServiceApi } from "./useApi";

export interface UseListServicesQueryOptions {
  poll?: boolean;
}

export function useListServicesQuery(
  options?: UseListServicesQueryOptions
): UseQueryResult<ServiceEnvelope> {
  const organization = useCurrentOrganization();
  const serviceApi = useServiceApi();
  return useQuery(
    ["services", organization.id],
    () => {
      return serviceApi.listServices({
        organizationId: organization.id,
      });
    },
    {
      refetchInterval: options?.poll ? 3000 : false,
    }
  );
}
