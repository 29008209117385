import { SignIn } from "@hooloovoodoo/mui-react-auth";
import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";

import { Logo } from "src/modules/common/components";

export const LoginPage: React.FC = () => {
  return (
    <Container maxWidth={false} sx={{ maxWidth: "27rem", paddingY: 10 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Logo />
        <Typography variant="h2">Sign in to your account</Typography>
        <Typography>
          Or <Link href="#">start your 14-day free trial</Link>
        </Typography>
      </Box>

      <SignIn
        methods={["github"]}
        PaperProps={{ sx: { marginTop: 8 }, elevation: 0 }}
      />
    </Container>
  );
};
