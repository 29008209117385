import { Box, Typography } from "@mui/material";
import React from "react";

interface RepositoryStatProps {
  title: string;
  value: string;
  subvalue?: string;
}

export const RepositoryStat: React.FC<RepositoryStatProps> = ({
  title,
  value,
  subvalue,
}) => {
  return (
    <Box
      sx={(theme) => ({
        borderTop: "1px solid",
        borderColor: theme.palette.gray200,
        px: theme.spacing(4),
        py: theme.spacing(6),
        [theme.breakpoints.up("sm")]: {
          px: theme.spacing(6),
        },
        [theme.breakpoints.up("lg")]: {
          px: theme.spacing(8),
        },
      })}
    >
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
        }}
        color="gray400"
      >
        {title}
      </Typography>
      <Typography
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "baseline",
          columnGap: 2,
          fontSize: "2.25rem",
          lineHeight: "2.5rem",
          fontWeight: 600,
        }}
      >
        {value}
        {subvalue && (
          <Typography component={"span"} variant="body2" color="gray400">
            {subvalue}
          </Typography>
        )}
      </Typography>
    </Box>
  );
};
