import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";

import { ServiceVariableEnvelope } from "src/generated-sources/openapi";

import { useServiceVariableApi } from "./useApi";

export function useListVariablesQuery(
  serviceId: string,
  options?: Partial<UseQueryOptions<ServiceVariableEnvelope, Error>>
): UseQueryResult<ServiceVariableEnvelope> {
  const api = useServiceVariableApi();
  return useQuery({
    queryKey: ["service", serviceId, "variables"],
    queryFn: () =>
      api.listVariables({
        serviceId,
        pageable: {
          size: 200,
        },
      }),
    ...options,
  });
}
