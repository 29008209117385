import { LRUCache } from "lru-cache";
import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from "react-query";

import {
  ServiceApi,
  ServiceLogs,
  ServiceStatusEnvelope,
} from "src/generated-sources/openapi";

import { useServiceApi } from "./useApi";

export function useGetServiceStatuses(
  serviceId: string
): UseQueryResult<ServiceStatusEnvelope> {
  const serviceApi = useServiceApi();
  return useQuery(
    ["service", serviceId, "statuses"],
    () => {
      return serviceApi.getServiceStatuses({
        serviceId,
      });
    },
    {
      refetchInterval: 3000,
    }
  );
}

export function useGetServiceLogs(
  serviceId: string
): UseInfiniteQueryResult<ServiceLogs> {
  const serviceApi = useServiceApi();
  return useInfiniteQuery(
    ["service", serviceId, "logs"],
    async ({ pageParam }) => {
      return await getBufferedServiceLogs(serviceApi, serviceId, pageParam);
    },
    {
      refetchInterval: 3000,
      getNextPageParam: (lastPage) => {
        const cursor = lastPage?.cursor;
        return cursor && cursor.length > 0 ? cursor : undefined;
      },
      select: (data) => ({
        pages: [...data.pages].reverse(),
        pageParams: [...data.pageParams].reverse(),
      }),
    }
  );
}

const options = {
  max: 500,
};

const cache = new LRUCache(options);

function getCachedPage(serviceId: string, cursor?: string): ServiceLogs | null {
  if (!cursor) {
    return null;
  }
  const cacheKey = `${serviceId}:${cursor}`;
  return cache.get(cacheKey) as ServiceLogs | null;
}

async function getCachedPageOrFetch(
  serviceApi: ServiceApi,
  serviceId: string,
  cursor?: string
): Promise<ServiceLogs> {
  const cached = getCachedPage(serviceId, cursor);
  if (cached) {
    return cached;
  }

  const logs = await serviceApi.getServiceLogs({
    serviceId,
    date: new Date(),
    cursor,
  });
  const cacheKey = `${serviceId}:${logs.current}`;
  cache.set(cacheKey, logs);
  return logs;
}

/**
 * Load up to 2KB of logs from the service API.
 * This is done because service logs can be very small, and we want to show a reasonable amount of logs.
 * @param serviceApi
 * @param serviceId
 * @param cursor
 */
async function getBufferedServiceLogs(
  serviceApi: ServiceApi,
  serviceId: string,
  cursor?: string
): Promise<ServiceLogs> {
  // 2 kilobytes
  const THRESHOLD = 2048;

  const currentPage = await getCachedPageOrFetch(serviceApi, serviceId, cursor);

  const result: ServiceLogs = { ...currentPage };
  while (result.logs.length < THRESHOLD) {
    // If there is no cursor, we are at the end of the logs.
    if (!result.cursor) {
      return result;
    }

    const nextResult = await getCachedPageOrFetch(
      serviceApi,
      serviceId,
      result.cursor
    );
    if (nextResult.logs.length === 0) {
      return result;
    }
    // Prepend the next page of logs to the current page.
    result.logs = nextResult.logs + "\n" + result.logs;
    // Update the cursor to the next page.
    result.cursor = nextResult.cursor;
  }
  return result;
}
