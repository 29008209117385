import { UseQueryResult, useQuery } from "react-query";

import { ServiceDeploymentMetric } from "src/generated-sources/openapi";

import { useServiceApi } from "./useApi";

export function useGetServiceDeploymentMetricsQuery(
  serviceId: string
): UseQueryResult<ServiceDeploymentMetric> {
  const serviceApi = useServiceApi();
  return useQuery(["service", serviceId, "statistics"], () => {
    return serviceApi.getServiceDeploymentsMetric({
      serviceId,
    });
  });
}
