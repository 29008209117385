import { UiButton } from "@hooloovoodoo/mui-react";
import { Stack, TextField } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { useForm } from "react-hook-form";

import { Service } from "src/generated-sources/openapi";

export interface DetailsFormProps {
  service: Service;
}

interface FormValues {
  name: string;
}

export const DetailsForm: React.FC<DetailsFormProps> = ({ service }) => {
  const form = useForm<FormValues>({
    defaultValues: {
      name: service.name,
    },
  });
  const submit = useMemoizedFn(() => {});

  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <Stack spacing={4}>
        <TextField fullWidth label="Name" {...form.register("name")} />
        <UiButton
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          disabled={
            !form.formState.isValid ||
            !form.formState.isDirty ||
            form.formState.isSubmitting
          }
        >
          Save
        </UiButton>
      </Stack>
    </form>
  );
};
