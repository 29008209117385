import {
  UiButton,
  UiDialogActions,
  UiDialogIconTitle,
  UiWarningCircle,
} from "@hooloovoodoo/mui-react";
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { Database } from "src/generated-sources/openapi";

export interface DeleteDatabaseDialogContentProps {
  database: Database;
  confirmDeletion: (database: Database) => Promise<void>;
  closeDialog: () => void;
}

export const DeleteDatabaseDialogContent: React.FC<
  DeleteDatabaseDialogContentProps
> = ({ database, closeDialog, confirmDeletion }) => {
  const form = useForm({
    defaultValues: {
      agree1: false,
      agree2: false,
    },
  });
  const submit = useMemoizedFn(async () => {
    try {
      await confirmDeletion(database);
    } catch (e) {
      // Ignore error, handled above
    }
  });
  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <UiDialogIconTitle icon={<UiWarningCircle />} title="Delete database?" />
      <DialogContent>
        <Stack spacing={4}>
          <Typography variant="body2" color="text.secondary" align="center">
            Are you sure you want to delete the database? This action is
            irreversible.
          </Typography>
          <Controller
            name="agree1"
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox />}
                label="I understand that this action is irreversible."
                disabled={form.formState.isSubmitting}
                {...field}
                onChange={(event, checked) => field.onChange(checked)}
              />
            )}
          />
          <Controller
            name="agree2"
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <>
                    I want to delete database <strong>{database.name}</strong>
                  </>
                }
                disabled={form.formState.isSubmitting}
                {...field}
                onChange={(event, checked) => field.onChange(checked)}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <UiDialogActions>
        <Grid container spacing={4}>
          <Grid item xs={12} sm>
            <UiButton
              color="gray"
              variant="outlined"
              fullWidth
              onClick={closeDialog}
              disabled={form.formState.isSubmitting}
            >
              Cancel
            </UiButton>
          </Grid>
          <Grid item xs={12} sm>
            <UiButton
              color="red"
              variant="contained"
              fullWidth
              type="submit"
              disabled={form.formState.isSubmitting || !form.formState.isValid}
            >
              Delete
            </UiButton>
          </Grid>
        </Grid>
      </UiDialogActions>
    </form>
  );
};
