import { PageContainer } from "@hooloovoodoo/mui-react";
import React from "react";

import { useCurrentOrganization } from "src/modules/organization/hooks";

import { CreateServiceForm } from "../components";

const CreateServiceContainer: React.FC = () => (
  <PageContainer sx={{ padding: 4, overflow: "auto" }}>
    <CreateServiceForm />
  </PageContainer>
);

export const CreateServicePage: React.FC = () => {
  const currentOrganization = useCurrentOrganization();
  return <CreateServiceContainer key={currentOrganization.id} />;
};
