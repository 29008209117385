import { NotFoundSimple } from "@hooloovoodoo/mui-react";
import { Box } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router";

import { DeploymentsRoutes } from "../Deployments";
import { LogsRoutes } from "../Logs";
import { Overview } from "../Overview";
import { Settings } from "../Settings";
import { VariableList } from "../Variables";

export const ServiceRoutes: React.FC = () => (
  <Box flexGrow={1} display="flex" flexDirection="column" overflow="auto">
    <Routes>
      <Route path="/deployments/*" element={<DeploymentsRoutes />} />
      <Route path="/logs/*" element={<LogsRoutes />} />
      <Route path="/variables/*" element={<VariableList />} />
      <Route path="/settings/*" element={<Settings />} />
      <Route index element={<Overview />} />
      <Route path="/*" element={<NotFoundSimple />} />
    </Routes>
  </Box>
);
