import { useParams } from "react-router";

export function useRouteServiceId(): string {
  const { serviceId } = useParams<{ serviceId: string }>();
  if (!serviceId) {
    throw new Error("No serviceId in route");
  }
  return serviceId;
}

export function useRouteDeploymentId(): string {
  const { deploymentId } = useParams<{ deploymentId: string }>();
  if (!deploymentId) {
    throw new Error("No deploymentId in route");
  }
  return deploymentId;
}
