/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Database } from './Database';
import {
    DatabaseFromJSON,
    DatabaseFromJSONTyped,
    DatabaseToJSON,
} from './Database';
import type { PaginationModel } from './PaginationModel';
import {
    PaginationModelFromJSON,
    PaginationModelFromJSONTyped,
    PaginationModelToJSON,
} from './PaginationModel';

/**
 * 
 * @export
 * @interface DatabaseEnvelope
 */
export interface DatabaseEnvelope {
    /**
     * 
     * @type {Array<Database>}
     * @memberof DatabaseEnvelope
     */
    data: Array<Database>;
    /**
     * 
     * @type {PaginationModel}
     * @memberof DatabaseEnvelope
     */
    pagination: PaginationModel;
}

/**
 * Check if a given object implements the DatabaseEnvelope interface.
 */
export function instanceOfDatabaseEnvelope(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function DatabaseEnvelopeFromJSON(json: any): DatabaseEnvelope {
    return DatabaseEnvelopeFromJSONTyped(json, false);
}

export function DatabaseEnvelopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseEnvelope {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(DatabaseFromJSON)),
        'pagination': PaginationModelFromJSON(json['pagination']),
    };
}

export function DatabaseEnvelopeToJSON(value?: DatabaseEnvelope | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(DatabaseToJSON)),
        'pagination': PaginationModelToJSON(value.pagination),
    };
}

