/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InstanceTypeEnvelope,
  Pageable,
  Problem,
} from '../models';
import {
    InstanceTypeEnvelopeFromJSON,
    InstanceTypeEnvelopeToJSON,
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
} from '../models';

export interface ListInstanceTypesRequest {
    pageable?: Pageable;
}

/**
 * 
 */
export class InstanceTypesApi extends runtime.BaseAPI {

    /**
     * List instance types. Currently only returns database instance types.
     * List instance types.
     */
    async listInstanceTypesRaw(requestParameters: ListInstanceTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstanceTypeEnvelope>> {
        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/instance-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceTypeEnvelopeFromJSON(jsonValue));
    }

    /**
     * List instance types. Currently only returns database instance types.
     * List instance types.
     */
    async listInstanceTypes(requestParameters: ListInstanceTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstanceTypeEnvelope> {
        const response = await this.listInstanceTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
