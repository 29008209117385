import { UiStatusDot } from "@hooloovoodoo/mui-react";
import { Box, ListItem, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

interface EntityListItemProps {
  to?: string;
  name: string;
  additionalInfo: string;
}

export const EntityListItem: React.FC<
  React.PropsWithChildren<EntityListItemProps>
> = ({ children, to, name, additionalInfo }) => {
  return (
    <ListItem
      sx={{
        borderBottom: "1px solid gray200",
        marginLeft: 0,
        marginBottom: 1,
        ...(to ? { cursor: "pointer" } : null),
      }}
      component={to ? RouterLink : "div"}
      to={to}
    >
      <Box
        sx={{
          minWidth: 0,
          flex: "1 1 auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 3,
          }}
        >
          <UiStatusDot />
          <Typography
            sx={{
              minWidth: 0,
              fontWeight: 600,
            }}
            variant={"body1"}
          >
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            columnGap: 2.5,
            whiteSpace: "nowrap",
          }}
        >
          <Typography variant="body2" color="gray400">
            {additionalInfo}
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              borderRadius: "full",
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
            {children}
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};
