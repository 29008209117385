import { useSnackbar } from "notistack";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { ServiceSecret } from "src/generated-sources/openapi";

import { useServiceSecretApi } from "./useApi";

export interface SetSecretMutationRequest {
  serviceId: string;
  name: string;
  value: string;
}

export function useSetSecretMutation(): UseMutationResult<
  ServiceSecret,
  Error,
  SetSecretMutationRequest
> {
  const api = useServiceSecretApi();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (request: SetSecretMutationRequest) => {
      return api.setSecret({
        serviceId: request.serviceId,
        serviceSecretName: request.name,
        setServiceSecretRequest: {
          value: request.value,
        },
      });
    },
    onSuccess: (_, variables) => {
      snackbar.enqueueSnackbar(`Secret "${variables.name}" value set.`, {
        variant: "success",
      });
      queryClient.invalidateQueries([
        "service",
        variables.serviceId,
        "secrets",
      ]);
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });
}
