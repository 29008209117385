import { UiDialogLoader } from "@hooloovoodoo/mui-react";
import { Dialog } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { bindDialog, usePopupState } from "material-ui-popup-state/hooks";
import React, { Ref, forwardRef, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router";

import { useDeleteDatabaseMutation, useGetDatabaseQuery } from "../../hooks";
import { DeleteDatabaseDialogContent } from "./DeleteDatabaseDialogContent";

export interface DeleteDatabaseDialogRef {
  open: (databaseId: string) => void;
}

export interface DeleteDatabaseDialogProps {
  ref?: Ref<DeleteDatabaseDialogRef>;
}

interface DialogState {
  databaseId: string;
  openTime: Date;
}

export const DeleteDatabaseDialog: React.FC<DeleteDatabaseDialogProps> =
  forwardRef((props, ref) => {
    const navigate = useNavigate();
    const popupState = usePopupState({ variant: "dialog" });
    const [state, setState] = useState<DialogState | null>(null);

    const open = useMemoizedFn((databaseId: string) => {
      setState({
        databaseId,
        openTime: new Date(),
      });
      popupState.open();
    });

    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      [open]
    );

    const { data: database, isLoading } = useGetDatabaseQuery(
      state?.databaseId ?? "",
      {
        enabled: !!state?.databaseId,
      }
    );

    const deleteDatabase = useDeleteDatabaseMutation({
      onSuccess: () => {
        popupState.close();
        navigate("/databases");
      },
    });

    return (
      <Dialog {...bindDialog(popupState)} maxWidth="xs" fullWidth>
        {isLoading && <UiDialogLoader />}
        {database && (
          <DeleteDatabaseDialogContent
            key={state?.openTime.toISOString()}
            database={database}
            confirmDeletion={deleteDatabase.mutateAsync}
            closeDialog={popupState.close}
          />
        )}
      </Dialog>
    );
  });
