import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

export interface RadBreadcrumbsProps {
  entity?: {
    name: string;
  };
  entityName?: string;
}

export const RadBreadcrumbs: React.FC<RadBreadcrumbsProps> = ({
  entity,
  entityName,
}) => {
  const location = useLocation();
  let pathnames = location.pathname.split("/").filter((x) => x);

  if (entity && entityName) {
    const entityIndex = pathnames.findIndex((value) => value === entityName);
    if (entityIndex !== -1) {
      pathnames[entityIndex + 1] = entity.name;
    }
  }

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon
          sx={{
            color: "gray400",
            fontSize: "0.8rem",
          }}
        />
      }
      sx={{ px: 4, py: 2 }}
      aria-label="breadcrumb"
    >
      <Link
        component={RouterLink}
        // underline="hover"
        // color="inherit"
        to="/"
        style={{ textTransform: "capitalize" }}
      >
        <Typography variant="body2" color="gray400">
          Home
        </Typography>
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <Typography
            variant="body2"
            color="gray400"
            key={to}
            style={{ textTransform: "capitalize" }}
          >
            {value}
          </Typography>
        ) : (
          <Link
            component={RouterLink}
            // underline="hover"
            // color="inherit"
            to={to}
            key={to}
            style={{ textTransform: "capitalize" }}
          >
            <Typography variant="body2" color="gray400">
              {value}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
