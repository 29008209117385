import { NotificationContext } from "@hooloovoodoo/mui-react-auth";
import { useSnackbar } from "notistack";

export const NotificationProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  const { enqueueSnackbar } = useSnackbar();

  const notificationOptions = {
    onSuccessNotification: (content?: React.ReactNode) => {
      enqueueSnackbar(content || "Operation successful", {
        variant: "success",
      });
    },
    onErrorNotification: (content?: React.ReactNode) => {
      enqueueSnackbar(content || "An error occurred", { variant: "error" });
    },
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationOptions,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};
