import { useSnackbar } from "notistack";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";

import { CreateDatabaseRequest, Database } from "src/generated-sources/openapi";

import { useCurrentOrganization } from "../../organization/hooks";
import { useDatabaseApi } from "./useDatabaseApi";

export function useCreateDatabaseMutation(): UseMutationResult<
  Database,
  Error,
  CreateDatabaseRequest,
  void
> {
  const queryClient = useQueryClient();
  const organization = useCurrentOrganization();
  const databaseApi = useDatabaseApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  return useMutation(
    ["create-database"],
    (createDatabaseRequest) =>
      databaseApi.createDatabase({
        organizationId: organization.id,
        createDatabaseRequest,
      }),
    {
      onSuccess: (database) => {
        queryClient.invalidateQueries(["databases"]);
        snackbar.enqueueSnackbar("Database created", {
          variant: "success",
        });
        navigate(`/databases/${database.id}`);
      },
      onError: () => {
        snackbar.enqueueSnackbar("Failed to create database", {
          variant: "error",
        });
      },
    }
  );
}
