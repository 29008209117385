/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeploymentStatus
 */
export interface DeploymentStatus {
    /**
     * 
     * @type {string}
     * @memberof DeploymentStatus
     */
    deploymentId: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentStatus
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof DeploymentStatus
     */
    statusCode: number;
    /**
     * 
     * @type {Date}
     * @memberof DeploymentStatus
     */
    reportedAt: Date;
}

/**
 * Check if a given object implements the DeploymentStatus interface.
 */
export function instanceOfDeploymentStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deploymentId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "statusCode" in value;
    isInstance = isInstance && "reportedAt" in value;

    return isInstance;
}

export function DeploymentStatusFromJSON(json: any): DeploymentStatus {
    return DeploymentStatusFromJSONTyped(json, false);
}

export function DeploymentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deploymentId': json['deployment_id'],
        'status': json['status'],
        'statusCode': json['status_code'],
        'reportedAt': (new Date(json['reported_at'])),
    };
}

export function DeploymentStatusToJSON(value?: DeploymentStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deployment_id': value.deploymentId,
        'status': value.status,
        'status_code': value.statusCode,
        'reported_at': (value.reportedAt.toISOString()),
    };
}

