import CircleStackIcon from "@heroicons/react/24/solid/CircleStackIcon";
import CommandLineIcon from "@heroicons/react/24/solid/CommandLineIcon";
import PlusCircleIcon from "@heroicons/react/24/solid/PlusCircleIcon";
import { Sidebar, UiHeroIcon } from "@hooloovoodoo/mui-react";
import { Divider } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

import { Service } from "src/generated-sources/openapi";
import { useListServicesQuery } from "src/modules/services/hooks";

export const SidebarMenu: React.FC = () => {
  const { data: services } = useListServicesQuery();

  return (
    <Sidebar.Nav>
      <Sidebar.Header title="Services" />
      {services?.data.map((service: Service) => (
        <Sidebar.MenuItem
          key={service.id}
          component={NavLink}
          to={`/services/${service.id}`}
        >
          {service.name}
        </Sidebar.MenuItem>
      ))}
      <Sidebar.MenuItem component={NavLink} to="/services/new">
        <Sidebar.Icon>
          <UiHeroIcon icon={PlusCircleIcon} />
        </Sidebar.Icon>
        Deploy new service
      </Sidebar.MenuItem>
      <Divider sx={{ my: 2 }} />
      <Sidebar.MenuItem component={NavLink} to="/repositories">
        <Sidebar.Icon>
          <UiHeroIcon icon={CommandLineIcon} />
        </Sidebar.Icon>
        Repositories
      </Sidebar.MenuItem>
      <Sidebar.MenuItem component={NavLink} to="/databases">
        <Sidebar.Icon>
          <UiHeroIcon icon={CircleStackIcon} />
        </Sidebar.Icon>
        Database
      </Sidebar.MenuItem>
    </Sidebar.Nav>
  );
};
