/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstanceType
 */
export interface InstanceType {
    /**
     * The unique slug identifier
     * @type {string}
     * @memberof InstanceType
     */
    slug: string;
    /**
     * Human readable name
     * @type {string}
     * @memberof InstanceType
     */
    name: string;
    /**
     * The categories this instance type can be used in
     * @type {Array<string>}
     * @memberof InstanceType
     */
    categories: Array<InstanceTypeCategoriesEnum>;
    /**
     * The type of node this instance type is for
     * @type {string}
     * @memberof InstanceType
     */
    nodeType: InstanceTypeNodeTypeEnum;
    /**
     * The number of CPUs
     * @type {number}
     * @memberof InstanceType
     */
    cpu: number;
    /**
     * The amount of RAM in GB
     * @type {number}
     * @memberof InstanceType
     */
    ram: number;
}


/**
 * @export
 */
export const InstanceTypeCategoriesEnum = {
    Database: 'DATABASE',
    Server: 'SERVER'
} as const;
export type InstanceTypeCategoriesEnum = typeof InstanceTypeCategoriesEnum[keyof typeof InstanceTypeCategoriesEnum];

/**
 * @export
 */
export const InstanceTypeNodeTypeEnum = {
    Basic: 'BASIC',
    GeneralPurpose: 'GENERAL_PURPOSE',
    StorageOptimized: 'STORAGE_OPTIMIZED',
    MemoryOptimized: 'MEMORY_OPTIMIZED'
} as const;
export type InstanceTypeNodeTypeEnum = typeof InstanceTypeNodeTypeEnum[keyof typeof InstanceTypeNodeTypeEnum];


/**
 * Check if a given object implements the InstanceType interface.
 */
export function instanceOfInstanceType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "nodeType" in value;
    isInstance = isInstance && "cpu" in value;
    isInstance = isInstance && "ram" in value;

    return isInstance;
}

export function InstanceTypeFromJSON(json: any): InstanceType {
    return InstanceTypeFromJSONTyped(json, false);
}

export function InstanceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slug': json['slug'],
        'name': json['name'],
        'categories': json['categories'],
        'nodeType': json['nodeType'],
        'cpu': json['cpu'],
        'ram': json['ram'],
    };
}

export function InstanceTypeToJSON(value?: InstanceType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slug': value.slug,
        'name': value.name,
        'categories': value.categories,
        'nodeType': value.nodeType,
        'cpu': value.cpu,
        'ram': value.ram,
    };
}

