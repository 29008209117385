import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";

import { Database } from "src/generated-sources/openapi";

import { useDatabaseApi } from "./useDatabaseApi";

export function useGetDatabaseQuery(
  databaseId: string,
  options?: UseQueryOptions<Database>
): UseQueryResult<Database> {
  const databaseApi = useDatabaseApi();

  return useQuery({
    queryKey: ["database", databaseId],
    queryFn: () =>
      databaseApi.getDatabase({
        databaseId,
      }),
    ...options,
  });
}
