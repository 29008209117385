import { Feed } from "@hooloovoodoo/mui-react";
import React from "react";

import { DeploymentStatus } from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";

import { DeploymentLogIcon } from "./DeploymentLogIcon";

export interface DeploymentLogsProps {
  statuses: Array<DeploymentStatus>;
}

export const DeploymentLogs: React.FC<DeploymentLogsProps> = ({ statuses }) => {
  const { formatDistanceToNow } = useDateFns();
  return (
    <Feed.List dense>
      {statuses.map((deploymentLog, index) => (
        <Feed.ListItem key={index}>
          <DeploymentLogIcon statusCode={deploymentLog.statusCode} />
          <Feed.Content>{deploymentLog.status}</Feed.Content>
          <Feed.DateTime>
            {formatDistanceToNow(deploymentLog.reportedAt, {
              addSuffix: true,
            })}
          </Feed.DateTime>
        </Feed.ListItem>
      ))}
    </Feed.List>
  );
};
