import { SimpleNav } from "@hooloovoodoo/mui-react";
import React from "react";
import { NavLink } from "react-router-dom";

import { useRouteDatabaseId } from "../hooks";

export const DatabaseNav: React.FC = () => {
  const databaseId = useRouteDatabaseId();
  const tabs = [
    {
      path: "",
      label: "Overview",
      end: true,
    },
    {
      path: "/connection",
      label: "Connection",
    },
  ];
  return (
    <SimpleNav.Root sx={{ paddingX: 4, marginBottom: 4 }}>
      {tabs.map(({ path, label, end }) => (
        <SimpleNav.TabButton
          component={NavLink}
          to={`/databases/${databaseId}${path}`}
          end={end}
          key={path}
        >
          {label}
        </SimpleNav.TabButton>
      ))}
    </SimpleNav.Root>
  );
};
