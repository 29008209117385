import { Stack } from "@mui/material";
import React from "react";

import { DeploymentOutcomeEnum } from "src/generated-sources/openapi";

import {
  useGetDeploymentQuery,
  useListServiceDeploymentStatusesQuery,
  useRouteDeploymentId,
} from "../../hooks";
import {
  DeploymentInfo,
  DeploymentInfoSkeleton,
  DeploymentLogs,
  DeploymentLogsSkeleton,
} from "./DeploymentsView";

export const ViewDeployment: React.FC = () => {
  const deploymentId = useRouteDeploymentId();
  const deployment = useGetDeploymentQuery(deploymentId);
  const isPending = deployment.data?.outcome === DeploymentOutcomeEnum.Pending;
  const deploymentLogs = useListServiceDeploymentStatusesQuery(deploymentId, {
    poll: isPending,
  });

  if (!deployment.data || !deploymentLogs.data) {
    return (
      <Stack spacing={10} padding={4}>
        <DeploymentInfoSkeleton />
        <DeploymentLogsSkeleton />
      </Stack>
    );
  }

  return (
    <Stack spacing={10} padding={4}>
      <DeploymentInfo deployment={deployment.data} />
      <DeploymentLogs statuses={deploymentLogs.data.data} />
    </Stack>
  );
};
