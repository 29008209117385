import { UseQueryResult, useQuery } from "react-query";

import { DeploymentEnvelope } from "src/generated-sources/openapi";

import { useServiceApi } from "./useApi";

export function useListServiceDeploymentsQuery(
  serviceId: string
): UseQueryResult<DeploymentEnvelope> {
  const serviceApi = useServiceApi();
  return useQuery(["service", serviceId, "deployments"], () =>
    serviceApi.listServiceDeployments({
      serviceId,
      pageable: {
        size: 100,
        page: 0,
        sort: ["executedAt,desc"],
      },
    })
  );
}
