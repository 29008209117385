import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import { Feed, UiHeroIcon } from "@hooloovoodoo/mui-react";
import React from "react";

export interface DeploymentLogIconProps {
  statusCode: number;
}

export const DeploymentLogIcon: React.FC<DeploymentLogIconProps> = ({
  statusCode,
}) => {
  if (isSuccess(statusCode)) {
    return (
      <Feed.Icon backgroundColor="green500">
        <UiHeroIcon icon={CheckIcon} />
      </Feed.Icon>
    );
  } else {
    return (
      <Feed.Icon backgroundColor="red500">
        <UiHeroIcon icon={XMarkIcon} />
      </Feed.Icon>
    );
  }
};

function isSuccess(statusCode: number): boolean {
  return statusCode >= 200 && statusCode < 300;
}
