/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServerStatus
 */
export interface ServerStatus {
    /**
     * 
     * @type {string}
     * @memberof ServerStatus
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof ServerStatus
     */
    statusCode: number;
    /**
     * 
     * @type {Date}
     * @memberof ServerStatus
     */
    reportedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ServerStatus
     */
    serverId: string;
}

/**
 * Check if a given object implements the ServerStatus interface.
 */
export function instanceOfServerStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "statusCode" in value;
    isInstance = isInstance && "reportedAt" in value;
    isInstance = isInstance && "serverId" in value;

    return isInstance;
}

export function ServerStatusFromJSON(json: any): ServerStatus {
    return ServerStatusFromJSONTyped(json, false);
}

export function ServerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServerStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'statusCode': json['status_code'],
        'reportedAt': (new Date(json['reported_at'])),
        'serverId': json['server_id'],
    };
}

export function ServerStatusToJSON(value?: ServerStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'status_code': value.statusCode,
        'reported_at': (value.reportedAt.toISOString()),
        'server_id': value.serverId,
    };
}

