import { UseQueryResult, useQuery } from "react-query";

import {
  Deployment,
  DeploymentOutcomeEnum,
} from "src/generated-sources/openapi";

import { useServiceApi } from "./useApi";

const REFETCH_INTERVAL_MS = 5000;

export function useGetServiceLatestDeploymentQuery(
  serviceId: string
): UseQueryResult<Deployment> {
  const serviceApi = useServiceApi();
  return useQuery(
    ["service", serviceId, "latest-deployment"],
    async () => {
      const envelope = await serviceApi.listServiceDeployments({
        serviceId,
        pageable: {
          size: 1,
          page: 0,
          sort: ["executedAt,desc"],
        },
      });
      return envelope.data.length > 0 ? envelope.data[0] : null;
    },
    {
      refetchInterval: (deployment) => {
        return !deployment ||
          deployment.outcome === DeploymentOutcomeEnum.Pending
          ? REFETCH_INTERVAL_MS
          : false;
      },
    }
  );
}
