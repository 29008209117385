import { useSnackbar } from "notistack";
import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "react-query";

import { Service } from "src/generated-sources/openapi";

import { useServiceApi } from "./useApi";

export function useDeleteServiceMutation(
  options: UseMutationOptions<void, Error, Service> = {}
): UseMutationResult<void, Error, Service> {
  const serviceApi = useServiceApi();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const { onSuccess, onError, ...other } = options;

  return useMutation({
    mutationFn: async (service: Service) => {
      await serviceApi.deleteService({ serviceId: service.id });
    },
    onSuccess: (_, service: Service, ctx) => {
      snackbar.enqueueSnackbar(`Service ${service.name} deleted.`, {
        variant: "success",
      });
      Promise.all([
        queryClient.invalidateQueries(["services"]),
        queryClient.invalidateQueries(["service", service.id]),
      ]).then(() => onSuccess?.(_, service, ctx));
    },
    onError: (_, service: Service, ctx) => {
      snackbar.enqueueSnackbar(`Failed to delete service ${service.name}.`, {
        variant: "error",
      });
      onError?.(_, service, ctx);
    },
    ...other,
  });
}
