import {
  Divider,
  ListItemText,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React, { ChangeEvent } from "react";

import { Server } from "src/generated-sources/openapi";

import { useListServersQuery } from "../hooks";

export interface ServerSelectorProps
  extends Omit<TextFieldProps, "onChange" | "value" | "defaultValue"> {
  defaultValue?: Server | null;
  value?: Server | null;
  onChange: (server: Server | null) => void;
  allowCreate?: boolean;
}

// Item id for "Create a new server" menu item
const CREATE_VALUE = "create";

/**
 * Server selector dropdown field.
 */
export const ServerSelector: React.FC<ServerSelectorProps> = React.forwardRef(
  ({ onChange, defaultValue, value, allowCreate, ...props }, ref) => {
    const { data: servers } = useListServersQuery();
    const onSelectServer = useMemoizedFn(
      (event: ChangeEvent<HTMLInputElement>) => {
        const id = event.target.value;
        const selectedServer = servers?.data.find((server) => server.id === id);
        if (selectedServer) {
          onChange?.(selectedServer);
        } else {
          onChange?.(null);
        }
      }
    );

    // Show divider if there are servers and allowCreate is true
    const showDivider = allowCreate && servers && servers.data.length > 0;

    const defaultValueIfNone = allowCreate ? CREATE_VALUE : null;
    return (
      <TextField
        select
        onChange={onSelectServer}
        defaultValue={defaultValue?.id ?? defaultValueIfNone}
        value={value?.id ?? defaultValueIfNone}
        {...props}
        ref={ref}
      >
        {servers?.data.map((server) => (
          <MenuItem key={server.id} value={server.id}>
            <ListItemText
              primary={server.publicUrl}
              secondary={<>2 CPU · 2 GB RAM · 50 GB SSD</>}
            />
          </MenuItem>
        ))}
        {showDivider && <Divider />}
        {allowCreate && (
          <MenuItem key="create" value={CREATE_VALUE}>
            <ListItemText
              primary="Create new server"
              secondary="Provision a new instance and get a new domain"
            />
          </MenuItem>
        )}
      </TextField>
    );
  }
);
