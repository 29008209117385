import { PageContainer, SimpleLoader } from "@hooloovoodoo/mui-react";
import React from "react";

import { RadBreadcrumbs } from "src/modules/common/components";

import { ServiceEmptyState, ServiceList, ServicesHeader } from "../components";
import { useListServicesQuery } from "../hooks";

export const ServicesPage: React.FC = () => {
  const { data: envelope, isLoading } = useListServicesQuery();

  return (
    <PageContainer>
      <RadBreadcrumbs />
      <ServicesHeader />
      {isLoading && <SimpleLoader text="Loading services..." />}
      {envelope && envelope.data.length === 0 && <ServiceEmptyState />}
      {envelope && envelope.data.length > 0 && (
        <ServiceList services={envelope.data} />
      )}
    </PageContainer>
  );
};
