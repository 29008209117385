import { UseQueryResult, useQuery } from "react-query";

import { RepositoryEnvelope } from "src/generated-sources/openapi";
import { useCurrentOrganization } from "src/modules/organization/hooks";

import { useRepositoryApi } from "./useRepositoryApi";

export function useListRepositoriesQuery(): UseQueryResult<RepositoryEnvelope> {
  const organization = useCurrentOrganization();
  const repositoryApi = useRepositoryApi();
  return useQuery(["repositories", organization.id], () => {
    return repositoryApi.listRepositories({
      organizationId: organization.id,
    });
  });
}
