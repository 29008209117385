import { Skeleton } from "@mui/material";
import React from "react";

import { useListRegionsQuery } from "../../hooks";

export interface DatabaseRegionProps {
  region: string;
}

export const DatabaseRegion: React.FC<DatabaseRegionProps> = ({ region }) => {
  const { data: regions, isLoading } = useListRegionsQuery();
  if (isLoading || !regions) {
    return <Skeleton />;
  }
  const regionData = regions.find((it) => it.slug === region);
  if (!regionData) {
    return <>{region}</>;
  }
  return <>{regionData.city}</>;
};
