/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationModel
 */
export interface PaginationModel {
    /**
     * 
     * @type {number}
     * @memberof PaginationModel
     */
    pageNumber: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationModel
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationModel
     */
    totalResults: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationModel
     */
    totalPages: number;
}

/**
 * Check if a given object implements the PaginationModel interface.
 */
export function instanceOfPaginationModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pageNumber" in value;
    isInstance = isInstance && "pageSize" in value;
    isInstance = isInstance && "totalResults" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function PaginationModelFromJSON(json: any): PaginationModel {
    return PaginationModelFromJSONTyped(json, false);
}

export function PaginationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNumber': json['page_number'],
        'pageSize': json['page_size'],
        'totalResults': json['total_results'],
        'totalPages': json['total_pages'],
    };
}

export function PaginationModelToJSON(value?: PaginationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page_number': value.pageNumber,
        'page_size': value.pageSize,
        'total_results': value.totalResults,
        'total_pages': value.totalPages,
    };
}

