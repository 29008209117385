import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import CogIcon from "@heroicons/react/24/outline/CogIcon";
import { UiButton, UiHeroIcon } from "@hooloovoodoo/mui-react";
import { Box, List, ListItem, Stack, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link as RouterLink, useSearchParams } from "react-router-dom";

import { PostGithubAppInstallationRequest } from "src/generated-sources/openapi";
import { useListRepositoriesQuery } from "src/modules/repositories/hooks";

import { useWebhookGithubApi } from "../hooks";

export interface CallbackGithubNewInstallationPageProps {}

export const CallbackGithubInstallationPage: React.FC<
  CallbackGithubNewInstallationPageProps
> = (props) => {
  const { data: repositories } = useListRepositoriesQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const installationId = searchParams.get("installation_id");
  const setupAction = searchParams.get("setup_action");
  const executedRef = React.useRef(false);
  const qc = useQueryClient();
  const api = useWebhookGithubApi();
  const { mutate } = useMutation(
    ["github-app-installation"],
    (request: PostGithubAppInstallationRequest) => {
      return api.postGithubAppInstallation(request);
    },
    {
      onSuccess: (data) => {},
      onSettled: () => {
        qc.invalidateQueries(["repositories"]);
        qc.invalidateQueries(["services"]);
        qc.invalidateQueries(["repository"]);
        qc.invalidateQueries(["service"]);
      },
    }
  );

  useEffect(() => {
    if (code && installationId && setupAction && !executedRef.current) {
      executedRef.current = true;
      mutate({ code, installationId, setupAction });
      setSearchParams(new URLSearchParams());
    }
  }, [code, installationId, setupAction, mutate, setSearchParams]);

  return (
    <Stack
      sx={{
        width: "fit-content",
        padding: 4,
      }}
    >
      <Typography variant="h1">Successful connection</Typography>
      <Typography variant="body1" color="gray500">
        You have successfully connected your repositories
      </Typography>
      <Box
        sx={{
          borderBottom: "1px solid",
          borderColor: "gray200",
          py: 4,
        }}
      >
        <Typography variant="subtitle1">Connected repositories</Typography>
        {repositories && repositories.data.length > 0 && (
          <>
            <List>
              {repositories.data.map((repository) => {
                return (
                  <StyledListItem>
                    <UiHeroIcon icon={CheckCircleIcon} color="green500" />
                    {repository.name}
                  </StyledListItem>
                );
              })}
            </List>
            <Typography variant="body1">
              Total repositories connected: {repositories.data.length}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <UiButton
          component={RouterLink}
          to="/services/new"
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
        >
          <UiHeroIcon icon={CogIcon} />
          Create a service
        </UiButton>
      </Box>
    </Stack>
  );
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2),
  gap: theme.spacing(2),
}));
