import { UseQueryResult, useQuery } from "react-query";

import { ServerEnvelope } from "src/generated-sources/openapi";
import { useCurrentOrganization } from "src/modules/organization/hooks";

import { useServerApi } from "./useServerApi";

export function useListServersQuery(): UseQueryResult<ServerEnvelope> {
  const organization = useCurrentOrganization();
  const serverApi = useServerApi();
  return useQuery(["servers", organization.id], () => {
    return serverApi.listServers({
      organizationId: organization.id,
    });
  });
}
