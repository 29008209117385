import { SimpleNav } from "@hooloovoodoo/mui-react";
import React from "react";
import { NavLink } from "react-router-dom";

import { useRouteServiceId } from "../../../hooks";

export const ServiceNav: React.FC = () => {
  const serviceId = useRouteServiceId();
  const tabs = [
    {
      path: "",
      label: "Overview",
      end: true,
    },
    {
      path: "/deployments",
      label: "Deployments",
    },
    {
      path: "/logs",
      label: "Logs",
    },
    {
      path: "/variables",
      label: "Variables",
    },
    {
      path: "/settings",
      label: "Settings",
    },
  ];
  return (
    <SimpleNav.Root sx={{ paddingX: 4, marginBottom: 4 }}>
      {tabs.map(({ path, label, end }) => (
        <SimpleNav.TabButton
          component={NavLink}
          to={`/services/${serviceId}${path}`}
          end={end}
          key={path}
        >
          {label}
        </SimpleNav.TabButton>
      ))}
    </SimpleNav.Root>
  );
};
