import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  PageHeaderContainer,
  UiButton,
  UiHeroIcon,
} from "@hooloovoodoo/mui-react";
import { Box } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const DatabaseHeader: React.FC = () => (
  <PageHeaderContainer
    title="Databases"
    actions={
      <Box>
        <UiButton
          variant="contained"
          color="indigo"
          component={RouterLink}
          to="/databases/new"
        >
          <UiHeroIcon icon={PlusIcon} />
          Add database
        </UiButton>
      </Box>
    }
  />
);
