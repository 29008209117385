import { UseQueryResult, useQuery } from "react-query";

import { ServerStatusEnvelope } from "src/generated-sources/openapi";

import { useServerApi } from "./useServerApi";

export interface UseListServerLogsQueryOptions {
  poll?: boolean;
}

export function useListServerStatusesQuery(
  serverId: string,
  options?: UseListServerLogsQueryOptions
): UseQueryResult<ServerStatusEnvelope> {
  const serverApi = useServerApi();
  return useQuery(
    ["servers", serverId, "logs"],
    () => {
      return serverApi.listServerStatuses({
        serverId,
        pageable: {
          page: 0,
          size: 100,
          sort: ["-reportedAt"],
        },
      });
    },
    {
      refetchInterval: options?.poll ? 5000 : false,
    }
  );
}
