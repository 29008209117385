import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";

import { Service } from "src/generated-sources/openapi";

import {
  isDeleteServiceDisabled,
  isDeployServiceDisabled,
} from "../permissions";
import { useServiceApi } from "./useApi";

export function useGetServiceQuery(
  serviceId: string,
  options?: UseQueryOptions<Service>
): UseQueryResult<Service> {
  const serviceApi = useServiceApi();
  return useQuery({
    queryKey: ["service", serviceId, "details"],
    queryFn: () => {
      return serviceApi.getService({
        serviceId,
      });
    },
    refetchInterval: (data) => {
      const refetch =
        !data || isDeployServiceDisabled(data) || isDeleteServiceDisabled(data);
      return refetch ? 5000 : false;
    },
    ...options,
  });
}
