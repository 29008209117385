import { useAuthenticationToken } from "@hooloovoodoo/mui-react-auth";
import { useThrottle } from "ahooks";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useEffect, useRef, useState } from "react";

import { WebsocketApi } from "./WebsocketApi";

export interface LogMessage {
  id: number;
  message: string;
}

export type Logs = Array<LogMessage>;

export interface LogsQuery {
  logs: Logs;
  isLoading: boolean;
}

const LOG_LIMIT = 200;

function getTargetHost() {
  if (process.env.REACT_APP_TARGET_ENV === "local") {
    return "ws://localhost:4000";
  } else {
    return "wss://app.rad12.io";
  }
}

/**
 * Connects to the event source and returns the logs as they come in.
 * @param serviceId
 */
export function useStreamingLogs(serviceId: string): LogsQuery {
  const { data: authenticationToken } = useAuthenticationToken();
  const idRef = useRef<number>(0);
  const [logs, setLogs] = useState<Logs>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const delayedLogs = useThrottle(logs, {
    wait: 1000,
  });

  const setupConnection = useMemoizedFn(() => {
    setIsLoading(true);
    const host = getTargetHost();
    const ws = new WebsocketApi({
      url: `${host}/api/ws`,
      token: authenticationToken ?? "",
      onAuthenticated: () => {
        setIsLoading(false);
        setLogs([]);
        ws.send({
          type: "SUBSCRIBE",
          topic: `/logs/${serviceId}`,
        });
      },
      onMessage: (message) => {
        switch (message.type) {
          case "LOG_MESSAGE": {
            const newMessage = {
              id: idRef.current,
              message: message.message,
            };
            idRef.current++;
            setLogs((previousLogs) => {
              const start = Math.max(previousLogs.length - LOG_LIMIT, 0);
              const keptLogs = previousLogs.slice(start);
              return [...keptLogs, newMessage];
            });
          }
        }
      },
      onError: (message) => {
        // TODO: handle error
      },
    });

    return function () {
      ws.close();
    };
  });

  useEffect(setupConnection, [serviceId, setupConnection]);

  return { logs: delayedLogs, isLoading };
}
