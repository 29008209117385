import { useSnackbar } from "notistack";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { ServiceVariable } from "src/generated-sources/openapi";

import { useServiceVariableApi } from "./useApi";

export interface SetVariableMutationRequest {
  serviceId: string;
  name: string;
  value: string;
}

export function useSetVariableMutation(): UseMutationResult<
  ServiceVariable,
  Error,
  SetVariableMutationRequest
> {
  const api = useServiceVariableApi();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  return useMutation({
    mutationFn: async (request: SetVariableMutationRequest) => {
      return api.setVariable({
        serviceId: request.serviceId,
        serviceVariableName: request.name,
        setServiceVariableRequest: {
          value: request.value,
        },
      });
    },
    onSuccess: (_, variables) => {
      snackbar.enqueueSnackbar(`Variable "${variables.name}" set`, {
        variant: "success",
      });
      queryClient.invalidateQueries([
        "service",
        variables.serviceId,
        "variables",
      ]);
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });
}
