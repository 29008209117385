import { UiDialogLoader } from "@hooloovoodoo/mui-react";
import { Dialog } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { bindDialog, usePopupState } from "material-ui-popup-state/hooks";
import React, { Ref, forwardRef, useImperativeHandle, useState } from "react";

import { useDeleteServiceMutation, useGetServiceQuery } from "../../hooks";
import { DeleteServiceDialogContent } from "./DeleteServiceDialogContent";

export interface DeleteServiceDialogRef {
  open: (serviceId: string) => void;
}

export interface DeleteServiceDialogProps {
  ref?: Ref<DeleteServiceDialogRef>;
}

interface DialogState {
  serviceId: string;
  openTime: Date;
}

export const DeleteServiceDialog: React.FC<DeleteServiceDialogProps> =
  forwardRef((props, ref) => {
    const popupState = usePopupState({ variant: "dialog" });
    const [state, setState] = useState<DialogState | null>(null);

    const open = useMemoizedFn((serviceId: string) => {
      setState({
        serviceId,
        openTime: new Date(),
      });
      popupState.open();
    });

    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      [open]
    );

    const { data: service, isLoading } = useGetServiceQuery(
      state?.serviceId ?? "",
      {
        queryKey: [
          "service-delete-dialog",
          state?.serviceId,
          state?.openTime.toISOString(),
        ],
        staleTime: 0,
        enabled: !!state && popupState.isOpen,
      }
    );

    const deleteService = useDeleteServiceMutation({
      onSuccess: () => {
        popupState.close();
      },
    });

    return (
      <Dialog {...bindDialog(popupState)} maxWidth="xs" fullWidth>
        {isLoading && <UiDialogLoader />}
        {service && (
          <DeleteServiceDialogContent
            key={state?.openTime.toISOString()}
            service={service}
            confirmDeletion={deleteService.mutateAsync}
            closeDialog={popupState.close}
          />
        )}
      </Dialog>
    );
  });
