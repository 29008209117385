import { useSnackbar } from "notistack";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { CreateServiceRequest, Service } from "src/generated-sources/openapi";
import { SpringBootError, parseError } from "src/modules/common/errors";
import { useCurrentOrganization } from "src/modules/organization/hooks";

import { useServiceApi } from "./useApi";

export function useCreateServiceMutation(): UseMutationResult<
  Service,
  Error,
  CreateServiceRequest,
  void
> {
  const queryClient = useQueryClient();
  const organization = useCurrentOrganization();
  const serviceApi = useServiceApi();
  const snackbar = useSnackbar();
  return useMutation(
    ["create-service"],
    (createServiceRequest) =>
      serviceApi
        .createService({
          organizationId: organization.id,
          createServiceRequest,
        })
        .catch((err) => parseError(err)),
    {
      onSuccess: (service) => {
        queryClient.invalidateQueries("services");
        snackbar.enqueueSnackbar(`Service ${service.name} created`, {
          variant: "success",
        });
      },
      onError: (error) => {
        const message =
          error instanceof SpringBootError
            ? error.message
            : "Failed to create service. Please try again.";
        snackbar.enqueueSnackbar(message, {
          variant: "error",
        });
      },
    }
  );
}
