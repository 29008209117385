import { UiStatusDot } from "@hooloovoodoo/mui-react";
import { Box, Typography } from "@mui/material";
import React from "react";

import { Repository } from "src/generated-sources/openapi";

interface RepositoryHeaderProps {
  repository: Repository;
}

export const RepositoryHeader: React.FC<RepositoryHeaderProps> = ({
  repository,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        columnGap: theme.spacing(8),
        rowGap: theme.spacing(4),
        px: theme.spacing(4),
        py: theme.spacing(4),
        backgroundColor: "gray100",
        [theme.breakpoints.up("sm")]: {
          flexDirection: "row",
          alignItems: "center",
          px: theme.spacing(6),
        },
        [theme.breakpoints.up("lg")]: {
          px: theme.spacing(8),
        },
      })}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 3,
          }}
        >
          <UiStatusDot />
          <Typography
            sx={{
              fontWeight: 600,
              display: "flex",
              columnGap: 3,
            }}
          >
            {repository.name}{" "}
            <Typography component={"span"} color="gray600">
              /
            </Typography>{" "}
            {/* {repository.organization} */}
          </Typography>
        </Box>
        <Typography
          sx={{
            mt: 2,
          }}
          variant="body2"
          color="gray400"
        >
          Deploys from GitHub via main branch
        </Typography>
      </Box>
      <Box
        sx={{
          flex: "none",
          borderRadius: "9999px",
          border: "1px solid",
          borderColor: "indigo400",
          backgroundColor: "indigo200",
          px: 2,
          py: 1,
          fontWeight: 500,
        }}
      >
        <Typography variant="body3" color="indigo400">
          Production
        </Typography>
      </Box>
    </Box>
  );
};
