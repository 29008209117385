import { useQuery } from "react-query";

import { useCurrentOrganization } from "../../organization/hooks";
import { useDatabaseApi } from "./useDatabaseApi";

export function useListOrganisationDatabaseQuery() {
  const organization = useCurrentOrganization();
  const databaseApi = useDatabaseApi();

  return useQuery(["databases", organization.id], () => {
    return databaseApi.listDatabases({
      organizationId: organization.id,
    });
  });
}
