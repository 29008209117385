import { Box } from "@mui/material";
import React from "react";

import { Repository } from "src/generated-sources/openapi";

import { RepositoryHeader } from "./RepositoryHeader";
import { RepositoryNav } from "./RepositoryNav";
import { RepositoryStats } from "./RepositoryStats";

interface RepositoryComponentProps {
  repository: Repository;
}

export const RepositoryComponent: React.FC<RepositoryComponentProps> = ({
  repository,
}) => {
  return (
    <Box>
      <Box>
        <RepositoryNav />
        <RepositoryHeader repository={repository} />
      </Box>
      <RepositoryStats repository={repository} />
    </Box>
  );
};
