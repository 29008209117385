import { SingleColumnDescriptionList } from "@hooloovoodoo/mui-react";
import { Box, Grid, Switch, Tooltip, styled } from "@mui/material";
import { useToggle } from "ahooks";
import React from "react";

import { DatabaseCredentials } from "src/generated-sources/openapi";

export interface DatabaseCredentialsContentProps {
  credentials: DatabaseCredentials;
}

const StyledCode = styled("code")(({ theme }) => ({
  backgroundColor: theme.palette.gray100,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadiusSm,
}));

export const DatabaseCredentialsContent: React.FC<
  DatabaseCredentialsContentProps
> = ({ credentials }) => {
  const [secretVisible, { toggle: toggleSecretVisible }] = useToggle(false);
  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={8}>
        <SingleColumnDescriptionList.Root>
          <SingleColumnDescriptionList.Header
            title="Credentials"
            subtitle="Use the connection details below to connect to your database."
          />
          <SingleColumnDescriptionList.Item
            label="Host"
            value={<StyledCode>{credentials.connection.host}</StyledCode>}
          />
          <SingleColumnDescriptionList.Item
            label="Port"
            value={<StyledCode>{credentials.connection.port}</StyledCode>}
          />
          <SingleColumnDescriptionList.Item
            label="User"
            value={<StyledCode>{credentials.connection.user}</StyledCode>}
          />
          <SingleColumnDescriptionList.Item
            label="SSL"
            value={
              <StyledCode>
                {credentials.connection.ssl ? "required" : "N/A"}
              </StyledCode>
            }
          />
          <SingleColumnDescriptionList.Item
            label="Password"
            value={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledCode>
                  {secretVisible ? credentials.connection.password : "hidden"}
                </StyledCode>
                <Tooltip title="Toggle password visibility">
                  <Switch
                    value={secretVisible}
                    onChange={toggleSecretVisible}
                  />
                </Tooltip>
              </Box>
            }
          />
          <SingleColumnDescriptionList.Item
            label="URI"
            value={
              <StyledCode>
                {secretVisible ? credentials.connection.uri : "hidden"}
              </StyledCode>
            }
          />
        </SingleColumnDescriptionList.Root>
      </Grid>
    </Grid>
  );
};
