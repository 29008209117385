import EllipsisVerticalIcon from "@heroicons/react/24/outline/EllipsisVerticalIcon";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import { UiHeroIcon, UiIconButton } from "@hooloovoodoo/mui-react";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { bindMenu } from "material-ui-popup-state";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import React, { useRef } from "react";

import { Database } from "../../../generated-sources/openapi";
import {
  DeleteDatabaseDialog,
  DeleteDatabaseDialogRef,
} from "./DeleteDatabaseDialog";

export interface DatabaseHeaderActionsProps {
  database: Database;
}

export const DatabaseHeaderActions: React.FC<DatabaseHeaderActionsProps> = ({
  database,
}) => {
  const menuPopupState = usePopupState({ variant: "popper" });
  const deleteDatabaseDialogRef = useRef<DeleteDatabaseDialogRef>(null);
  const onOpenDeleteDialog = () => {
    deleteDatabaseDialogRef.current?.open(database.id);
    menuPopupState.close();
  };

  return (
    <>
      <UiIconButton
        variant="ghost"
        color="gray"
        {...bindTrigger(menuPopupState)}
      >
        <UiHeroIcon icon={EllipsisVerticalIcon} />
      </UiIconButton>
      <Menu {...bindMenu(menuPopupState)}>
        <MenuItem onClick={onOpenDeleteDialog}>
          <ListItemIcon>
            <UiHeroIcon icon={TrashIcon} />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
      <DeleteDatabaseDialog ref={deleteDatabaseDialogRef} />
    </>
  );
};
