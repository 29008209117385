import React from "react";
import { Outlet } from "react-router-dom";

import { AppLayout } from "./AppLayout";

export const AppRoute: React.FC = () => (
  <AppLayout>
    <Outlet />
  </AppLayout>
);
