import { UiStatusDot, UiStatusDotProps } from "@hooloovoodoo/mui-react";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import {
  Deployment,
  DeploymentOutcomeEnum,
  Service,
} from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";

export interface ServiceDetailsProps {
  service: Service;
  lastDeployment?: Deployment;
}

export const ServiceDetails: React.FC<ServiceDetailsProps> = ({
  service,
  lastDeployment,
}) => {
  const { formatDistanceToNow } = useDateFns();
  const status = resolveStatus(lastDeployment);

  function resolveStatusDotVariant(
    status: Status
  ): UiStatusDotProps["variant"] {
    switch (status) {
      case "RUNNING":
        return "success";
      case "FAILED":
        return "error";
      case "PENDING":
      case "DEPLOYING":
        return "pending";
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item md>
        <Typography variant="overline">Name</Typography>
        <Typography>{service.name}</Typography>
      </Grid>
      <Grid item md>
        <Typography variant="overline">Status</Typography>
        <Typography sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <UiStatusDot variant={resolveStatusDotVariant(status)} />
          {status}
        </Typography>
      </Grid>
      <Grid item md>
        <Box>
          <Typography variant="overline">Deployed by</Typography>
          <Typography>{lastDeployment?.executedBy.name ?? "N/A"}</Typography>
        </Box>
      </Grid>
      <Grid item md>
        <Typography variant="overline">Deployed</Typography>
        <Typography>
          {lastDeployment?.executedAt
            ? formatDistanceToNow(lastDeployment.executedAt)
            : "N/A"}
        </Typography>
      </Grid>
      <Grid item md>
        <Box>
          <Typography variant="overline">Version</Typography>
          <Typography>
            <code>{lastDeployment?.tag ?? "N/A"}</code>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

type Status = "RUNNING" | "PENDING" | "DEPLOYING" | "FAILED";

function resolveStatus(lastDeployment?: Deployment): Status {
  if (!lastDeployment) {
    return "PENDING";
  }
  switch (lastDeployment.outcome) {
    case DeploymentOutcomeEnum.Pending:
      return "DEPLOYING";
    case DeploymentOutcomeEnum.Failed:
      return "FAILED";
    case DeploymentOutcomeEnum.Successful:
      return "RUNNING";
  }
}
