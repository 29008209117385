import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";

import { DatabaseCredentials } from "src/generated-sources/openapi";

import { useDatabaseApi } from "./useDatabaseApi";

export function useGetDatabaseCredentialsQuery(
  databaseId: string,
  options?: UseQueryOptions<DatabaseCredentials>
): UseQueryResult<DatabaseCredentials> {
  const databaseApi = useDatabaseApi();

  return useQuery({
    queryKey: ["credentials", databaseId],
    queryFn: () => {
      return databaseApi.getDatabaseCredentials({
        databaseId,
      });
    },
    // We don't want to cache this query, because the credentials are sensitive
    staleTime: 0,
    cacheTime: 0,
    ...options,
  });
}
