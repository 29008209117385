/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationModel } from './PaginationModel';
import {
    PaginationModelFromJSON,
    PaginationModelFromJSONTyped,
    PaginationModelToJSON,
} from './PaginationModel';
import type { ServerStatus } from './ServerStatus';
import {
    ServerStatusFromJSON,
    ServerStatusFromJSONTyped,
    ServerStatusToJSON,
} from './ServerStatus';

/**
 * 
 * @export
 * @interface ServerStatusEnvelope
 */
export interface ServerStatusEnvelope {
    /**
     * 
     * @type {Array<ServerStatus>}
     * @memberof ServerStatusEnvelope
     */
    data: Array<ServerStatus>;
    /**
     * 
     * @type {PaginationModel}
     * @memberof ServerStatusEnvelope
     */
    pagination: PaginationModel;
}

/**
 * Check if a given object implements the ServerStatusEnvelope interface.
 */
export function instanceOfServerStatusEnvelope(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function ServerStatusEnvelopeFromJSON(json: any): ServerStatusEnvelope {
    return ServerStatusEnvelopeFromJSONTyped(json, false);
}

export function ServerStatusEnvelopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServerStatusEnvelope {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ServerStatusFromJSON)),
        'pagination': PaginationModelFromJSON(json['pagination']),
    };
}

export function ServerStatusEnvelopeToJSON(value?: ServerStatusEnvelope | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(ServerStatusToJSON)),
        'pagination': PaginationModelToJSON(value.pagination),
    };
}

