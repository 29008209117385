import { useSnackbar } from "notistack";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useServiceSecretApi } from "./useApi";

export interface DeleteSecretMutationRequest {
  serviceId: string;
  name: string;
}

export function useDeleteSecretMutation(): UseMutationResult<
  void,
  Error,
  DeleteSecretMutationRequest
> {
  const api = useServiceSecretApi();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  return useMutation({
    mutationFn: async (request: DeleteSecretMutationRequest) => {
      await api.deleteSecret({
        serviceId: request.serviceId,
        serviceSecretName: request.name,
      });
    },
    onSuccess: (_, variables) => {
      snackbar.enqueueSnackbar(`Secret "${variables.name}" deleted.`, {
        variant: "success",
      });
      queryClient.invalidateQueries([
        "service",
        variables.serviceId,
        "secrets",
      ]);
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });
}
