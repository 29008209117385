import { UiStatusDot } from "@hooloovoodoo/mui-react";
import { styled } from "@mui/material";
import React from "react";

import { DatabaseStatusEnum } from "../../../../generated-sources/openapi";

export interface DatabaseStatusProps {
  status: DatabaseStatusEnum;
}

const Status = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

export const DatabaseStatus: React.FC<DatabaseStatusProps> = ({ status }) => {
  switch (status) {
    case DatabaseStatusEnum.Creating:
      return (
        <Status>
          <UiStatusDot variant="pending" /> Creating
        </Status>
      );
    case DatabaseStatusEnum.Online:
    case DatabaseStatusEnum.Resizing:
    case DatabaseStatusEnum.Migrating:
    case DatabaseStatusEnum.Forking:
      return (
        <Status>
          <UiStatusDot variant="success" /> Online
        </Status>
      );
    case DatabaseStatusEnum.Deleted:
      return (
        <Status>
          <UiStatusDot variant="error" /> Deleted
        </Status>
      );
  }
};
