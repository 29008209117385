/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Layout } from './Layout';
import {
    LayoutFromJSON,
    LayoutFromJSONTyped,
    LayoutToJSON,
} from './Layout';

/**
 * 
 * @export
 * @interface DatabaseOption
 */
export interface DatabaseOption {
    /**
     * The name of the database engine
     * @type {string}
     * @memberof DatabaseOption
     */
    name?: string;
    /**
     * An array of strings containing the names of available regions
     * @type {Array<string>}
     * @memberof DatabaseOption
     */
    regions: Array<string>;
    /**
     * An array of strings containing the names of available versions
     * @type {Array<string>}
     * @memberof DatabaseOption
     */
    versions: Array<string>;
    /**
     * 
     * @type {Array<Layout>}
     * @memberof DatabaseOption
     */
    layouts: Array<Layout>;
}

/**
 * Check if a given object implements the DatabaseOption interface.
 */
export function instanceOfDatabaseOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "regions" in value;
    isInstance = isInstance && "versions" in value;
    isInstance = isInstance && "layouts" in value;

    return isInstance;
}

export function DatabaseOptionFromJSON(json: any): DatabaseOption {
    return DatabaseOptionFromJSONTyped(json, false);
}

export function DatabaseOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'regions': json['regions'],
        'versions': json['versions'],
        'layouts': ((json['layouts'] as Array<any>).map(LayoutFromJSON)),
    };
}

export function DatabaseOptionToJSON(value?: DatabaseOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'regions': value.regions,
        'versions': value.versions,
        'layouts': ((value.layouts as Array<any>).map(LayoutToJSON)),
    };
}

