type IncomingMessageType = "LOG_MESSAGE" | "AUTHORIZATION_SUCCEEDED" | "ERROR";
type OutgoingMessageType = "AUTHORIZATION" | "SUBSCRIBE";

export type IncomingMessage = { type: IncomingMessageType } & (
  | AuthorizeSuccessIncomingMessage
  | LogMessageIncomingMessage
  | ErrorIncomingMessage
);

export interface AuthorizeSuccessIncomingMessage {
  type: "AUTHORIZATION_SUCCEEDED";
}

export interface LogMessageIncomingMessage {
  type: "LOG_MESSAGE";
  message: string;
}

export interface ErrorIncomingMessage {
  type: "ERROR";
  code: number;
  message: string;
}

export interface OutgoingMessage {
  type: OutgoingMessageType;
}

export interface WebsocketApiOptions {
  url: string;
  token: string;
  onAuthenticated(): void;
  onError(message: string): void;
  onMessage(message: IncomingMessage): void;
}

export class WebsocketApi {
  private ws: WebSocket;

  constructor(private options: WebsocketApiOptions) {
    this.ws = new WebSocket(options.url);
    this.ws.onopen = () => {
      this.send({
        type: "AUTHORIZATION",
        token: options.token,
      });
    };
    this.ws.onerror = (event) => {
      options.onError(event.type);
    };
    this.ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (isIncomingMessage(data)) {
          this.handleIncomingMessage(data);
        }
      } catch (e) {
        const msg = e instanceof Error ? e.message : "Unknown error";
        options.onError(`Failed to parse message: ${msg}`);
      }
    };
  }

  private handleIncomingMessage(message: IncomingMessage) {
    switch (message.type) {
      case "AUTHORIZATION_SUCCEEDED":
        this.options.onAuthenticated();
        break;
      case "ERROR":
        this.options.onError(message.message);
        break;
      default:
        this.options.onMessage(message);
        break;
    }
  }

  send<T extends OutgoingMessage>(message: T): void {
    const msg = JSON.stringify(message);
    this.ws.send(msg);
  }

  close() {
    if (this.ws.readyState <= this.ws.OPEN) {
      this.ws.onopen = null;
      this.ws.onerror = null;
      this.ws.onmessage = null;
      this.ws.close();
    }
  }
}

function isIncomingMessage(data: any): data is IncomingMessage {
  return data.hasOwnProperty("type");
}
