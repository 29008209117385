import {
  TokenLoader,
  useAuthenticationToken,
} from "@hooloovoodoo/mui-react-auth";
import React from "react";
import { Navigate, Outlet } from "react-router";

import { useCurrentUserQuery } from "../../authentication/hooks";

export const AnonymousRoute: React.FC = () => {
  const { isLoading: isLoadingToken } = useAuthenticationToken();
  const { data: user, isLoading: isLoadingUser } = useCurrentUserQuery();
  const isLoggedIn = !!user;

  if (isLoadingToken || isLoadingUser) {
    return <TokenLoader />;
  }

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
