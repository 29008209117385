/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Pageable,
  Problem,
  ServiceVariable,
  ServiceVariableEnvelope,
  SetServiceVariableRequest,
} from '../models';
import {
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
    ServiceVariableFromJSON,
    ServiceVariableToJSON,
    ServiceVariableEnvelopeFromJSON,
    ServiceVariableEnvelopeToJSON,
    SetServiceVariableRequestFromJSON,
    SetServiceVariableRequestToJSON,
} from '../models';

export interface DeleteVariableRequest {
    serviceId: string;
    serviceVariableName: string;
}

export interface GetVariableRequest {
    serviceId: string;
    serviceVariableName: string;
}

export interface ListVariablesRequest {
    serviceId: string;
    pageable?: Pageable;
}

export interface SetVariableRequest {
    serviceId: string;
    serviceVariableName: string;
    setServiceVariableRequest: SetServiceVariableRequest;
}

/**
 * 
 */
export class ServiceVariableApi extends runtime.BaseAPI {

    /**
     * Deletes the variable with the given name associated with the given service.
     * Delete variable
     */
    async deleteVariableRaw(requestParameters: DeleteVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling deleteVariable.');
        }

        if (requestParameters.serviceVariableName === null || requestParameters.serviceVariableName === undefined) {
            throw new runtime.RequiredError('serviceVariableName','Required parameter requestParameters.serviceVariableName was null or undefined when calling deleteVariable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/variable/{serviceVariableName}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))).replace(`{${"serviceVariableName"}}`, encodeURIComponent(String(requestParameters.serviceVariableName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the variable with the given name associated with the given service.
     * Delete variable
     */
    async deleteVariable(requestParameters: DeleteVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteVariableRaw(requestParameters, initOverrides);
    }

    /**
     * Returns the variable with the given name associated with the given service.
     * Get variable
     */
    async getVariableRaw(requestParameters: GetVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceVariable>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling getVariable.');
        }

        if (requestParameters.serviceVariableName === null || requestParameters.serviceVariableName === undefined) {
            throw new runtime.RequiredError('serviceVariableName','Required parameter requestParameters.serviceVariableName was null or undefined when calling getVariable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/variable/{serviceVariableName}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))).replace(`{${"serviceVariableName"}}`, encodeURIComponent(String(requestParameters.serviceVariableName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceVariableFromJSON(jsonValue));
    }

    /**
     * Returns the variable with the given name associated with the given service.
     * Get variable
     */
    async getVariable(requestParameters: GetVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceVariable> {
        const response = await this.getVariableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of variables associated with the given service.
     * List variables
     */
    async listVariablesRaw(requestParameters: ListVariablesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceVariableEnvelope>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling listVariables.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/variable`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceVariableEnvelopeFromJSON(jsonValue));
    }

    /**
     * Returns a list of variables associated with the given service.
     * List variables
     */
    async listVariables(requestParameters: ListVariablesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceVariableEnvelope> {
        const response = await this.listVariablesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates or updates variable for the given service.
     * Set variable
     */
    async setVariableRaw(requestParameters: SetVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceVariable>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling setVariable.');
        }

        if (requestParameters.serviceVariableName === null || requestParameters.serviceVariableName === undefined) {
            throw new runtime.RequiredError('serviceVariableName','Required parameter requestParameters.serviceVariableName was null or undefined when calling setVariable.');
        }

        if (requestParameters.setServiceVariableRequest === null || requestParameters.setServiceVariableRequest === undefined) {
            throw new runtime.RequiredError('setServiceVariableRequest','Required parameter requestParameters.setServiceVariableRequest was null or undefined when calling setVariable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/{serviceId}/variable/{serviceVariableName}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))).replace(`{${"serviceVariableName"}}`, encodeURIComponent(String(requestParameters.serviceVariableName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetServiceVariableRequestToJSON(requestParameters.setServiceVariableRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceVariableFromJSON(jsonValue));
    }

    /**
     * Creates or updates variable for the given service.
     * Set variable
     */
    async setVariable(requestParameters: SetVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceVariable> {
        const response = await this.setVariableRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
