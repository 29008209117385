import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { Server } from "src/generated-sources/openapi";
import { useCurrentOrganization } from "src/modules/organization/hooks";

import { useServerApi } from "./useServerApi";

export function useCreateServerMutation(): UseMutationResult<
  Server,
  Error,
  void,
  void
> {
  const queryClient = useQueryClient();
  const organization = useCurrentOrganization();
  const serverApi = useServerApi();
  return useMutation(
    ["create-server"],
    () => {
      return serverApi.createServer({
        organizationId: organization.id,
      });
    },
    {
      onSuccess: function () {
        queryClient.invalidateQueries(["servers"]);
      },
    }
  );
}
