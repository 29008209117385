import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";

import { ServiceVersionEnvelope } from "src/generated-sources/openapi";

import { useServiceApi } from "./useApi";

export function useListServiceVersionsQuery(
  serviceId: string,
  options?: UseQueryOptions<ServiceVersionEnvelope>
): UseQueryResult<ServiceVersionEnvelope> {
  const serviceApi = useServiceApi();
  return useQuery({
    queryKey: ["service", serviceId, "versions"],
    queryFn: () =>
      serviceApi.listServiceVersions({
        serviceId,
        pageable: {
          sort: ["executedAt,desc"],
        },
      }),
    ...options,
  });
}
