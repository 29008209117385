import {
  ListItemText,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";

import { Layout } from "src/generated-sources/openapi";

export interface LayoutSelectorProps
  extends Omit<TextFieldProps, "onChange" | "value" | "defaultValue"> {
  defaultValue?: Layout;
  value?: Layout;
  onChange: (option: Layout) => void;
  options: Layout[];
}

const SupportedLayouts: Record<number, { primary: string; secondary: string }> =
  {
    1: {
      primary: "Single node",
      secondary: "Easy starter for development clusters",
    },
    2: {
      primary: "High availability (2 nodes)",
      secondary: "Suggested for production clusters",
    },
    3: {
      primary: "High availability (3 nodes)",
      secondary: "Suggested for battle-hardened production clusters",
    },
  };

export const ClusterSizeSelector: React.FC<LayoutSelectorProps> =
  React.forwardRef(
    ({ options, value, onChange, defaultValue, ...props }, ref) => {
      const handleChange = useMemoizedFn(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          const selectedLayout = options.find(
            (it) => it.numNodes === parseInt(event.target.value, 10)
          );
          if (selectedLayout) {
            onChange?.(selectedLayout);
          }
        }
      );
      return (
        <TextField
          select
          {...props}
          defaultValue={String(value?.numNodes)}
          value={String(value?.numNodes)}
          onChange={handleChange}
          ref={ref}
        >
          {options
            .filter((it) => SupportedLayouts.hasOwnProperty(it.numNodes))
            .map((it) => (
              <MenuItem value={it.numNodes}>
                <ListItemText
                  primary={SupportedLayouts[it.numNodes].primary}
                  secondary={SupportedLayouts[it.numNodes].secondary}
                />
              </MenuItem>
            ))}
          {/*<MenuItem value="1">*/}
          {/*  <ListItemText primary="Single node"*/}
          {/*                secondary="Easy starter for development clusters" />*/}
          {/*</MenuItem>*/}
          {/*<MenuItem value="2">*/}
          {/*  <ListItemText primary="High availability (2 nodes)" secondary="Suggested for production clusters" />*/}
          {/*</MenuItem>*/}
          {/*<MenuItem value="3">*/}
          {/*  <ListItemText primary="High availability (3 nodes)"*/}
          {/*                secondary="Suggested for battle-hardened production clusters" />*/}
          {/*</MenuItem>*/}
        </TextField>
      );
    }
  );
