import { PageContainer } from "@hooloovoodoo/mui-react";
import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

import { DatabaseEmptyState, DatabaseHeader } from "../components";
import { DatabaseList } from "../components/DatabaseList";
import { useListOrganisationDatabaseQuery } from "../hooks";

export const DatabasesPage: React.FC = () => {
  const { data: envelope, isLoading } = useListOrganisationDatabaseQuery();

  return (
    <PageContainer>
      <DatabaseHeader />
      <Box>
        {isLoading && <LoadingPlaceholder />}
        {envelope && envelope.data.length === 0 && <DatabaseEmptyState />}
        {envelope && envelope.data.length > 0 && (
          <DatabaseList databases={envelope.data} />
        )}
      </Box>
    </PageContainer>
  );
};

const LoadingPlaceholder = () => (
  <Stack spacing={4} sx={{ p: 4 }}>
    <Skeleton variant="rounded" height={80} />
    <Skeleton variant="rounded" height={80} />
    <Skeleton variant="rounded" height={80} />
  </Stack>
);
