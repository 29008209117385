import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import { UiHeroIcon, UiIconButton } from "@hooloovoodoo/mui-react";
import {
  Box,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

import { ServiceVariable } from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";

type Item = {
  name: string;
  variable?: string;
  updatedAt: Date;
} & object;

export interface VariableItemProps<ItemType extends Item> {
  item: ItemType;
  onEdit?: (variable: ItemType) => void;
  onDelete?: (variable: ItemType) => void;
}

function hasValue(item: Item): item is ServiceVariable {
  return item.hasOwnProperty("value");
}

function getItemValue(item: Item): string {
  return hasValue(item) ? item.value : "HIDDEN";
}

function abbreviateValue(value: string): string {
  const THRESHOLD = 20;
  if (value.length > THRESHOLD) {
    return value.substring(0, THRESHOLD) + "...";
  }
  return value;
}

export function VariableItem<ItemType extends Item>({
  item,
  onEdit,
  onDelete,
}: VariableItemProps<ItemType>) {
  const { formatDistanceToNow } = useDateFns();
  return (
    <TableRow>
      <TableCell>
        <Stack>
          <code>{item.name}</code>
          <Typography variant="caption" color="text.secondary">
            Updated {formatDistanceToNow(item.updatedAt, { addSuffix: true })}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Tooltip
          title={hasValue(item) ? getItemValue(item) : "Secrets cannot be read"}
        >
          <code>
            <pre>{abbreviateValue(getItemValue(item))}</pre>
          </code>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        <Box display="flex" gap={2} justifyContent="flex-end">
          {onEdit && (
            <UiIconButton
              variant="outlined"
              color="gray"
              onClick={() => onEdit(item)}
            >
              <UiHeroIcon icon={PencilIcon} />
            </UiIconButton>
          )}
          {onDelete && (
            <UiIconButton
              variant="outlined"
              color="red"
              onClick={() => onDelete(item)}
            >
              <UiHeroIcon icon={TrashIcon} />
            </UiIconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}
