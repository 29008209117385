/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Server
 */
export interface Server {
    /**
     * The unique identifier for the server
     * @type {string}
     * @memberof Server
     */
    id: string;
    /**
     * The hostname of the server
     * @type {string}
     * @memberof Server
     */
    hostname: string;
    /**
     * The publicly accessible URL of the server
     * @type {string}
     * @memberof Server
     */
    publicUrl: string;
    /**
     * The status of the server
     * @type {string}
     * @memberof Server
     */
    status: ServerStatusEnum;
    /**
     * 
     * @type {Organization}
     * @memberof Server
     */
    organization: Organization;
    /**
     * The date and time the server was created
     * @type {Date}
     * @memberof Server
     */
    createdAt: Date;
    /**
     * 
     * @type {User}
     * @memberof Server
     */
    createdBy: User;
}


/**
 * @export
 */
export const ServerStatusEnum = {
    ProvisioningScheduled: 'PROVISIONING_SCHEDULED',
    ProvisioningError: 'PROVISIONING_ERROR',
    Provisioning: 'PROVISIONING',
    Provisioned: 'PROVISIONED',
    DestroyScheduled: 'DESTROY_SCHEDULED',
    Destroying: 'DESTROYING',
    Destroyed: 'DESTROYED'
} as const;
export type ServerStatusEnum = typeof ServerStatusEnum[keyof typeof ServerStatusEnum];


/**
 * Check if a given object implements the Server interface.
 */
export function instanceOfServer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "hostname" in value;
    isInstance = isInstance && "publicUrl" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "createdBy" in value;

    return isInstance;
}

export function ServerFromJSON(json: any): Server {
    return ServerFromJSONTyped(json, false);
}

export function ServerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Server {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'hostname': json['hostname'],
        'publicUrl': json['public_url'],
        'status': json['status'],
        'organization': OrganizationFromJSON(json['organization']),
        'createdAt': (new Date(json['created_at'])),
        'createdBy': UserFromJSON(json['created_by']),
    };
}

export function ServerToJSON(value?: Server | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hostname': value.hostname,
        'public_url': value.publicUrl,
        'status': value.status,
        'organization': OrganizationToJSON(value.organization),
        'created_at': (value.createdAt.toISOString()),
        'created_by': UserToJSON(value.createdBy),
    };
}

