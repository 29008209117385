import { PageContainer, SimpleLoader } from "@hooloovoodoo/mui-react";
import React from "react";

import { RadBreadcrumbs } from "src/modules/common/components";

import { ServerList, ServersEmptyState, ServersHeader } from "../components";
import { useListServersQuery } from "../hooks";

export const ServersPage: React.FC = () => {
  const { data: servers, isLoading } = useListServersQuery();

  return (
    <PageContainer>
      <RadBreadcrumbs />
      <ServersHeader />
      {isLoading && <SimpleLoader text="Loading servers..." />}
      {servers && servers.data.length === 0 && <ServersEmptyState />}
      {servers && servers.data.length > 0 && (
        <ServerList servers={servers.data} />
      )}
    </PageContainer>
  );
};
