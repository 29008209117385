/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Service } from './Service';
import {
    ServiceFromJSON,
    ServiceFromJSONTyped,
    ServiceToJSON,
} from './Service';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Deployment
 */
export interface Deployment {
    /**
     * The unique identifier for the deployment
     * @type {string}
     * @memberof Deployment
     */
    id: string;
    /**
     * Represent whether an action is executed as a deployment or a rollback
     * @type {string}
     * @memberof Deployment
     */
    action: DeploymentActionEnum;
    /**
     * The current state or status of the deployment or rollback process
     * @type {string}
     * @memberof Deployment
     */
    status: DeploymentStatusEnum;
    /**
     * The outcome of the deployment or rollback process
     * @type {string}
     * @memberof Deployment
     */
    outcome: DeploymentOutcomeEnum;
    /**
     * Identifier assigned to a specific version or release of the application
     * @type {string}
     * @memberof Deployment
     */
    tag: string;
    /**
     * 
     * @type {Service}
     * @memberof Deployment
     */
    service: Service;
    /**
     * The date and time the deployment or rollback was executed
     * @type {Date}
     * @memberof Deployment
     */
    executedAt: Date;
    /**
     * 
     * @type {User}
     * @memberof Deployment
     */
    executedBy: User;
    /**
     * The date and time the deployment or rollback was completed
     * @type {Date}
     * @memberof Deployment
     */
    completedAt: Date;
    /**
     * The date and time until the deployment or rollback was active
     * @type {Date}
     * @memberof Deployment
     */
    activeUntil: Date;
}


/**
 * @export
 */
export const DeploymentActionEnum = {
    Deployment: 'DEPLOYMENT',
    Rollback: 'ROLLBACK'
} as const;
export type DeploymentActionEnum = typeof DeploymentActionEnum[keyof typeof DeploymentActionEnum];

/**
 * @export
 */
export const DeploymentStatusEnum = {
    Created: 'CREATED',
    Deploying: 'DEPLOYING',
    Completed: 'COMPLETED'
} as const;
export type DeploymentStatusEnum = typeof DeploymentStatusEnum[keyof typeof DeploymentStatusEnum];

/**
 * @export
 */
export const DeploymentOutcomeEnum = {
    Pending: 'PENDING',
    Successful: 'SUCCESSFUL',
    Failed: 'FAILED'
} as const;
export type DeploymentOutcomeEnum = typeof DeploymentOutcomeEnum[keyof typeof DeploymentOutcomeEnum];


/**
 * Check if a given object implements the Deployment interface.
 */
export function instanceOfDeployment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "outcome" in value;
    isInstance = isInstance && "tag" in value;
    isInstance = isInstance && "service" in value;
    isInstance = isInstance && "executedAt" in value;
    isInstance = isInstance && "executedBy" in value;
    isInstance = isInstance && "completedAt" in value;
    isInstance = isInstance && "activeUntil" in value;

    return isInstance;
}

export function DeploymentFromJSON(json: any): Deployment {
    return DeploymentFromJSONTyped(json, false);
}

export function DeploymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Deployment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'action': json['action'],
        'status': json['status'],
        'outcome': json['outcome'],
        'tag': json['tag'],
        'service': ServiceFromJSON(json['service']),
        'executedAt': (new Date(json['executed_at'])),
        'executedBy': UserFromJSON(json['executed_by']),
        'completedAt': (new Date(json['completed_at'])),
        'activeUntil': (new Date(json['active_until'])),
    };
}

export function DeploymentToJSON(value?: Deployment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'action': value.action,
        'status': value.status,
        'outcome': value.outcome,
        'tag': value.tag,
        'service': ServiceToJSON(value.service),
        'executed_at': (value.executedAt.toISOString()),
        'executed_by': UserToJSON(value.executedBy),
        'completed_at': (value.completedAt.toISOString()),
        'active_until': (value.activeUntil.toISOString()),
    };
}

