import { Action, Service } from "src/generated-sources/openapi";

const DEPLOY_ACTION = "deploy";
const INSUFFICIENT_PERMISSIONS = "INSUFFICIENT_PERMISSIONS";
const INVALID_STATE = "INVALID_STATE";

export function canDeployService(service: Service): boolean {
  return (
    filterDeployServiceActions(service).filter((it) => it.allow).length > 0
  );
}

export function isDeployServiceHidden(service: Service): boolean {
  return filterDeployServiceActions(service).every(
    (it) => it.denyReason === INSUFFICIENT_PERMISSIONS
  );
}

export function isDeployServiceDisabled(service: Service): boolean {
  return filterDeployServiceActions(service).every(
    (it) => it.denyReason === INVALID_STATE
  );
}

export function filterDeployServiceActions(service: Service): Action[] {
  return service.meta.actions?.filter((it) => it.name === DEPLOY_ACTION) ?? [];
}
