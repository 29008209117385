import CircleStackIcon from "@heroicons/react/24/outline/CircleStackIcon";
import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import { PageHeaderDetailsItem } from "@hooloovoodoo/mui-react";
import { List } from "@mui/material";
import React from "react";

import { Database } from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";

import { DatabaseEngineName } from "./DatabaseEngineName";

export interface DatabaseHeaderDetailsProps {
  database: Database;
}

export const DatabaseHeaderDetails: React.FC<DatabaseHeaderDetailsProps> = ({
  database,
}) => {
  const { formatDistanceToNow } = useDateFns();
  return (
    <List
      sx={(theme) => ({
        display: "flex",
        gap: theme.spacing(4),
        justifyContent: "flex-start",
      })}
    >
      <PageHeaderDetailsItem
        text={<DatabaseEngineName engine={database.engine} />}
        icon={CircleStackIcon}
      />
      <PageHeaderDetailsItem
        text={`
          Created ${formatDistanceToNow(database.createdAt, {
            addSuffix: true,
          })}`}
        icon={ClockIcon}
      />
    </List>
  );
};
