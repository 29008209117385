import { useSnackbar } from "notistack";
import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "react-query";

import { Database } from "src/generated-sources/openapi";

import { useDatabaseApi } from "./useDatabaseApi";

export function useDeleteDatabaseMutation(
  options: UseMutationOptions<void, Error, Database> = {}
): UseMutationResult<void, Error, Database> {
  const databaseApi = useDatabaseApi();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const { onSuccess, onError, ...other } = options;

  return useMutation({
    mutationFn: async (database: Database) => {
      await databaseApi.destroyDatabase({ databaseId: database.id });
    },
    onSuccess: (_, database: Database, ctx) => {
      snackbar.enqueueSnackbar(
        `Database ${database.name} destruction requested.`,
        {
          variant: "success",
        }
      );
      Promise.all([
        queryClient.invalidateQueries(["databases"]),
        queryClient.invalidateQueries(["database", database.id]),
      ]).then(() => onSuccess?.(_, database, ctx));
    },
    onError: (_, database: Database, ctx) => {
      snackbar.enqueueSnackbar(`Failed to delete database ${database.name}.`, {
        variant: "error",
      });
      onError?.(_, database, ctx);
    },
    ...other,
  });
}
