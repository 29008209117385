/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DatabaseOption } from './DatabaseOption';
import {
    DatabaseOptionFromJSON,
    DatabaseOptionFromJSONTyped,
    DatabaseOptionToJSON,
} from './DatabaseOption';

/**
 * 
 * @export
 * @interface DatabaseOptionEnvelope
 */
export interface DatabaseOptionEnvelope {
    /**
     * 
     * @type {Array<DatabaseOption>}
     * @memberof DatabaseOptionEnvelope
     */
    data: Array<DatabaseOption>;
}

/**
 * Check if a given object implements the DatabaseOptionEnvelope interface.
 */
export function instanceOfDatabaseOptionEnvelope(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function DatabaseOptionEnvelopeFromJSON(json: any): DatabaseOptionEnvelope {
    return DatabaseOptionEnvelopeFromJSONTyped(json, false);
}

export function DatabaseOptionEnvelopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseOptionEnvelope {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(DatabaseOptionFromJSON)),
    };
}

export function DatabaseOptionEnvelopeToJSON(value?: DatabaseOptionEnvelope | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(DatabaseOptionToJSON)),
    };
}

