import { UiBadge, UiButton } from "@hooloovoodoo/mui-react";
import { Box, Typography, styled } from "@mui/material";
import {
  bindDialog,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React from "react";

import { Repository } from "src/generated-sources/openapi";

import { useListRepositoriesQuery } from "../hooks";
import { ConnectRepositoryDialog } from "./ConnectRepositoryDialog";

export interface RepositoryListWidgetProps {}

const RepositoryListItem = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  padding: theme.spacing(2, 1),
}));

export const RepositoryListWidget: React.FC<RepositoryListWidgetProps> = (
  props
) => {
  const { data: repositories } = useListRepositoriesQuery();
  const popupState = usePopupState({ variant: "dialog" });

  return (
    <Box>
      <Typography variant="h1">Repositories</Typography>
      <ConnectRepositoryDialog {...bindDialog(popupState)} />
      {typeof repositories !== "undefined" && repositories.data.length === 0 ? (
        <>
          <Typography>No repositories</Typography>
          <UiButton {...bindTrigger(popupState)} color="primary">
            Connect repository
          </UiButton>
        </>
      ) : null}
      {repositories?.data.map((repository: Repository) => (
        <RepositoryListItem key={repository.id}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography sx={{ fontWeight: "fontWeightSemiBold" }}>
              {repository.name}

              <UiBadge component={"span"}>online</UiBadge>
            </Typography>
            <Box>
              <Typography color="gray300">Connected by: N/A</Typography>
            </Box>
          </Box>
        </RepositoryListItem>
      ))}
    </Box>
  );
};
