import {
  faBitbucket,
  faGithub,
  faGitlab,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmptyStateList } from "@hooloovoodoo/mui-react";
import { Link, Typography } from "@mui/material";
import React from "react";

import { Configuration } from "src/config";

export const ConnectRepositoriesList: React.FC = () => (
  <EmptyStateList.Root>
    <EmptyStateList.Heading
      title="Connect your repository"
      subtitle="Choose the suitable repository provider"
    />
    <EmptyStateList.List role="list">
      <EmptyStateList.ListItem
        primary="GitHub repository"
        secondary="Install the Rad12 GitHub app to connect your repository."
        icon={<FontAwesomeIcon size="lg" icon={faGithub} color="#1a1e22" />}
        href={Configuration.github.installUrl}
      />
      <EmptyStateList.ListItem
        disabled
        primary="GitLab repository"
        secondary="Connect your GitLab repository to start deploying your services."
        icon={<FontAwesomeIcon size="lg" icon={faGitlab} color="#da4127" />}
      />
      <EmptyStateList.ListItem
        disabled
        primary="BitBucket repository"
        secondary="Connect your BitBucket repository to start deploying your services."
        icon={<FontAwesomeIcon size="lg" icon={faBitbucket} color="#2580f7" />}
      />
    </EmptyStateList.List>
    <EmptyStateList.Footer>
      <Typography>
        Having trouble connecting your repository?{" "}
        <Link href={Configuration.supportEmail}>Let us know!</Link>
      </Typography>
    </EmptyStateList.Footer>
  </EmptyStateList.Root>
);
