/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';

/**
 * 
 * @export
 * @interface OrganizationMember
 */
export interface OrganizationMember {
    /**
     * The unique identifier for the user membership
     * @type {string}
     * @memberof OrganizationMember
     */
    id: string;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationMember
     */
    organization: Organization;
    /**
     * The role of the user in the organization
     * @type {string}
     * @memberof OrganizationMember
     */
    role: OrganizationMemberRoleEnum;
}


/**
 * @export
 */
export const OrganizationMemberRoleEnum = {
    Administrator: 'ADMINISTRATOR',
    Moderator: 'MODERATOR',
    Member: 'MEMBER'
} as const;
export type OrganizationMemberRoleEnum = typeof OrganizationMemberRoleEnum[keyof typeof OrganizationMemberRoleEnum];


/**
 * Check if a given object implements the OrganizationMember interface.
 */
export function instanceOfOrganizationMember(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function OrganizationMemberFromJSON(json: any): OrganizationMember {
    return OrganizationMemberFromJSONTyped(json, false);
}

export function OrganizationMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organization': OrganizationFromJSON(json['organization']),
        'role': json['role'],
    };
}

export function OrganizationMemberToJSON(value?: OrganizationMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organization': OrganizationToJSON(value.organization),
        'role': value.role,
    };
}

