import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useLocalStorage } from "usehooks-ts";

import { Organization } from "src/generated-sources/openapi";
import { useCurrentUserQuery } from "src/modules/authentication/hooks";

export function useSetCurrentOrganizationId() {
  const [, setOrganizationId] = useLocalStorage<string | undefined>(
    "organization_id",
    undefined
  );
  return useMemoizedFn((organizationId: string) => {
    setOrganizationId(() => organizationId);
  });
}

export function useCurrentOrganizationQuery(): Organization | undefined {
  const { data: currentUser } = useCurrentUserQuery();
  const [organizationId] = useLocalStorage("organization_id", undefined);

  const organizations = currentUser?.organizations;
  if (!organizations) {
    return undefined;
  }
  const defaultToFirst = organizations[0]?.organization;
  const selected = organizations
    .map((it) => it.organization)
    .find((it) => it.id === organizationId);
  return selected ?? defaultToFirst;
}

export function useCurrentOrganization(): Organization {
  const organization = useCurrentOrganizationQuery();
  if (!organization) {
    throw new Error(
      "useCurrentOrganization can only be used when organization exists"
    );
  }
  return organization;
}
