import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import { Feed, UiHeroIcon } from "@hooloovoodoo/mui-react";
import { CircularProgress, Container, Typography } from "@mui/material";
import React from "react";

import { Server, ServerStatusEnvelope } from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";

export interface ServerProvisionInProgressProps {
  server: Server;
  statuses: ServerStatusEnvelope;
}

export const ServerProvisionInProgress: React.FC<
  ServerProvisionInProgressProps
> = ({ server, statuses }) => {
  const { formatDistanceToNow } = useDateFns();
  return (
    <Container maxWidth="sm">
      <Typography
        variant="h4"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          marginBottom: 4,
        }}
      >
        <CircularProgress />
        <span>Please wait while your server is being provisioned</span>
      </Typography>

      <Feed.List dense>
        {statuses.data.map((log, index) => (
          <Feed.ListItem key={index}>
            <Feed.Icon backgroundColor="green500">
              <UiHeroIcon icon={CheckIcon} />
            </Feed.Icon>
            <Feed.Content>{log.status}</Feed.Content>
            <Feed.DateTime>
              {formatDistanceToNow(log.reportedAt, {
                addSuffix: true,
              })}
            </Feed.DateTime>
          </Feed.ListItem>
        ))}
      </Feed.List>
    </Container>
  );
};
