import { Box, Stack } from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";
import { Navigate } from "react-router";

import {
  ServerStatusEnum,
  ServiceStatusEnum,
} from "src/generated-sources/openapi";
import { ServerProvisionInProgress } from "src/modules/server/components";
import { useListServerStatusesQuery } from "src/modules/server/hooks";

import {
  useGetServiceDeploymentMetricsQuery,
  useGetServiceLatestDeploymentQuery,
  useGetServiceQuery,
  useRouteServiceId,
} from "../../hooks";
import { MetricsOverview } from "../Metrics";
import { ServiceDeletionInProgress } from "../ServiceDeletionInProgress";
import { ServerDetails } from "./ServerDetails";
import { ServiceDetails } from "./ServiceDetails";
import { ServiceStats } from "./ServiceStats";

export const Overview: React.FC = () => {
  const serviceId = useRouteServiceId();
  const service = useGetServiceQuery(serviceId);
  const isProvisioning =
    service.data?.server.status !== ServerStatusEnum.Provisioned;
  const isDeleting = service.data?.status === ServiceStatusEnum.Deleting;

  return (
    <>
      {isProvisioning || isDeleting ? (
        <InProgress serviceId={serviceId} status={service.data?.status} />
      ) : (
        <OverviewContent serviceId={serviceId} />
      )}
    </>
  );
};

const OverviewContent: React.FC<{ serviceId: string }> = ({ serviceId }) => {
  const { data: service } = useGetServiceQuery(serviceId);
  const { data: deployment } = useGetServiceLatestDeploymentQuery(serviceId);
  const { data: metrics } = useGetServiceDeploymentMetricsQuery(serviceId);

  if (!service || !deployment || !metrics) {
    return null;
  }

  return (
    <Box sx={{ p: 4 }}>
      <Stack spacing={4}>
        <ServiceStats metrics={metrics} />
        <ServiceDetails service={service} lastDeployment={deployment} />
        <ServerDetails
          publicUrl={service.server.publicUrl}
          vCPU={1}
          memory={2}
          disk={50}
          createdBy={service.server.createdBy}
        />
        <MetricsOverview />
      </Stack>
    </Box>
  );
};

const InProgress: React.FC<{ serviceId: string; status?: string }> = ({
  serviceId,
  status,
}) => {
  const service = useGetServiceQuery(serviceId, {
    refetchInterval: status !== ServiceStatusEnum.Deleting ? 3000 : undefined,
  });
  const logs = useListServerStatusesQuery(service.data?.server.id!!, {
    poll: service.data?.server.status !== ServerStatusEnum.Provisioned,
  });
  const isDeleted = service.data?.status === ServiceStatusEnum.Deleted;
  const qc = useQueryClient();

  if (isDeleted) {
    qc.invalidateQueries(["services"]);
    return <Navigate to={"/services/deletion-success"} />;
  }

  if (service.data?.status === ServiceStatusEnum.Deleting) {
    return <ServiceDeletionInProgress />;
  }

  return (
    <>
      {service.data && logs.data && (
        <ServerProvisionInProgress
          server={service.data.server}
          statuses={logs.data}
        />
      )}
    </>
  );
};
