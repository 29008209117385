import { List } from "@mui/material";
import React from "react";

import { Service } from "src/generated-sources/openapi";
import { EntityListItem } from "src/modules/common/components";

interface ServiceListProps {
  services: Service[];
}
export const ServiceList: React.FC<ServiceListProps> = ({ services }) => {
  return (
    <List>
      {services.map((service) => {
        return (
          <EntityListItem
            key={service.id}
            to={`/services/${service.id}`}
            name={service.name}
            additionalInfo={`Repository name: ${service.repository.name}`}
          />
        );
      })}
    </List>
  );
};
