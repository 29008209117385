import { useTheme } from "@mui/material";
import useCreation from "ahooks/es/useCreation";
import React from "react";

import { ServiceMetrics } from "src/generated-sources/openapi";

import {
  useGetServiceApplicationMetricsQuery,
  useRouteServiceId,
} from "../../hooks";
import { MetricsLineChart } from "./MetricsLineChart";

function getCpuMetrics(data?: ServiceMetrics) {
  if (!data?.cpu) {
    return [];
  }
  return [
    {
      id: "CPU",
      values: data.cpu.values,
    },
  ];
}

function getMemoryMetrics(data?: ServiceMetrics) {
  if (!data?.memoryUsed || !data?.memoryTotal) {
    return [];
  }
  return [
    {
      id: "Memory Used",
      values: data.memoryUsed.values,
    },
    {
      id: "Memory Total",
      values: data.memoryTotal.values,
    },
  ];
}

function getDiskMetrics(data?: ServiceMetrics) {
  if (!data?.diskReadBytes || !data?.diskWrittenBytes) {
    return [];
  }
  return [
    {
      id: "Disk Read",
      values: data.diskReadBytes.values,
    },
    {
      id: "Disk Write",
      values: data.diskWrittenBytes.values,
    },
  ];
}

export const MetricsOverview: React.FC = () => {
  const serviceId = useRouteServiceId();
  const metrics = useGetServiceApplicationMetricsQuery(serviceId);

  const cpuMetrics = useCreation(
    () => getCpuMetrics(metrics.data),
    [metrics.data?.cpu]
  );
  const memoryMetrics = useCreation(() => {
    return getMemoryMetrics(metrics.data);
  }, [metrics.data?.memoryUsed, metrics.data?.memoryTotal]);
  const diskMetrics = useCreation(
    () => getDiskMetrics(metrics.data),
    [metrics.data?.diskReadBytes, metrics.data?.diskWrittenBytes]
  );
  const theme = useTheme();

  if (!metrics.data) {
    return null;
  }

  return (
    <>
      {cpuMetrics.length > 0 && (
        <MetricsLineChart
          yLabel="CPU Usage [%]"
          yFormat={formatCpuPercent}
          min={0}
          max={100}
          data={cpuMetrics}
        />
      )}
      {memoryMetrics.length > 0 && (
        <MetricsLineChart
          yLabel="RAM [GB]"
          yFormat={formatAsGigabytes}
          data={memoryMetrics}
          colors={[theme.palette.green500, theme.palette.yellow500]}
        />
      )}
      {diskMetrics.length > 0 && (
        <MetricsLineChart
          yLabel="Disk [MB]"
          yFormat={formatAsMegabytes}
          data={diskMetrics}
          colors={[theme.palette.amber500, theme.palette.red500]}
        />
      )}
    </>
  );
};

const numberFormat = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 2,
});

function formatAsGigabytes(value: number): string {
  return `${numberFormat.format(value / 1024 / 1024 / 1024)} GB`;
}

function formatCpuPercent(value: number): string {
  return `${numberFormat.format(value)}%`;
}

function formatAsMegabytes(value: number): string {
  return `${numberFormat.format(value / 1024 / 1024)} MB`;
}
