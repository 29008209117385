export interface ApplicationProperties {
  github: {
    installUrl: string;
  };
  supportEmail: string;
}

const supportEmail = "mailto:support@rad12.io";

type Domains = "https://app.rad12.io" | string;

const config: Record<Domains, ApplicationProperties> = {
  "http://localhost:3000": {
    github: {
      installUrl:
        "https://github.com/apps/rad12-test-localhost/installations/new",
    },
    supportEmail,
  },
  "https://app.rad12.io": {
    github: {
      installUrl:
        "https://github.com/apps/rad12-1-click-deploy/installations/new",
    },
    supportEmail,
  },
};

export const Configuration: ApplicationProperties =
  config[document.location.origin] ?? config["https://app.rad12.io"];
