import React from "react";
import { Route, Routes } from "react-router-dom";

import { ListDeployments } from "./ListDeployments";
import { ViewDeployment } from "./ViewDeployment";

export const DeploymentsRoutes = () => (
  <Routes>
    <Route path="" element={<ListDeployments />} />
    <Route path="/:deploymentId" element={<ViewDeployment />} />
  </Routes>
);
