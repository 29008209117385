import {
  useAuthenticationToken,
  useProfile,
} from "@hooloovoodoo/mui-react-auth";
import { UseQueryResult, useQuery } from "react-query";

import { CurrentUser } from "src/generated-sources/openapi";

import { useUserApi } from "./useUserApi";

export function useCurrentUserQuery(): UseQueryResult<CurrentUser> {
  const { data: idToken } = useAuthenticationToken();
  const profile = useProfile();
  const userApi = useUserApi();
  return useQuery(
    ["current-user", idToken],
    async (): Promise<CurrentUser | null> => {
      if (!idToken) {
        return null;
      }
      const firebaseUser = await profile.getUserProfile();
      const apiUser = await userApi.getCurrentUser();
      return {
        ...apiUser,
        ...(firebaseUser && {
          name: firebaseUser.name,
          email: firebaseUser.email,
          avatarImageUrl: firebaseUser.avatarImageUrl,
        }),
      };
    },
    {
      staleTime: 100000,
    }
  );
}

export function useCurrentUser(): CurrentUser {
  const { data: currentUser } = useCurrentUserQuery();
  if (!currentUser) {
    throw new Error(
      "useCurrentUser can only be used when user exists (ProtectedRoute)"
    );
  }
  return currentUser;
}
