import {
  Locale as DateFnsLocale,
  format as dateFnsFormat,
  formatDistanceToNow as dateFnsFormatDistanceToNow,
  formatDistanceToNowStrict as dateFnsFormatDistanceToNowStrict,
  isSameYear,
} from "date-fns";
import enUS from "date-fns/locale/en-US";
import srLatn from "date-fns/locale/sr-Latn";
import capitalize from "lodash.capitalize";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RadDate } from "src/domain";

interface FormatDistanceToNowOptions {
  includeSeconds?: boolean;
  addSuffix?: boolean;
}

interface FormatDistanceToNowStrictOptions {
  unit?: "second" | "minute" | "hour" | "day" | "month" | "year";
  roundingMethod?: "floor" | "ceil" | "round";
  addSuffix?: boolean;
}

interface FormatRangeOptions {
  representation: "full" | "short";
}

class LocalizedDateFns {
  constructor(public readonly locale: Locale) {}

  formatHourRange = (dateFrom: Date, dateTo: Date): string => {
    return `${dateFnsFormat(dateFrom, "HH:mm")} - ${dateFnsFormat(
      dateTo,
      "HH:mm"
    )}`;
  };

  format = (date: Date, format: string): string => {
    return dateFnsFormat(date, format, { locale: this.locale });
  };

  formatDistanceToNow = (
    date: Date,
    options?: FormatDistanceToNowOptions
  ): string => {
    return dateFnsFormatDistanceToNow(date, {
      ...options,
      locale: this.locale,
    });
  };

  formatDistanceToNowStrict = (
    date: Date,
    options?: FormatDistanceToNowStrictOptions
  ): string => {
    return dateFnsFormatDistanceToNowStrict(date, {
      ...options,
      locale: this.locale,
    });
  };

  formatDay = (date: Date): string => {
    return capitalize(this.format(date, "eeee, dd.MM.yyyy."));
  };

  formatMonth = (date: Date): string => {
    return capitalize(this.format(date, "LLLL"));
  };

  formatRange = (date: RadDate, opts?: FormatRangeOptions): string => {
    const options: FormatRangeOptions = {
      representation: "full",
      ...opts,
    };

    if (date.isSingleDay) {
      return formatSingleDay(date, options, this);
    }

    if (isSameYear(date.startDate, date.endDate)) {
      return formatSameYear(date, options, this);
    }

    return formatFull(date, options, this);
  };

  formatDayName = (date: Date): string => {
    return dateFnsFormat(date, "eee", { locale: this.locale });
  };
}

const Formats = {
  full: {
    year: "eeee, dd.MM.yyyy.",
    noYear: "eeee, dd.MM.",
  },
  short: {
    year: "eee, dd.MM.yyyy.",
    noYear: "eee, dd.MM.",
  },
};

function formatSingleDay(
  date: RadDate,
  options: FormatRangeOptions,
  formatter: LocalizedDateFns
): string {
  const format = Formats[options.representation];
  return capitalize(formatter.format(date.startDate, format.year));
}

function formatSameYear(
  date: RadDate,
  options: FormatRangeOptions,
  formatter: LocalizedDateFns
): string {
  const format = Formats[options.representation];
  const startDate = capitalize(formatter.format(date.startDate, format.noYear));
  const endDate = capitalize(formatter.format(date.endDate, format.year));
  return `${startDate} - ${endDate}`;
}

function formatFull(
  date: RadDate,
  options: FormatRangeOptions,
  formatter: LocalizedDateFns
): string {
  const format = Formats[options.representation];
  const startDate = capitalize(formatter.format(date.startDate, format.year));
  const endDate = capitalize(formatter.format(date.endDate, format.year));
  return `${startDate} - ${endDate}`;
}

const LocalizedDateFnsContext = React.createContext<LocalizedDateFns>(
  new LocalizedDateFns(enUS)
);

export function useDateFns(): LocalizedDateFns {
  return React.useContext(LocalizedDateFnsContext);
}

export function getLocale(language: string): Locale {
  switch (language) {
    case "rs":
      return srLatn;
    default:
      return enUS;
  }
}

export const LocalizedDateFnsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { i18n } = useTranslation();

  const locale = useMemo<DateFnsLocale>(
    () => getLocale(i18n.resolvedLanguage ?? "en"),
    [i18n.resolvedLanguage]
  );

  const dateFns = useMemo<LocalizedDateFns>(() => {
    return new LocalizedDateFns(locale);
  }, [locale]);

  return (
    <LocalizedDateFnsContext.Provider value={dateFns}>
      {children}
    </LocalizedDateFnsContext.Provider>
  );
};
