import { PageContainer, SimpleLoader } from "@hooloovoodoo/mui-react";
import { Container } from "@mui/material";
import React from "react";

import {
  ConnectRepositoriesList,
  RepositoriesHeader,
  RepositoryList,
} from "../components";
import { useListRepositoriesQuery } from "../hooks";

export const RepositoriesPage: React.FC = () => {
  const { data: envelope, isLoading } = useListRepositoriesQuery();
  return (
    <PageContainer>
      <RepositoriesHeader />
      {isLoading && <SimpleLoader text="Loading repositories..." />}
      {envelope && envelope.data.length === 0 && (
        <Container maxWidth="sm" sx={{ mt: 4 }}>
          <ConnectRepositoriesList />
        </Container>
      )}
      {envelope && envelope.data.length > 0 && (
        <RepositoryList repositories={envelope.data} />
      )}
    </PageContainer>
  );
};
