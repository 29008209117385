import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import CodeBracketIcon from "@heroicons/react/24/outline/CodeBracketIcon";
import CommandLineIcon from "@heroicons/react/24/outline/CommandLineIcon";
import ComputerDesktopIcon from "@heroicons/react/24/outline/ComputerDesktopIcon";
import GlobeAltIcon from "@heroicons/react/24/outline/GlobeAltIcon";
import { PageHeaderDetailsItem } from "@hooloovoodoo/mui-react";
import { Link, List } from "@mui/material";
import React from "react";

import { Service } from "src/generated-sources/openapi";
import { useDateFns } from "src/hooks";

interface ServiceHeaderDetailsProps {
  service: Service;
}

export const ServiceHeaderDetails: React.FC<ServiceHeaderDetailsProps> = ({
  service,
}) => {
  const { formatDistanceToNow } = useDateFns();

  return (
    <List
      sx={(theme) => ({
        display: "flex",
        gap: theme.spacing(4),
        justifyContent: "flex-start",
      })}
    >
      <PageHeaderDetailsItem
        text={
          <Link href={service.server.publicUrl} target="_blank">
            {service.server.publicUrl}
          </Link>
        }
        icon={GlobeAltIcon}
      />
      <PageHeaderDetailsItem
        text={
          <Link href={service.repository.htmlUrl} target="_blank">
            {service.repository.name}
          </Link>
        }
        icon={CommandLineIcon}
      />
      <PageHeaderDetailsItem text={"Github"} icon={ComputerDesktopIcon} />
      <PageHeaderDetailsItem
        text={`
          Created ${formatDistanceToNow(service.createdAt, {
            addSuffix: true,
          })}`}
        icon={ClockIcon}
      />
      <PageHeaderDetailsItem
        text={service.repository.language}
        icon={CodeBracketIcon}
      />
    </List>
  );
};
