/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Database
 */
export interface Database {
    /**
     * The unique identifier for the database
     * @type {string}
     * @memberof Database
     */
    id: string;
    /**
     * The unique identifier for the database cluster
     * @type {string}
     * @memberof Database
     */
    externalId: string;
    /**
     * The unique name of the database cluster
     * @type {string}
     * @memberof Database
     */
    name: string;
    /**
     * The number of nodes in the database cluster
     * @type {number}
     * @memberof Database
     */
    numNodes: number;
    /**
     * The slug identifier for the database engine
     * @type {string}
     * @memberof Database
     */
    engine: DatabaseEngineEnum;
    /**
     * The slug identifier for the region where the database cluster is located
     * @type {string}
     * @memberof Database
     */
    datacenterRegion: string;
    /**
     * The slug identifier representing the size of the nodes in the database cluster
     * @type {string}
     * @memberof Database
     */
    instanceType: string;
    /**
     * The status of the database
     * @type {string}
     * @memberof Database
     */
    status: DatabaseStatusEnum;
    /**
     * 
     * @type {Organization}
     * @memberof Database
     */
    organization: Organization;
    /**
     * The date and time the database was created
     * @type {Date}
     * @memberof Database
     */
    createdAt: Date;
    /**
     * 
     * @type {User}
     * @memberof Database
     */
    createdBy: User;
    /**
     * The date and time the database was deleted
     * @type {Date}
     * @memberof Database
     */
    deletedAt: Date;
    /**
     * 
     * @type {User}
     * @memberof Database
     */
    deletedBy: User;
}


/**
 * @export
 */
export const DatabaseEngineEnum = {
    Postgresql: 'POSTGRESQL',
    Redis: 'REDIS',
    Mysql: 'MYSQL',
    Mongodb: 'MONGODB'
} as const;
export type DatabaseEngineEnum = typeof DatabaseEngineEnum[keyof typeof DatabaseEngineEnum];

/**
 * @export
 */
export const DatabaseStatusEnum = {
    Creating: 'CREATING',
    Online: 'ONLINE',
    Resizing: 'RESIZING',
    Migrating: 'MIGRATING',
    Forking: 'FORKING',
    Deleted: 'DELETED'
} as const;
export type DatabaseStatusEnum = typeof DatabaseStatusEnum[keyof typeof DatabaseStatusEnum];


/**
 * Check if a given object implements the Database interface.
 */
export function instanceOfDatabase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "externalId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "numNodes" in value;
    isInstance = isInstance && "engine" in value;
    isInstance = isInstance && "datacenterRegion" in value;
    isInstance = isInstance && "instanceType" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "deletedAt" in value;
    isInstance = isInstance && "deletedBy" in value;

    return isInstance;
}

export function DatabaseFromJSON(json: any): Database {
    return DatabaseFromJSONTyped(json, false);
}

export function DatabaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Database {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': json['external_id'],
        'name': json['name'],
        'numNodes': json['num_nodes'],
        'engine': json['engine'],
        'datacenterRegion': json['datacenter_region'],
        'instanceType': json['instance_type'],
        'status': json['status'],
        'organization': OrganizationFromJSON(json['organization']),
        'createdAt': (new Date(json['created_at'])),
        'createdBy': UserFromJSON(json['created_by']),
        'deletedAt': (new Date(json['deleted_at'])),
        'deletedBy': UserFromJSON(json['deleted_by']),
    };
}

export function DatabaseToJSON(value?: Database | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'external_id': value.externalId,
        'name': value.name,
        'num_nodes': value.numNodes,
        'engine': value.engine,
        'datacenter_region': value.datacenterRegion,
        'instance_type': value.instanceType,
        'status': value.status,
        'organization': OrganizationToJSON(value.organization),
        'created_at': (value.createdAt.toISOString()),
        'created_by': UserToJSON(value.createdBy),
        'deleted_at': (value.deletedAt.toISOString()),
        'deleted_by': UserToJSON(value.deletedBy),
    };
}

