import React from "react";
import { Route, Routes } from "react-router";

import { ListLogs } from "./ListLogs";

export interface LogsRouteProps {}

export const LogsRoutes: React.FC<LogsRouteProps> = (props) => {
  return (
    <Routes>
      <Route path="/" element={<ListLogs />} />
    </Routes>
  );
};
