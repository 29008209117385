import { Box } from "@mui/material";
import React from "react";

import { Repository } from "src/generated-sources/openapi";

import { RepositoryStat } from "./RepositoryStat";

interface RepositoryStatsProps {
  repository: Repository;
}

export const RepositoryStats: React.FC<RepositoryStatsProps> = ({
  repository,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        backgroundColor: "gray100",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        [theme.breakpoints.up("sm")]: {
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        },
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
      })}
    >
      <RepositoryStat title="Number of deploys" value="405" />
      <RepositoryStat
        title="Average deploy time"
        value="3.65"
        subvalue="mins"
      />
      <RepositoryStat title="Number of servers" value="3" />
      <RepositoryStat title="Success rate" value="98.5%" />
    </Box>
  );
};
