/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Layout
 */
export interface Layout {
    /**
     * 
     * @type {number}
     * @memberof Layout
     */
    numNodes: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Layout
     */
    sizes: Array<string>;
}

/**
 * Check if a given object implements the Layout interface.
 */
export function instanceOfLayout(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "numNodes" in value;
    isInstance = isInstance && "sizes" in value;

    return isInstance;
}

export function LayoutFromJSON(json: any): Layout {
    return LayoutFromJSONTyped(json, false);
}

export function LayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): Layout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numNodes': json['num_nodes'],
        'sizes': json['sizes'],
    };
}

export function LayoutToJSON(value?: Layout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'num_nodes': value.numNodes,
        'sizes': value.sizes,
    };
}

