import { useSession } from "@hooloovoodoo/mui-react-auth";
import { useMemo } from "react";

import { Configuration } from "src/generated-sources/openapi";

export function useApiConfiguration(): Configuration {
  const session = useSession();
  return useMemo(() => {
    return new Configuration({
      basePath: document.location.origin,
      accessToken: async (): Promise<string> => {
        const token = await session.getAuthenticationToken();
        return token ?? "";
      },
    });
  }, [session]);
}
