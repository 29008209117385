import RocketLaunchIcon from "@heroicons/react/24/solid/RocketLaunchIcon";
import { UiButton, UiHeroIcon } from "@hooloovoodoo/mui-react";
import {
  CircularProgress,
  Container,
  ContainerProps,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  DatabaseOption,
  InstanceType,
  Layout,
} from "src/generated-sources/openapi";

import {
  useCreateDatabaseMutation,
  useListDatabaseOptionsQuery,
  useListInstanceTypesQuery,
} from "../../hooks";
import { ClusterSizeSelector } from "./ClusterSizeSelector";
import { DbInstanceSizeSelector } from "./DbInstanceSizeSelector";
import { EngineSelector } from "./EngineSelector";
import { RegionSelector } from "./RegionSelector";

const Wrapper: React.FC<ContainerProps> = (props) => (
  <Container maxWidth="xs" sx={{ marginY: 5 }} {...props} />
);

export const CreateDatabaseForm: React.FC = () => {
  const instanceTypesQuery = useListInstanceTypesQuery();
  const databaseOptionsQuery = useListDatabaseOptionsQuery();

  if (!instanceTypesQuery.isSuccess || !databaseOptionsQuery.isSuccess) {
    return (
      <Wrapper sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Wrapper>
    );
  }

  return (
    <CreateDatabaseFormContent
      databaseOptions={databaseOptionsQuery.data.data}
      instanceTypes={instanceTypesQuery.data.data}
    />
  );
};

interface FormValues {
  engine: DatabaseOption;
  layout: Layout;
  region: string;
  version: string;
  instanceType: string;
  name: string;
}

interface CreateDatabaseFormContentOptions {
  databaseOptions: DatabaseOption[];
  instanceTypes: InstanceType[];
}

const CLUSTER_NAME_VALIDATION_MSG =
  "Names must be in lowercase and start with a letter. They can be between 3 and 30 characters long and may contain dashes.";
const CreateDatabaseFormContent: React.FC<CreateDatabaseFormContentOptions> = ({
  databaseOptions,
  instanceTypes,
}) => {
  const createDatabase = useCreateDatabaseMutation();
  const defaultEngine = databaseOptions[0];
  const defaultRegion = defaultEngine.regions[0];
  const defaultVersion =
    defaultEngine.versions[defaultEngine.versions.length - 1];
  const defaultLayout = defaultEngine.layouts[0];
  const defaultInstanceType = defaultLayout.sizes[0];
  const defaultName = useMemo(() => {
    return "rad12-db-" + Math.floor(Math.random() * 1000000);
  }, []);
  const form = useForm<FormValues>({
    defaultValues: {
      engine: defaultEngine,
      region: defaultRegion,
      version: defaultVersion,
      layout: defaultLayout,
      instanceType: defaultInstanceType,
    },
    mode: "onChange",
  });
  const engine = form.watch("engine");
  const layout = form.watch("layout");

  const doCreate = async (values: FormValues) => {
    await createDatabase.mutateAsync({
      datacenterRegion: values.region,
      engine: getEngine(values.engine.name!!),
      instanceType: values.instanceType,
      numNodes: values.layout.numNodes,
      name: values.name,
    });
  };

  return (
    <form onSubmit={form.handleSubmit(doCreate)}>
      <Wrapper>
        <Stack spacing={4}>
          <Typography variant="h3" component="h1" sx={{ mb: 3 }}>
            Deploy a new database
          </Typography>
          <Controller
            name="engine"
            control={form.control}
            render={({ field }) => (
              <EngineSelector
                options={databaseOptions}
                disabled={form.formState.isSubmitting}
                {...field}
                onChange={(newEngine) => {
                  form.reset({
                    engine: newEngine,
                    region: newEngine.regions[0],
                    version: newEngine.versions[newEngine.versions.length - 1],
                    layout: newEngine.layouts[0],
                    instanceType: newEngine.layouts[0].sizes[0],
                  });
                }}
                label="Database"
              />
            )}
          />
          <Controller
            name="version"
            control={form.control}
            render={({ field }) => (
              <TextField
                select
                {...field}
                disabled={form.formState.isSubmitting}
                label="Version"
              >
                {engine.versions.map((version) => (
                  <MenuItem key={version} value={version}>
                    <ListItemText
                      primary={<>v{version}</>}
                      secondary={engine.name}
                    />
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="region"
            control={form.control}
            render={({ field }) => (
              <RegionSelector
                options={engine.regions}
                {...field}
                disabled={form.formState.isSubmitting}
                label="Region"
              />
            )}
          />
          <Controller
            name="layout"
            control={form.control}
            render={({ field }) => (
              <ClusterSizeSelector
                options={engine.layouts}
                {...field}
                disabled={form.formState.isSubmitting}
                label="Cluster size"
              />
            )}
          />
          <Controller
            name="instanceType"
            control={form.control}
            render={({ field }) => (
              <DbInstanceSizeSelector
                options={layout.sizes}
                instanceTypes={instanceTypes}
                {...field}
                disabled={form.formState.isSubmitting}
                label="Instance Type"
              />
            )}
          />
          <TextField
            {...form.register("name", {
              required: {
                value: true,
                message: CLUSTER_NAME_VALIDATION_MSG,
              },
              pattern: {
                value: /^[a-z][a-z0-9-]*[a-z0-9]$/,
                message: CLUSTER_NAME_VALIDATION_MSG,
              },
              minLength: {
                value: 3,
                message: CLUSTER_NAME_VALIDATION_MSG,
              },
              maxLength: {
                value: 30,
                message: CLUSTER_NAME_VALIDATION_MSG,
              },
            })}
            defaultValue={defaultName}
            label="Database cluster name"
            error={!!form.formState.errors.name}
            helperText={form.formState.errors.name?.message}
            disabled={form.formState.isSubmitting}
          />
          <UiButton
            type="submit"
            fullWidth
            disabled={!form.formState.isValid || form.formState.isSubmitting}
            variant="contained"
            color="primary"
          >
            <UiHeroIcon icon={RocketLaunchIcon} />
            Deploy
          </UiButton>
        </Stack>
      </Wrapper>
    </form>
  );
};

function getEngine(name: string): string {
  switch (name.toLowerCase()) {
    case "postgresql":
      return "pg";
    case "redis":
      return "redis";
    default:
      throw new Error("Invalid database");
  }
}
