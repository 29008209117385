import { UiButton } from "@hooloovoodoo/mui-react";
import { Alert, Stack, TextField, TextFieldProps } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormReturn,
  useForm,
} from "react-hook-form";

import { Service } from "src/generated-sources/openapi";

import { useUpdateServiceIngressPathMutation } from "../../../hooks";
import { canDeployService } from "../../../permissions";
import { validateIngressPath } from "../../../validation";

export interface IngressFormProps {
  service: Service;
}

interface FormValues {
  ingressPath: string;
}

function bindMuiField<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  name: TFieldName,
  options: RegisterOptions<TFieldValues, TFieldName>,
  form: UseFormReturn<TFieldValues>
): Partial<TextFieldProps> {
  return {
    ...form.register(name, options),
    error: !!form.formState.errors[name],
    ...(form.formState.errors[name] && {
      helperText: form.formState.errors[name]?.message as string,
    }),
    disabled: form.formState.isSubmitting,
  };
}

export const IngressForm: React.FC<IngressFormProps> = ({ service }) => {
  const { mutateAsync: updateIngressPath } =
    useUpdateServiceIngressPathMutation();
  const form = useForm<FormValues>({
    defaultValues: {
      ingressPath: service.ingressPath,
    },
    mode: "all",
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
  } = form;

  const submit = useMemoizedFn(async (values: FormValues) => {
    try {
      await updateIngressPath({
        serviceId: service.id,
        updateServiceIngressPathRequest: {
          ingressPath: values.ingressPath,
        },
      });
    } catch (err) {
      // Mutation will have already displayed an error message.
    }
  });

  const canChangeIngressPath = canDeployService(service);
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack spacing={4}>
        <TextField
          fullWidth
          label="Ingress path"
          helperText={
            <>
              The path where your service is being accessed publicly. e.g.{" "}
              <code>/my-service</code>
            </>
          }
          {...bindMuiField(
            "ingressPath",
            {
              required: "Please enter a valid ingress path.",
              validate: {
                ingressPath: validateIngressPath,
              },
            },
            form
          )}
        />
        {canChangeIngressPath ? (
          <Alert severity="info">
            Changing the ingress path will deploy a new version of the service.
          </Alert>
        ) : (
          <Alert severity="warning">
            Cannot change ingress path until the deployment is completed. Please
            wait for the current action to finish.
          </Alert>
        )}
        <UiButton
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          disabled={
            !isValid || !isDirty || isSubmitting || !canChangeIngressPath
          }
        >
          Save
        </UiButton>
      </Stack>
    </form>
  );
};
