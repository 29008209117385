import { SimpleLoader } from "@hooloovoodoo/mui-react";
import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router";

import { RadBreadcrumbs } from "src/modules/common/components";

import { RepositoryComponent } from "../components/RepositoryComponent";
import { useRepositoryQuery } from "../hooks";

export interface RepositoryPageProps {}

type RepositoryRouteParams = "repositoryId";

export const RepositoryPage: React.FC<RepositoryPageProps> = (props) => {
  const { repositoryId } = useParams<RepositoryRouteParams>();
  const { data: repository, isLoading } = useRepositoryQuery(repositoryId);

  return (
    <Box>
      {!isLoading && (
        <RadBreadcrumbs entity={repository} entityName="repositories" />
      )}
      {isLoading && <SimpleLoader text="Loading repository info..." />}
      {repository && <RepositoryComponent repository={repository} />}
    </Box>
  );
};
