/* tslint:disable */
/* eslint-disable */
/**
 * Rad12 API
 * Rad12 API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateDatabaseRequest,
  Database,
  DatabaseCredentials,
  DatabaseEnvelope,
  DatabaseOptionEnvelope,
  Pageable,
  Problem,
} from '../models';
import {
    CreateDatabaseRequestFromJSON,
    CreateDatabaseRequestToJSON,
    DatabaseFromJSON,
    DatabaseToJSON,
    DatabaseCredentialsFromJSON,
    DatabaseCredentialsToJSON,
    DatabaseEnvelopeFromJSON,
    DatabaseEnvelopeToJSON,
    DatabaseOptionEnvelopeFromJSON,
    DatabaseOptionEnvelopeToJSON,
    PageableFromJSON,
    PageableToJSON,
    ProblemFromJSON,
    ProblemToJSON,
} from '../models';

export interface CreateDatabaseOperationRequest {
    organizationId: string;
    createDatabaseRequest: CreateDatabaseRequest;
}

export interface DestroyDatabaseRequest {
    databaseId: string;
}

export interface GetDatabaseRequest {
    databaseId: string;
}

export interface GetDatabaseCredentialsRequest {
    databaseId: string;
}

export interface ListDatabasesRequest {
    organizationId: string;
    pageable?: Pageable;
}

/**
 * 
 */
export class DatabaseApi extends runtime.BaseAPI {

    /**
     * Create a new database.
     * Create new database
     */
    async createDatabaseRaw(requestParameters: CreateDatabaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Database>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createDatabase.');
        }

        if (requestParameters.createDatabaseRequest === null || requestParameters.createDatabaseRequest === undefined) {
            throw new runtime.RequiredError('createDatabaseRequest','Required parameter requestParameters.createDatabaseRequest was null or undefined when calling createDatabase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organization/{organizationId}/database`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDatabaseRequestToJSON(requestParameters.createDatabaseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseFromJSON(jsonValue));
    }

    /**
     * Create a new database.
     * Create new database
     */
    async createDatabase(requestParameters: CreateDatabaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Database> {
        const response = await this.createDatabaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Destroy the created database.
     * Destroy database
     */
    async destroyDatabaseRaw(requestParameters: DestroyDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.databaseId === null || requestParameters.databaseId === undefined) {
            throw new runtime.RequiredError('databaseId','Required parameter requestParameters.databaseId was null or undefined when calling destroyDatabase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/databases/{databaseId}`.replace(`{${"databaseId"}}`, encodeURIComponent(String(requestParameters.databaseId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Destroy the created database.
     * Destroy database
     */
    async destroyDatabase(requestParameters: DestroyDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.destroyDatabaseRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a database of the organization.
     * Get database
     */
    async getDatabaseRaw(requestParameters: GetDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Database>> {
        if (requestParameters.databaseId === null || requestParameters.databaseId === undefined) {
            throw new runtime.RequiredError('databaseId','Required parameter requestParameters.databaseId was null or undefined when calling getDatabase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/databases/{databaseId}`.replace(`{${"databaseId"}}`, encodeURIComponent(String(requestParameters.databaseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseFromJSON(jsonValue));
    }

    /**
     * Returns a database of the organization.
     * Get database
     */
    async getDatabase(requestParameters: GetDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Database> {
        const response = await this.getDatabaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns database credentials.
     * Get database credentials
     */
    async getDatabaseCredentialsRaw(requestParameters: GetDatabaseCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatabaseCredentials>> {
        if (requestParameters.databaseId === null || requestParameters.databaseId === undefined) {
            throw new runtime.RequiredError('databaseId','Required parameter requestParameters.databaseId was null or undefined when calling getDatabaseCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/databases/{databaseId}/credentials`.replace(`{${"databaseId"}}`, encodeURIComponent(String(requestParameters.databaseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseCredentialsFromJSON(jsonValue));
    }

    /**
     * Returns database credentials.
     * Get database credentials
     */
    async getDatabaseCredentials(requestParameters: GetDatabaseCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatabaseCredentials> {
        const response = await this.getDatabaseCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of database options.
     * List database options
     */
    async listDatabaseOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatabaseOptionEnvelope>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/databases/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseOptionEnvelopeFromJSON(jsonValue));
    }

    /**
     * Returns a list of database options.
     * List database options
     */
    async listDatabaseOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatabaseOptionEnvelope> {
        const response = await this.listDatabaseOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of databases belonging to organization.
     * List databases
     */
    async listDatabasesRaw(requestParameters: ListDatabasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatabaseEnvelope>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling listDatabases.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/organization/{organizationId}/database`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseEnvelopeFromJSON(jsonValue));
    }

    /**
     * Returns a list of databases belonging to organization.
     * List databases
     */
    async listDatabases(requestParameters: ListDatabasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatabaseEnvelope> {
        const response = await this.listDatabasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
