import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { DestroyServerRequest } from "src/generated-sources/openapi";

import { useServerApi } from "./useServerApi";

export function useDestroyServerMutation(): UseMutationResult<
  void,
  Error,
  DestroyServerRequest
> {
  const queryClient = useQueryClient();
  const serverApi = useServerApi();
  return useMutation(
    ["destroy-server"],
    async (request: DestroyServerRequest) => {
      await serverApi.destroyServer(request);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["list-servers"]);
      },
    }
  );
}
